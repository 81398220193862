import React, { useEffect, useState } from "react";
// import StudentItem from "../../../components/ProfDashboard/StudentItem";
import Aux from '../../../hoc/Wrapper';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from '../../../components/Common/ModalComponent/ModalComponent';
import { withNamespaces } from 'react-i18next';
import "../../../components/Answerboard/Answerboard.scss"
import { getStudentListbyProfId, imgURL, updatePTAutoSubmit, savepttimer, PTTimerSubmitUpdate } from '../../../common/API'
import { User_Role, getCurrentUserId, getCurrentUserRole, Call_Type, getIsPTTeacher, getInstituteId, getTimeDifference, convertDurationToSeonds, Problem_source, getMenuSelectedItem } from '../../../common/Functions'
import { Institute, halfTestMarksLimit, fullPTTimeInString, halfPTTimeInString } from '../../../common/Constants'


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { forEach, isEmpty, isNil, random } from 'lodash';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import legendCircle from "../../../assets/images/legend-circle.svg";
import {
    Avatar,
    makeStyles

} from '@material-ui/core';
import Button from '@mui/material/Button';
import GraphComponent from "../Graph/GraphComponent";
import "./HomePage.scss"
import TeacherUI from '../../TutorScreens/TeacherUI/TeacherUI';
import SessionHistory from '../../TutorScreens/SessionHistory/SessionHistory';
import TutorAvailability from '../../Tutor/TutorAvailability';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import RiceHomeworkDetail from "../RiceHomework/RiceHomeworkDetail";
import InClassQAReport from "../../../components/Reports/InClassQAReport";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {


    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="studentname"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "studentname" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "studentname"}
                        direction={orderBy === "studentname" ? order : 'asc'}
                        onClick={createSortHandler("studentname")}
                    >
                        Student Name
                        {orderBy === "studentname" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="proficiency"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "proficiency" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "proficiency"}
                        direction={orderBy === "proficiency" ? order : 'asc'}
                        onClick={createSortHandler("proficiency")}
                    >
                        Proficiency Score(%)
                        {orderBy === "proficiency" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="viewmap"
                    align={'left'}
                    padding={'normal'}
                    sx={{ cursor: 'pointer' }}
                >Proficiency Map</TableCell>
                {getIsPTTeacher() === 'true' ? <TableCell sx={{ maxWidth: "300px" }} key="pttestkey">Completed Practice Test</TableCell> : null}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    avatarlarge: {
        height: 150,
        width: 150,
    },
    linkbutton: {
        fontSize: "10px"
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#1976d214 !important"
        }
    },

}));

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "#a4a4a4",
//         color: theme.palette.common.white,
//         padding: "10px",
//         fontSize: 16,
//         fontWeight: "bold"
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//         padding: "7px"
//     },
// }));


// const StyledTableCellbody = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         //backgroundColor: theme.palette.common.black,
//         //color: theme.palette.common.white,
//         padding: "10px",
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 12,
//         padding: "7px"
//     },
// }));
// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(even)': {
//         backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));



const HomePage = (props) => {
    const classes = useStyles();
    const [loading, setloading] = useState(false)
    const [studentdata, setstudentdata] = useState([])
    const [page, setpage] = useState(1)
    const [startPage, setstartPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(10)
    const [openmodal, setopenmodal] = useState(false)
    const [selectesstudentname, setselectesstudentname] = useState("")
    const [selectesstudentId, setselectesstudentId] = useState(null)
    const [tabValue, setTabValue] = useState("Dashboard")
    const [totalPages, settotalPages] = useState(1)


    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('proficiency');
    const [selected, setSelected] = React.useState([]);
    const [assignment_id, setassignment_id] = useState("")
    const [assignmentName, setassignmentName] = useState("")
    const [isStudentView, setisStudentView] = useState(false)
    const [problemSource, setProblemSource] = useState(Problem_source.assignment)

    useEffect(() => {
        let sList
        setloading(true)

        const reqData = {
            params: {
                user_professor_id: getCurrentUserId(),
                course_id: getMenuSelectedItem()
            }
        }

        getStudentListbyProfId(reqData, getIsPTTeacher()).then(res => {
            if (!isNil(res.data.data) && res.data.data.length > 0) {

                setstudentdata(res.data.data)
                sList = res.data.data
                settotalPages(Math.ceil(res.data.data.length / rowsPerPage))
                setloading(false)

                // if (getInstituteId() === Institute.Rice) {
                //     if (res.data.data.length > 0) {
                //         res.data.data.forEach(student => {
                //             if (student.inprogressptList !== undefined && !isNil(student.inprogressptList) && student.inprogressptList.length > 0) {
                //                 const interval = setInterval(() => {
                //                     student.inprogressptList.forEach(ptDetail => {
                //                         //debugger;
                //                         let totalTime
                //                         let startEpoch = ptDetail.epoch
                //                         let totalChallenges = ptDetail.totalchallengeperPtTest
                //                         if (totalChallenges > halfTestMarksLimit) {
                //                             totalTime = fullPTTimeInString
                //                         }
                //                         else {
                //                             totalTime = halfPTTimeInString
                //                         }

                //                         let now = new Date();
                //                         let currentEpoch = Math.floor(now.getTime() / 1000);
                //                         let getTimeDiffArray = getTimeDifference(currentEpoch, startEpoch)
                //                         let currentTimeDiff = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds

                //                         if (convertDurationToSeonds(totalTime) < convertDurationToSeonds(currentTimeDiff)) {
                //                             clearInterval(interval);
                //                             const reqData = {
                //                                 "user_id": student.studentid,
                //                                 "unique_id": parseInt(ptDetail.unique_id),
                //                                 "problem_source": Problem_source.apptesthomework
                //                             }

                //                             updatePTAutoSubmit(reqData).then(res => {
                //                                 if (res.data !== undefined && res.data !== null) {

                //                                     /// Update Remaining TimeLapse in DB
                //                                     const reqData2 = {
                //                                         "user_id": student.studentid,
                //                                         "unique_id": parseInt(ptDetail.unique_id),
                //                                         "timelapse": "00:00:00",
                //                                         "epoch": parseInt(ptDetail.epoch)
                //                                     }

                //                                     savepttimer(reqData2).then(res => {
                //                                         if (res.data !== undefined && res.data !== null) {
                //                                             /// PT TimeLapse updated successfully..!
                //                                         }
                //                                     })

                //                                     const reqData1 = {
                //                                         "user_id": student.studentid,
                //                                         "unique_id": parseInt(ptDetail.unique_id),
                //                                     }
                //                                     PTTimerSubmitUpdate(reqData1).then(res => {
                //                                         if (res.data !== undefined && res.data !== null) {
                //                                             const reqData = {
                //                                                 params: {
                //                                                     user_professor_id: getCurrentUserId(),
                //                                                     course_id: getMenuSelectedItem()
                //                                                 }
                //                                             }

                //                                             getStudentListbyProfId(reqData, getIsPTTeacher()).then(res => {
                //                                                 if (!isNil(res.data.data) && res.data.data.length > 0) {

                //                                                     setstudentdata(res.data.data)
                //                                                     sList = res.data.data
                //                                                     settotalPages(Math.ceil(res.data.data.length / rowsPerPage))
                //                                                     setloading(false)


                //                                                 }
                //                                                 else {
                //                                                     setloading(false)
                //                                                 }
                //                                             }).catch(err => {
                //                                                 console.error(err.message);
                //                                                 setloading(false)
                //                                             })
                //                                         }
                //                                     })


                //                                 }
                //                             })
                //                         }
                //                     })
                //                 }, 5000);
                //             }
                //         })
                //     }
                // }
                setloading(false)
            }
            else {
                setloading(false)
            }
        }).catch(err => {
            console.error(err.message);
            setloading(false)
        })

        if (props.location.state !== undefined && props.location.state !== null) {
            setloading(false)
            console.log("prev node - " + props.location.state["assignmentId"])
            if (props.location.state["prevNode"].includes('Assignment_Summary')) {
                setTabValue("homework")
                if (props.location.state["assignmentId"] !== undefined && props.location.state["assignmentId"] !== null && props.location.state["assignmentId"].length > 0)
                    setassignment_id(props.location.state["assignmentId"][0])
                if (props.location.state["assignmentName"] !== undefined && props.location.state["assignmentName"] !== null && props.location.state["assignmentName"].length > 0)
                    setassignmentName(props.location.state["assignmentName"][0])
                setisStudentView(true)
            }
            else {
                setTabValue("Dashboard")
                setisStudentView(false)
            }
        }
        else {
            setTabValue("Dashboard")
            setisStudentView(false)
        }
    }, [])

    const handleChangePage = (event, page) => {
        // this.setState({ page }, () => {
        //     // this.raiseHandClickHandler()
        // });
        setpage(page)
        setstartPage(page - 1)
    }
    const handleChangeRowsPerPage = event => {
        // this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
        //     this.raiseHandClickHandler()
        // });
        setrowsPerPage(event.target.value)
        setpage(0)
    }

    const openmodalhandle = (event, studentid, name) => {
        setselectesstudentname(name)
        setselectesstudentId(studentid)
        setopenmodal(true)
    }
    const closemodalhandle = () => {
        setopenmodal(false)
    }

    const openContentOptionsModal = () => {
    }

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
        setisStudentView(false)
    }

    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentdata.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(studentdata, getComparator(order, orderBy)).slice(
                startPage * rowsPerPage,
                startPage * rowsPerPage + rowsPerPage,
            ),
        [studentdata, order, orderBy, page, rowsPerPage],
    );
    const handleClick = (event, id) => {


        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    return (
        <>
            <Modal className="test" show={openmodal} onHide={closemodalhandle}
                //draggable={true}
                handleClose={closemodalhandle}
                popUpSize="large"
                title={`${selectesstudentname}'s Proficiency`}
                body=
                {
                    <>
                        <div style={{ minHeight: "500px" }}>
                            <GraphComponent
                                //chartType="dashboard"
                                componentId="homePage"
                                isauthordashboard={true}
                                selectedstudentId={selectesstudentId}
                                openContentOptionsModal={openContentOptionsModal}
                                isPT={false}
                            ></GraphComponent>

                            <div className="dotted-line-legend">
                                <div className="legendName">
                                    <span className="white-dotted line-width"></span>{" "}
                                    <span>Chapter - Sections</span>
                                </div>
                                <div className="legendName">
                                    <span className="black-dotted line-width"></span>{" "}
                                    <span>Prerequisite Links</span>
                                </div>
                                <div className="legendName">
                                    <span className="blue-dotted line-width"></span>{" "}
                                    <span>Path to Selected Node</span>
                                </div>
                            </div>


                            <div className="graph-legend">
                                <img src={legendCircle} />
                                <div>
                                    <div className="legend-completed">
                                        <span>% Completion</span>
                                    </div>
                                    <div className="legend-mastery">
                                        {" "}
                                        <span>% Proficiency</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                }

                showButton={false}
            // customButton={                    
            //         <Button variant="primary" onClick={closemodalhandle}>
            //             Ok
            //         </Button>                  

            // }
            >
                {/* </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closemodalhandle}>
                        Ok
                    </Button>
                </Modal.Footer> */}
            </Modal >
            {getCurrentUserRole() === User_Role.Professor || getIsPTTeacher() === 'true' ?
                <div class="container-fluid px-3 px-md-5">
                    <div className="pt-3">
                        <h4>Your Dashboard</h4>
                        <p>Stay on top of your students’ proficiency progress and join or track past tutoring sessions</p>
                    </div>
                    <div className='bg-white-with-shadow my-3'>
                        <div className=''>
                            {/* <h5 className='breadcrumbH5'>
                            {<a href="/homeworkproblems">Homework</a>}
                            <> <ArrowForwardIcon /> Challenge: {challengeTitle}</>
                        </h5> */}
                        </div>

                        <Tabs activeKey={tabValue} onSelect={handleTabChange} className="tabs">
                            <Tab eventKey="Dashboard" title="Proficiency View">
                                <div className='py-3'>
                                    <h5 className="mb-0">Student Proficiency </h5>
                                    <p className="text-secondary">Track the proficiency level of all students in your class</p>
                                    <Box sx={{ width: '100%' }}>
                                        <Paper sx={{ width: '100%', mb: 2 }}>

                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    size={'medium'}
                                                >
                                                    <EnhancedTableHead
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                    // rowCount={rows.length}
                                                    />
                                                    <TableBody>
                                                        {
                                                            visibleRows !== undefined && visibleRows !== null && visibleRows.length > 0 ?

                                                                (visibleRows.map((row, index) => {
                                                                    const isItemSelected = isSelected(row.id);
                                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                                    let attemptpttestcontent = []
                                                                    if (row.attemptedpttestlist !== undefined && row.attemptedpttestlist !== null && row.attemptedpttestlist.length > 0) {
                                                                        //debugger;
                                                                        row.attemptedpttestlist.forEach(element => {
                                                                            let data =
                                                                                // <Button variant="text" 
                                                                                // //onClick={(e) => openassessmentreview(e, element.unique_id, element.title)}
                                                                                //     style={{ fontSize: "12px", textTransform: "none" }}>{element.title}</Button>
                                                                                <Link
                                                                                    style={{ fontSize: "12px", textTransform: "none", paddingRight: "20px" }}
                                                                                    to={{
                                                                                        pathname: "/assignmentdetails/" + element.assignment_id + "/" + row.studentid,
                                                                                        state: { prevPath: ['/'], prevNode: ['Dashboard'], studentname: [`${row.studentname}`] }
                                                                                    }}
                                                                                >
                                                                                    {element.title}
                                                                                </Link>
                                                                            attemptpttestcontent.push(data)
                                                                        })
                                                                    }

                                                                    return (
                                                                        <TableRow
                                                                            hover
                                                                            className={classes.tableRow}
                                                                            // onClick={(event) => handleClick(event, row.id)}
                                                                            role="checkbox"
                                                                            aria-checked={isItemSelected}
                                                                            tabIndex={-1}
                                                                            key={row.id}
                                                                            // selected={isItemSelected}
                                                                            sx={{ cursor: 'pointer' }}
                                                                        >

                                                                            <TableCell
                                                                                component="th"
                                                                                id={labelId}
                                                                                scope="row"
                                                                                padding="none"
                                                                            >
                                                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                                    <Avatar
                                                                                        className={classes.avatar}
                                                                                        src={imgURL + row.profile_pic}>
                                                                                    </Avatar>
                                                                                    <div className='mt-2'>{row.studentname}</div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell align="left">{row.proficiency}</TableCell>
                                                                            <TableCell align="left" ><Button variant="text" onClick={(e) => openmodalhandle(e, row.studentid, row.studentname)} style={{ fontSize: "12px", textTransform: "none" }}>View</Button></TableCell>

                                                                            {getIsPTTeacher() === 'true' ?
                                                                                <TableCell sx={{ maxWidth: 300 }}>
                                                                                    {attemptpttestcontent}
                                                                                </TableCell> : null}
                                                                        </TableRow>
                                                                    );
                                                                })) : (<div style={{ padding: "20px" }}>No Student Found!</div>)

                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <Stack spacing={2}>



                                                <Pagination
                                                    sx={{
                                                        "& .MuiTablePagination-selectLabel": {
                                                            marginBottom: "0px !important"
                                                        },
                                                        "& .MuiTablePagination-displayedRows": {
                                                            marginBottom: "0px !important"
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    color="primary"
                                                    count={totalPages}
                                                    page={page}
                                                    onChange={handleChangePage}
                                                />
                                            </Stack>

                                        </Paper>

                                    </Box>

                                </div>
                                {getIsPTTeacher() === 'false' ? <TeacherUI></TeacherUI> : null}

                            </Tab>
                            {/* {getIsPTTeacher() === 'false' ?
                                <Tab eventKey="SessionHistory" title="Session History">
                                    <SessionHistory></SessionHistory>
                                </Tab> : null}
                            {getIsPTTeacher() === 'false' ?
                                <Tab eventKey="Scheduler" title="Scheduler">
                                    <TutorAvailability></TutorAvailability>
                                </Tab> : null} */}
                            <Tab eventKey="homework" title="Assignment Participation & Grading">
                                <RiceHomeworkDetail 
                                assignment_id={assignment_id} 
                                assignmentName ={assignmentName}
                                isStudentView={isStudentView} problemSource={problemSource}></RiceHomeworkDetail>
                            </Tab>
                            <Tab eventKey="inclassreport" title="In Class Q&A Summary">
                                <InClassQAReport></InClassQAReport>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
                : null}
            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    );
};
export default withNamespaces()(HomePage);