import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { TextField, Grid, Box, Button, FormLabel } from '@mui/material';
import { getCurrentUserId, getCurrentUserName } from '../../common/Functions';
import { saveactionFeedback } from '../../common/API';
import { FaStar } from "react-icons/fa";
import { Container, RadioRate, Rating } from "../Feedback/RatingStyles";

class CommonFeedback extends Component {

    state = {
        feedbackcomment: "",
        rating: 0,
        incorrectfeedbackrating: 0,
        correctfeedbackrating: 0,
        evaluationhelprating:0
        
    }

    handlefeedbackcommment = (e) => {
        this.setState({ feedbackcomment: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let description = {
            "feedbackcomment": this.state.feedbackcomment,
            "rating": this.state.rating,
            "correctfeedbackrating":this.state.correctfeedbackrating,
            "incorrectfeedbackrating":this.state.incorrectfeedbackrating,
            "evaluationhelprating":this.state.evaluationhelprating
        }

        const reqData = {
            userId: getCurrentUserId(),
            description: description,
            action: this.props.actionName,
            challenge_id: this.props.challenge_id !== undefined && this.props.challenge_id !== null ? this.props.challenge_id : "",
            boardid: this.props.boardid !== undefined && this.props.boardid !== null ? this.props.boardid : "",
            assignment_id: this.props.assignment_id !== undefined && this.props.assignment_id !== null ? this.props.assignment_id : "",
            problem_source: this.props.problem_source !== undefined && this.props.problem_source !== null ? this.props.problem_source : ""

        }

        saveactionFeedback(reqData).then(res => {
            toast.success("Thank you for writting to us !", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            /* Clear Form Data */
            this.setState({ feedbackcomment: '', rating: 0 })
            this.props.onClose()
        })
            .catch(err => {
                console.error(err.message);
                toast.error("Some error occurred", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })

    }

    render() {
        //alert(this.props.isanyEquationIncorrect)

        return (
            <>
                <div className='fwidth'>
                    <Box component="form" sx={{ mt: 3 }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <FormLabel id="refer-us-radio">{this.props.ratingTitle} </FormLabel>
                                <Container>
                                    {[...Array(5)].map((item, index) => {
                                        const givenRating = index + 1;
                                        return (
                                            <label>
                                                <RadioRate
                                                    type="radio"
                                                    value={givenRating}
                                                    onClick={() => { this.setState({ rating: givenRating }) }}
                                                />
                                                <Rating>
                                                    <FaStar
                                                        color={
                                                            givenRating < this.state.rating || givenRating === this.state.rating
                                                                ? "#d7ea4e"
                                                                : "rgb(192,192,192)"
                                                        }
                                                    />
                                                </Rating>
                                            </label>
                                        );
                                    })}
                                </Container>
                            </Grid>

                            {
                                this.props.isPopupFromEvaluate ? (
                                    <>
                                        {
                                            this.props.isanyEquationIncorrect ?
                                                (
                                                    <Grid item xs={12}>
                                                        <FormLabel id="refer-us-radio">Did the feedback help you solve the error?</FormLabel>
                                                        <Container>
                                                            {[...Array(5)].map((item, index) => {
                                                                const givenRating = index + 1;
                                                                return (
                                                                    <label>
                                                                        <RadioRate
                                                                            type="radio"
                                                                            value={givenRating}
                                                                            onClick={() => { this.setState({ incorrectfeedbackrating: givenRating }) }}
                                                                        />
                                                                        <Rating>
                                                                            <FaStar
                                                                                color={
                                                                                    givenRating < this.state.incorrectfeedbackrating || givenRating === this.state.incorrectfeedbackrating
                                                                                        ? "#d7ea4e"
                                                                                        : "rgb(192,192,192)"
                                                                                }
                                                                            />
                                                                        </Rating>
                                                                    </label>
                                                                );
                                                            })}
                                                        </Container>
                                                    </Grid>
                                                ) :
                                                (
                                                    <Grid item xs={12}>
                                                        <FormLabel id="refer-us-radio">Did the feedback validate your thinking, to progress further with confidence? </FormLabel>
                                                        <Container>
                                                            {[...Array(5)].map((item, index) => {
                                                                const givenRating = index + 1;
                                                                return (
                                                                    <label>
                                                                        <RadioRate
                                                                            type="radio"
                                                                            value={givenRating}
                                                                            onClick={() => { this.setState({ correctfeedbackrating: givenRating }) }}
                                                                        />
                                                                        <Rating>
                                                                            <FaStar
                                                                                color={
                                                                                    givenRating < this.state.correctfeedbackrating || givenRating === this.state.correctfeedbackrating
                                                                                        ? "#d7ea4e"
                                                                                        : "rgb(192,192,192)"
                                                                                }
                                                                            />
                                                                        </Rating>
                                                                    </label>
                                                                );
                                                            })}
                                                        </Container>
                                                    </Grid>
                                                )
                                        }


                                        <Grid item xs={12}>
                                            <FormLabel id="refer-us-radio">Did the immediate feedback help, while still thinking about the problem? </FormLabel>
                                            <Container>
                                                {[...Array(5)].map((item, index) => {
                                                    const givenRating = index + 1;
                                                    return (
                                                        <label>
                                                            <RadioRate
                                                                type="radio"
                                                                value={givenRating}
                                                                onClick={() => { this.setState({ evaluationhelprating: givenRating }) }}
                                                            />
                                                            <Rating>
                                                                <FaStar
                                                                    color={
                                                                        givenRating < this.state.evaluationhelprating || givenRating === this.state.evaluationhelprating
                                                                            ? "#d7ea4e"
                                                                            : "rgb(192,192,192)"
                                                                    }
                                                                />
                                                            </Rating>
                                                        </label>
                                                    );
                                                })}
                                            </Container>
                                        </Grid>

                                    </>
                                ) : null
                            }
                            <Grid item xs={12}>
                                <TextField
                                    name="feedbackcomment"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    id="feedbackcomment"
                                    label="Please provide any additional feedback or suggestions."
                                    value={this.state.feedbackcomment}
                                    onChange={this.handlefeedbackcommment}
                                />
                            </Grid>
                        </Grid>
                        <Button onClick={this.handleSubmit} type='submit' variant="contained" sx={{ mt: 3, mb: 2 }}> Submit </Button>
                        <Button onClick={this.props.onClose} variant="outlined" sx={{ mt: 3, mb: 2, ml: 2 }}> Cancel </Button>
                    </Box>

                    <ToastContainer />
                </div>
            </>
        )
    }
}

export default CommonFeedback;