import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { filter, isEmpty, isNil, orderBy } from "lodash";
import { CSVLink } from "react-csv";
import { getInClassQASummary, getProfessorsforInstituteCourse } from "../../common/API";
import { getCurrentUserId, formatDateUs, getMenuSelectedItem, getInstituteId, getIsAdminUser } from "../../common/Functions";
import DownloadIcon from '../../assets/images/download-icon.svg';
import './InClassQAReport.scss'


export default function InClassQAReport() {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [csvData, setCSVData] = useState([])
    const [sessionsName, setSessionsName] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedProf, setSelectedProf] = React.useState(0);



    useEffect(() => {
        if (!dataLoaded) {
            setDataLoaded(true);
            if (getIsAdminUser() === 'true') {
                fetchProfessorData();
            } else {
                fetchTableData()
            }

        }
    }, [dataLoaded])

    useEffect(() => {
        prepareCSVData();
    }, [data])

    useEffect(() => {
        if (selectedProf !== 0) {
            fetchTableData()
        }
    }, [selectedProf])
    const fetchProfessorData = () => {
        setIsLoading(true)
        const reqData = {
            params: {
                institute_id: getInstituteId(),
                "course_id": getMenuSelectedItem()
            }
        }
        getProfessorsforInstituteCourse(reqData).then(res => {
            if (!isNil(res.data.data) && !isEmpty(res.data.data)) {
                setSections(res.data.data);
                setTimeout(() => {
                    setSelectedProf(res.data.data[0]['prof_id']);
                }, 1000)

            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }

    const fetchTableData = () => {
        const reqData = {
            params: {
                Prof_id: getIsAdminUser() === 'true' ? selectedProf : getCurrentUserId(),
                "course_id": getMenuSelectedItem()
            }
        }

        setIsLoading(true)
        getInClassQASummary(reqData).then((res) => {
            if (!isNil(res.data) && !isNil(res.data.data)) {
                const sessions = res.data.data;
                const uniqueStudents = new Set();
                const sessionNames = []

                // Iterate through each session
                sessions.forEach(session => {
                    // Add session name to the set

                    sessionNames.push({ name: session.session_name, date: getFormatterData(session.session_date) });
                    // Iterate through the student list and add student names to the set
                    session.student_list.forEach(student => {
                        if (student.student_name) { // Ensure student name is not null
                            uniqueStudents.add(student.student_name.trim()); // Remove any extra spaces
                        }
                    });
                });

                // Convert sets to arrays for display or further processing
                const uniqueStudentArray = uniqueStudents.size > 0 ? ['Total - All Students', ...uniqueStudents] : [];
                // const sessionNameArray = [...sessionNames];
                setSessionsName([...sessionNames]);
                setData(prepareTableData(uniqueStudentArray, res.data.data))
            }
            setIsLoading(false);

        }).catch(err => {
            setData(prepareTableData([], []))
            setIsLoading(false)
        })
    }
    const handleDropDownChange = (event) => {
        setSelectedProf(event.target.value);
    };
    const getFormatterData = (dataStr) => {
        const date = new Date(dataStr);

        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        return formattedDate;
    }




    const prepareTableData = (studentsList, classData) => {
        try {
            let tableData = [];
            let firstRow = []
            studentsList.forEach((name, idx) => {
                let obj = { name: name }
                let participation_count = 0;
                let total_question_asked = 0
                classData.forEach(element => {
                    if (!isNil(element.student_list) && idx !== 0) {
                        let o = filter(element.student_list, function (o) { return o.student_name === name });
                        if (!isNil(o) && !isEmpty(o)) {
                            participation_count += 1;
                            total_question_asked += o[0]['question_count'];
                            obj[element.session_name] = o[0]['question_count'];
                        } else {
                            obj[element.session_name] = 0
                        }

                    } else if (idx === 0) {
                        obj[element.session_name] = element.student_list.reduce((sum, student) => sum + student.question_count, 0)
                    }
                })

                obj['participation_count'] = participation_count;
                obj['total_question_asked'] = total_question_asked;
                obj['total_sessions'] = classData.length;
                idx === 0 ? firstRow.push(obj) : tableData.push(obj)
            })

            return [...firstRow, ...orderBy(tableData, ['participation_count'], ['desc'])]
        } catch (e) {
            return []
        }

    }
    const prepareCSVData = () => {
        let tempArr = [];
        let a = ['Student Name', 'All Sessions Participation', 'All Sessions Questions']
        sessionsName.forEach(element => {
            a.push(element.name + "(" + getFormatterData(element.date) + ")")
        })

        tempArr.push(a)
        data.forEach((element, idx) => {
            if (idx > 0) {
                let arr = [element.name, element.participation_count, element.total_question_asked]
                sessionsName.forEach(item => {
                    arr.push(element[item.name])

                })
                tempArr.push(arr);
            }

        })

        setCSVData(tempArr)
    }
    return (
        <div className="qaReportContainer">
            <div className="exportCSV" style={{justifyContent: getIsAdminUser() === 'true' ? 'space-between':''}}>
                {
                    getIsAdminUser() === 'true' ?
                        <Box sx={{ minWidth: 200 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedProf}
                                    label="Section"
                                    onChange={handleDropDownChange}
                                >
                                    {
                                        sections.map((item) => (
                                            <MenuItem value={item.prof_id}>{item.prof_name}</MenuItem>
                                        ))
                                    }
                                    
                                </Select>
                            </FormControl>

                        </Box> : null
                }

                <CSVLink data={csvData} className='exportCSVbtn exportRepresentative'
                    filename={"In_Class_QA_Summary_ " + formatDateUs(Date.now()) + ".csv"}> Export To CSV
                    <img src={DownloadIcon}></img></CSVLink>
            </div>
            <div className="tableContainer">
                <TableContainer component={Paper} style={{ overflowX: 'hidden', minWidth: 'fit-content' }}>
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">

                        <TableHead>

                            <TableRow>
                                <TableCell >Student Name</TableCell>
                                <TableCell align="left">All Sessions Participation</TableCell>
                                <TableCell align="left">All Sessions Questions</TableCell>
                                {
                                    sessionsName.map((item) => (
                                        <TableCell align="left"><div className="tableHeader" >
                                            <div style={{ textWrap: 'nowrap' }}>{item.name}</div>
                                            <div className="subHeader">({item.date})</div>
                                            <div className="subHeader"># Questions</div>
                                        </div>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ?
                                <TableRow>
                                    <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{'Fetching Data...'}</TableCell>
                                </TableRow> :
                                data.length > 0 ?
                                    data.map((row, index) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" style={{ fontSize: index === 0 ? 'medium' : '' }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {index === 0 ? null : <>
                                                    <progress id={`idx_progress${index}`} value={(row.participation_count / row.total_sessions) * 100} max={100}>
                                                        {`${row.participation_count}`}
                                                    </progress>
                                                    <span style={{ paddingLeft: '5px' }}>{row.participation_count}/{row.total_sessions}</span>
                                                </>}


                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                {index === 0 ? null : row.total_question_asked}
                                            </TableCell>
                                            {
                                                sessionsName.map((item) => (
                                                    <TableCell align="center" style={{ fontSize: index === 0 ? 'medium' : '' }}>{row[item.name]}</TableCell>
                                                ))
                                            }

                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{'No Records Found...'}</TableCell>
                                    </TableRow>

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>

    )
}