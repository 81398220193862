import React, { Component } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Modal, Button } from 'react-bootstrap';
import VideoConferenceSideBar from '../../components/VideoConferenceSideBar/VideoConferenceSideBar';
import Answerboard from '../../components/Answerboard/Answerboard';
import './Whiteboard.scss';
import { withNamespaces } from 'react-i18next';
import Modal1 from '../../components/Common/ModalComponent/ModalComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import {
    fetchChallenges, getdemouserdetail, savedemouserdetail, getUserRoleByUserId
} from '../../common/API';
import queryString from 'query-string';
import AnswerBoardMobile from '../../components/SolutionBoard/AnswerBoardMobile';
import { getCurrentUserId, getCurrentUserRole, User_Role, Call_Type, showFullApp, getUserFingerprint, isDemoUser, Problem_source, convertSecondsToHMS, convertDurationToSeonds, getInstituteId } from '../../common/Functions';
import SolutionBoard from '../../components/SolutionBoard/SolutionBoard';
import { AnyProblem_Challenge_Type, bothExceedMessage, problemsExceedMessage, contentExceedMessage, demoUserId, interactiveContentExceedLimit, interactiveProblemExceedLimit, timeLapseCounter, Institute } from '../../common/Constants';
import { isEmpty, isNil, random } from 'lodash';
import Cookies from 'universal-cookie';
import { TimelapseSharp } from '@material-ui/icons';


const startTime = Date.now()
let interval;
let timeCounter = null
class Whiteboard extends Component {

    state = {
        toggleVideoConfShowModal: false,
        isTeacher: false,
        question: null,
        challengeMapId: null,
        showModal: false,
        modalStatus: 'progress',
        boardId: null,
        tutorid: null,
        disablesynopsisbutton: true,
        isAnyProblem: true,
        type: null,
        session_id: null,
        demoExceedModalOpen: false,
        videoWatchCount: 0,
        useruniquecode: null,
        hw_id: null,
        timer: "",
        loader: false,
        pMode: null,
        timeLapseString: "",
        isReview: false,
        isguided: false,
        isgraded: false,
        totaltrials: 0,
        istimed: false,
        assignmenttimeinsec: 0,
        isFromAssignment: false,
        studentId: 0,
        studentRole: ""
    }

    maincontainerclass = "whiteboard-container"
    pSource = ""

    cookies = new Cookies();

    toggleVideoConfModal = (data) => {
        localStorage.removeItem("studentroomid")
        this.setState((prevState) => {
            return {
                toggleVideoConfShowModal: !prevState.toggleVideoConfShowModal,
                tutorid: data
            }
        }, () => {

        })
        this.setState({ disablesynopsisbutton: false })


    }
    setBoardId = (id) => {
        this.setState({ boardId: id });
    }

    componentWillMount() {
        let sessionId = Date.now()
        this.setState({ session_id: sessionId })

        if (window.location.href.includes("answerboardpt")) {
            if (this.props.match.params.studentId !== undefined && this.props.match.params.studentId !== null && this.props.match.params.studentId > 0) {
                this.setState({ studentId: parseInt(this.props.match.params.studentId) })
                debugger;
                const reqData = { params: { 'user_id': parseInt(this.props.match.params.studentId) } }
                getUserRoleByUserId(reqData).then(res => {
                    debugger;
                    if (res.status === 200) {
                        if (res.data.user_role !== undefined && res.data.user_role !== null) {
                            this.setState({ studentRole: res.data.user_role })
                        }
                    }
                })
            }

            let guided = this.cookies.get("isguided", { pathname: "/" })
            let graded = this.cookies.get("isgraded", { pathname: "/" })
            let trials = this.cookies.get("totaltrials", { pathname: "/" })
            let timed = this.cookies.get("istimed", { pathname: "/" })
            let review = this.cookies.get("isreview", { pathname: "/" })
            let asgTimeInSec = this.cookies.get("assignmenttime", { pathname: "/" })

            this.setState({ isFromAssignment: true, isguided: guided, isgraded: graded, totaltrials: trials, istimed: timed, isReview: review, assignmenttimeinsec: asgTimeInSec })

            if (this.cookies.get("timelapse", { pathname: "/" }) !== "") {
                let timeLapse = this.cookies.get("timelapse", { pathname: "/" })
                this.setState({ timeLapseString: timeLapse })
            }
        }
    }

    componentWillUnmount() {
        if (this.cookies.get("review", { pathname: "/" }) === false) {
            this.cookies.set("fromFRQ", true, { path: "/" })
        }
    }

    async componentDidMount() {
        this.setState({ loader: true })
        if (!isNil(this.props.location.state) && !isEmpty(this.props.location.state) && !isNil(this.props.location.state["problemSource"]) && !isEmpty(this.props.location.state["problemSource"])) {

            this.setState({ problem_source: this.props.location.state["problemSource"][0] })
            this.pSource = this.props.location.state["problemSource"][0]
        }
        else {
            this.pSource = Problem_source.interactiveproblems
        }

        let question = null;
        const { hasCustomQuestion } = queryString.parse(window.location.search);
        debugger
        if (this.props.match.params.teacher && this.props.match.params.teacher > 0) {
            // Teacher's URL
            question = 'teacher'
        }

        if (this.props.match.params.homeworkId !== undefined && this.props.match.params.homeworkId !== null && this.props.match.params.homeworkId !== "") {
            this.setState({ hw_id: this.props.match.params.homeworkId })
        }

        let customQuestion = null, challengeOnly = false;
        try {
            let params = new URLSearchParams(window.location.search);
            customQuestion = params.get('ques');
        } catch (e) {
            if (hasCustomQuestion) {
                customQuestion = hasCustomQuestion
            }
        }

        try {
            challengeOnly = this.props.location.state.challengeOnly
        } catch (e) { }

        if (this.props.match.params.questionId === AnyProblem_Challenge_Type.AnyProblem) {
            this.setState({ isAnyProblem: true, customQuestion: " ", type: "FRQ" })
        }
        else {
            this.setState({ isAnyProblem: false })
            if (customQuestion && !hasCustomQuestion && !this.props.match.params.teacher) {
                this.setState({ showModal: true }, () => {
                    setTimeout(() => {
                        this.setState({ modalStatus: 'success' })
                    }, 5000)
                });
            } else if (!question) {
                try {
                    debugger;
                    const reqData = { params: { 'challengeId': this.props.match.params.questionId, 'user_id': this.state.studentId > 0 ? this.state.studentId : getCurrentUserId(), 'user_role': (this.state.studentRole !== "" ? this.state.studentRole : getCurrentUserRole()) } }
                    await fetchChallenges(reqData)
                        .then(res => {
                            question = res.data;
                            this.setState({ type: res.data.type, challengeMapId: res.data.challenge_map_id });

                        }).catch(err => {
                            alert(err.message);
                            alert('Some error occurred');
                            this.props.history.goBack();
                        })
                } catch (e) {
                    this.props.history.push('/');
                }
            }
            if (question === undefined && customQuestion === undefined && !challengeOnly) {
                this.props.history.push('/');
            }

            debugger;
            if ((this.props.match.params.teacher && this.props.match.params.teacher > 0 && !this.props.match.params.studentId) || challengeOnly) {
                debugger;
                fetchChallenges({ params: { challengeId: this.props.match.params.questionId, 'user_id': getCurrentUserId(), 'user_role': getCurrentUserRole() } })
                    .then(res => {
                        this.setState({
                            question: res.data,
                            challengeMapId: res.data.challenge_map_id,
                            customQuestion: customQuestion,
                            type: res.data.type,
                        })

                        if (this.props.match.params.teacher) {
                            this.setState({
                                isTeacher: true,
                                toggleVideoConfShowModal: true
                            })
                        }
                    }).catch(err => {
                        console.error(err.message);
                        alert('Some error occurred');
                        this.props.history.goBack();
                    })
            } else {
                debugger;
                this.setState({ question: question, customQuestion: customQuestion })
            }
        }
        // Scroll to whiteboard container
        window.$([document.documentElement, document.body]).animate({
            scrollTop: window.$(".whiteboard-container").offset().top
        }, 500);


        if (isDemoUser() === "true") {
            // const client = new ClientJS();
            const fingerprint = getUserFingerprint() //client.getFingerprint();
            this.setState({ useruniquecode: fingerprint })
            const reqData = { params: { 'useruniquecode': fingerprint } }
            getdemouserdetail(reqData).then(res => {
                if (res.status === 200) {
                    if (!isNil(res.data)) {
                        if (res.data.totalproblemscount >= interactiveProblemExceedLimit) {
                            this.maincontainerclass = this.maincontainerclass + "  disabledDiv"
                            this.setState({ demoExceedModalOpen: true, videoWatchCount: res.data.totalcontentcount })
                        }
                        else {
                            const reqData = {
                                useruniquecode: fingerprint,
                                iscontent: 0,
                                isany: this.state.isAnyProblem ? 1 : 0,
                                challenge_content_id: this.state.isAnyProblem ? 0 : this.props.match.params.questionId
                            }
                            savedemouserdetail(reqData)
                                .then(res => {
                                })
                                .catch(err => {
                                    console.error(err.message);
                                })
                        }
                    }
                }
            })
        }

        setTimeout(() => {
            this.setState({ loader: false })
        }, 1000)
    }

    handledemoExceedModal = () => {
        this.setState({ demoExceedModalOpen: false })
    }



    render() {
        let modalBody = (this.state.showModal ?
            <div className="waiting-for-teacher-modal-content">
                <h4>Please wait while we connect you to a teacher.</h4>
                <p>Finding a tutor to match your profile and preferences.</p>
                <Grid container justify="center">
                    {this.state.modalStatus === 'success' ? (
                        <Typography>Yay, we found someone to assist you.</Typography>
                    ) : (
                        <Fade
                            in={this.state.modalStatus === 'progress'}
                            style={{ transitionDelay: this.state.modalStatus === 'progress' ? '800ms' : '0ms' }}
                            unmountOnExit>
                            <CircularProgress />
                        </Fade>
                    )}
                </Grid>
            </div> : null
        )
        let answerboard = null;

        let isTeacher = (this.props.match.params.teacher && this.props.match.params.teacher > 0) ? true : false;
        // let homeworkId = this.props.match.params.homeworkId
        if (this.state.customQuestion && showFullApp()) {
            answerboard = <Answerboard
                vcState={this.state.toggleVideoConfShowModal}
                toggleVC={this.toggleVideoConfModal}
                setBoardId={this.setBoardId}
                boardId={this.state.boardId}
                challengeMapId={this.state.challengeMapId}
                teacher={isTeacher}
                // homeworkproblems_id={homeworkId}
                disablesynopsisbutton={this.state.disablesynopsisbutton}
                challengeId={this.props.match.params.questionId}
                call_type={Call_Type.Challenge}
                hasActiveConference={this.state.toggleVideoConfShowModal}
                question={{
                    question: this.state.customQuestion,
                    custom: true,
                    type: this.state.type,
                }}
                anyProblem={this.state.isAnyProblem}
                session_id={this.state.session_id}
                hw_id={this.state.hw_id}
                isGuided={this.state.isguided}
                isGraded={this.state.isgraded}
                totalTrials={this.state.totaltrials}
                isTimed={this.state.istimed}
                assignmentTime={this.state.assignmenttimeinsec}
                isReview={this.state.isReview}
                timeLapseString={this.state.timeLapseString}
                isFromAssignment={this.state.isFromAssignment}
                studentId={this.state.studentId}
                studentRole={this.state.studentRole}
            />
        } else if (this.state.question && showFullApp()) {
            answerboard = <Answerboard
                vcState={this.state.toggleVideoConfShowModal}
                toggleVC={this.toggleVideoConfModal}
                setBoardId={this.setBoardId}
                boardId={this.state.boardId}
                disablesynopsisbutton={this.state.disablesynopsisbutton}
                challengeMapId={this.state.challengeMapId}
                teacher={isTeacher}
                // homeworkproblems_id={homeworkId}
                challengeId={this.props.match.params.questionId}
                hasActiveConference={this.state.toggleVideoConfShowModal}
                call_type={Call_Type.Challenge}
                question={{
                    ...this.state.question,
                    custom: false,
                    type: this.state.type,
                }}
                anyProblem={this.state.isAnyProblem}
                session_id={this.state.session_id}
                hw_id={this.state.hw_id}
                isGuided={this.state.isguided}
                isGraded={this.state.isgraded}
                totalTrials={this.state.totaltrials}
                isTimed={this.state.istimed}
                assignmentTime={this.state.assignmenttimeinsec}
                isReview={this.state.isReview}
                timeLapseString={this.state.timeLapseString}
                isFromAssignment={this.state.isFromAssignment}
                studentId={this.state.studentId}
                studentRole={this.state.studentRole}
            />
        }
        else if (this.state.question && !showFullApp()) {
            answerboard = <AnswerBoardMobile
                vcState={this.state.toggleVideoConfShowModal}
                toggleVC={this.toggleVideoConfModal}
                setBoardId={this.setBoardId}
                boardId={this.state.boardId}
                challengeMapId={this.state.challengeMapId}
                teacher={isTeacher}
                disablesynopsisbutton={this.state.disablesynopsisbutton}
                challengeId={this.props.match.params.questionId}
                call_type={Call_Type.Challenge}
                hasActiveConference={this.state.toggleVideoConfShowModal}
                question={{
                    ...this.state.question,
                    custom: false,
                    type: this.state.type,
                }}
                anyProblem={this.state.isAnyProblem}
                session_id={this.state.session_id}
                hw_id={this.state.hw_id}
                isGuided={this.state.isguided}
                isGraded={this.state.isgraded}
                totalTrials={this.state.totaltrials}
                isTimed={this.state.istimed}
                assignmentTime={this.state.assignmenttimeinsec}
                isReview={this.state.isReview}
                timeLapseString={this.state.timeLapseString}
                isFromAssignment={this.state.isFromAssignment}
                studentId={this.state.studentId}
                studentRole={this.state.studentRole}
            ></AnswerBoardMobile>
        }
        return (
            <>
                {/* <Breadcrumb className='d-flex flex-row breadCrumbContainer'
                    currentNode={this.state.question ? this.state.question.title : (this.state.customQuestion ? this.state.customQuestion : [])}
                    prevNode={this.props.location.state ? this.props.location.state : []} /> */}

                <Modal
                    show={this.state.demoExceedModalOpen}
                    onHide={this.handledemoExceedModal.bind(this)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4> Alert </h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.videoWatchCount < interactiveContentExceedLimit ?
                            <div dangerouslySetInnerHTML={{ __html: problemsExceedMessage(this.state.useruniquecode) }} />
                            :
                            <div dangerouslySetInnerHTML={{ __html: bothExceedMessage(this.state.useruniquecode) }} />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handledemoExceedModal.bind(this)} >OK</Button>
                    </Modal.Footer>
                </Modal>

                <Modal1
                    show={this.state.showModal}
                    handleClose={() => { this.setState({ showModal: false, toggleVideoConfShowModal: true }) }}
                    body={modalBody}
                    disableClose={true}
                    hideHeader={true}
                    setStatic={true}
                    showButton={this.state.modalStatus === 'success'} />



                {/* {this.state.isAnyProblem || !showFullApp() ? null :
                    <div className='videoClassWhiteBoard'>
                        <Button className='btnHelpCenter' onClick={this.handleHelpCenterClickEvent}>
                            <HelpRoundedIcon className='helpIcon' />    Help Center</Button>
                    </div>
                } */}
                <div className={this.maincontainerclass}>
                    {/* {this.state.timer !== "" ?
                        <div style={{ display: 'none' }}>
                            <Countdown onTick={this.setTimeCounterHandler} date={startTime + this.state.timer} renderer={this.startExamTimeCountdown} />
                        </div>
                        :
                        null
                    } */}
                    <div className='answer-board-holder'>
                        {answerboard}
                    </div>

                    {/* <div className={`vc-container ml-auto ${this.state.toggleVideoConfShowModal ? 'modal--visible' : 'modal--invisible'}`}> */}
                    {this.state.toggleVideoConfShowModal ? <VideoConferenceSideBar
                        showModal={this.state.toggleVideoConfShowModal}
                        isTeacher={this.state.isTeacher}
                        boardId={this.state.boardId}
                        challengeId={this.props.match.params.questionId}
                        challengeMapId={this.state.challengeMapId}
                        tutorid={this.state.tutorid}
                        call_type={Call_Type.Challenge}
                        customQuestion={this.state.customQuestion ? this.state.customQuestion : false}
                        toggleVideoConfModal={this.toggleVideoConfModal}
                        problem_source={this.pSource}
                        from="wh"
                    />
                        : null}
                    {/* </div> */}
                </div>
                {
                    this.state.loader && (
                        <CircularProgress
                            size={24}
                            style={{
                                color: "#164b99",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )
                }

            </>
        )
    }
}

export default withNamespaces()(Whiteboard);