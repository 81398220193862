import React from "react";
import "./Progress.scss";
import Modal from "../ModalComponent/ModalComponent";
import Aux from "../../../hoc/Wrapper";
//import Table from "react-bootstrap/Table";
import progressData from "../../../data/user.json";
import lastProgress from "../../../assets/images/last-progres.png";
import totalProgress from "../../../assets/images/total-progres.png";
import { ProgressUpdatescalculation, problemcompletion_byweek } from '../../../common/API'
import { getCurrentUserRole, User_Role, getCurrentUserId, formatNumber, getMenuSelectedItem } from '../../../common/Functions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ProgressByTopic from "./ProgressByTopic";
import ProgressByWeek from "./ProgressByWeek";



export default class Progress extends React.Component {
    state = {
        showModal: false,
        topiccompletiondata: [],
        page: 0,
        rowsPerPage: 5,
        progressproficiency: 0,
        progresscompletion: 0,
        peerscompletion: 0,
        loading: false,
        weekcaption: [],
        weekdata: [],
        captionlist:[],
        weekfinaldata:[],
        openByWeekModal: false
    };

    modalBody = null;
    modalTitle = null;
    showButton = false;


    componentDidMount() {
        this.setState({ loading: true })
        const reqData = {
            params: {
                user_id: getCurrentUserId(),
                course_id: getMenuSelectedItem()
            },
        };
        ProgressUpdatescalculation(reqData).then((res) => {
            this.setState({
                progressproficiency: res.data.progressupdates.proficiency,
                progresscompletion: res.data.progressupdates.completion,
                peerscompletion: res.data.progressupdates.peerscompletion,
                weekcaption: res.data.progressupdates.weeklist,
                weekdata: res.data.progressupdates.datalist,
                captionlist: res.data.progressupdates.labellist,
                weekfinaldata:res.data.progressupdates.finaldata,
            }, () => {
                this.setState({ loading: false })

            });
        });
    }



    openModal = (event, modalTitle, modalBody, showButton = false) => {
        this.modalBody = (
            <>
                {modalBody}
            </>
        );
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.setState({ showModal: true });
        event.stopPropagation();
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    handleChangePage = (event, page) => {
        this.setState({ page }, () => {
            this.raiseHandClickHandler(event)
        });

    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
            this.raiseHandClickHandler(event)
        });
    };

    raiseHandClickHandler = (event) => {
        let listdata = null;
        listdata = <>
            <div style={{ width: '1000px', height: "550px", overflowY: "auto" }}>
                <ProgressByTopic></ProgressByTopic>

            </div>
        </>
        this.openModal(
            event,
            "Progress by Topics",
            listdata,
            true)
    }

    handleviewbyweek = (e) => {
        let listdata = null;
        listdata = <>
            <ProgressByWeek weekcaption={this.state.weekcaption} weekdata={this.state.weekdata} captionlist ={this.state.captionlist} finaldata={this.state.weekfinaldata}></ProgressByWeek>
        </>
        this.openModal(
            e,
            "Progress by Week",
            listdata,
            true)
    }



    render() {
        // const viewByWeeks = (
        //     <div style={{ width: '600px' }}>
        //         <img
        //             src={progressData.progress_by_weeks}
        //             alt="Progress by weeks"
        //             className="responsive-img"
        //         />
        //         <span style={{ float: 'right', marginTop: '5px' }} className="sample-data-label text-right flex-grow-1">* Sample Data</span>
        //     </div>
        // );

        return (
            <Aux>
                {
                    this.state.loading === false ?
                        (<div className="section-icon-text" onClick={this.props.clicked}>
                            <div className="progress-content">
                                <div className="peers-name">
                                    <div className="mb-1 font-14">Yours</div>
                                    <div className="font-14">Peers</div>
                                </div>
                                <div className="progress-update">
                                    <label
                                        data-toggle="modal"
                                        data-target="#progressUpdate"
                                    >
                                        Completion
                                    </label>
                                    <ProgressUpdateBar
                                        total={this.state.progresscompletion}
                                        lastWeek={7}
                                        addBottomMargin
                                    />
                                    <ProgressUpdateBar total={this.state.peerscompletion} lastWeek={7} />
                                </div>
                            </div>
                            <div className="progress-content">
                                <div className="peers-name">
                                    <div className="mb-1 font-14">Yours </div>
                                    <div className="font-14">Peers </div>
                                </div>
                                <div className="progress-update">
                                    <label
                                        data-toggle="modal"
                                        data-target="#progressUpdate"
                                        className="text-danger"
                                    >
                                        Proficiency
                                    </label>
                                    <ProgressUpdateBar
                                        total={this.props.totalproficiency}
                                        lastWeek={7}
                                        addBottomMargin
                                        hasRedBackground
                                    />
                                    <ProgressUpdateBar
                                        total={32}
                                        lastWeek={5}
                                        hasRedBackground
                                    />
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="last-progress">
                                    <img src={lastProgress} alt="" />
                                    <label className="progres2 text-muted">
                                        Last Week
                                    </label>
                                </div>
                                <div className="total-progress">
                                    <img src={totalProgress} alt="" />
                                    <label className="progres1 text-muted">Total</label>
                                </div>
                            </div>
                            <div className="bottom-progress">
                                <div
                                    onClick={
                                        (event) => this.raiseHandClickHandler(event)
                                    }
                                >
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="progress_tab"
                                    >
                                        View By Topics
                                    </a>
                                </div>
                                <div
                                    onClick={
                                        (event) => this.handleviewbyweek(event)
                                    }
                                    // onClick={(event) =>
                                    //     this.openModal(
                                    //         event,
                                    //         "Progress by Week",
                                    //         viewByWeeks,
                                    //         true
                                    //     )
                                    // }
                                >
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="progress_tab"
                                    >
                                        View By Weeks
                                    </a>
                                </div>
                            </div>
                        </div>)
                        : <>
                            <button class="btn btn-primary" type="button" disabled>
                                <span style={{ marginRight: '5px' }} class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Processing...
                            </button>
                        </>
                }

                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={this.modalTitle}
                    body={this.modalBody}
                    showButton={this.showButton}

                />

            </Aux>
        );
    }
}

const ProgressUpdateBar = (props) => {

    return (
        <div
            className="progress-update-bars"
            style={{ marginBottom: props.addBottomMargin ? "5px" : "0px" }}
        >
            <div class="progress">
                <div
                    role="progressbar"
                    class="progress-bar outer"
                    aria-valuenow={props.total}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                        width: `${props.total}%`,
                        backgroundColor: props.hasRedBackground
                            ? "red"
                            : "#3567FC",
                    }}
                >
                    <span className="label">
                        {props.total !== null && props.total !== undefined ? formatNumber(props.total) : 0.00}%
                    </span>
                </div>
            </div>
        </div>
    );
};
