import { clone, filter, isEmpty, isNil, uniqBy } from "lodash";
import React from "react";
import './Graph.scss';
import GraphNode from "./GraphNode";
import { Spinner } from "react-bootstrap";
import { getGraphData } from "../../../common/API";
import { getCurrentUserId, showFullApp, getMenuSelectedItem } from "../../../common/Functions";



const CHART_TYPE = {
  BASIC: 'basic',
  PROFICIENCY: 'prof',

}
class GraphComponent extends React.Component {

  edges = [];
  nodes = [];
  nodesView;
  network;
  highlightActive = false;
  edgesView;
  nodeGenerated = false;
  chapterColors = [];
  chartType = CHART_TYPE.PROFICIENCY;
  nodeColors = ['#77DC77', '#0AA80A', '#007C00', '#004400', '#758bc6', '#3B57A4', '#17378D', '#0A2368', '#031139', '#FFD98A', '#F4BB44', '#D2930D', '#553900', '#FF8A8A', '#F44444', '#D20D0D', '#9B0000', '#FFED91', '#FFD600', '#6C5B00', '#A282D6', '#6E42B8', '#4C10AE', '#1E034A', '#FF9900', '#FFA891', '#FF704A', '#FF3500', '#5165FB', '#001EFF', '#FF7800', '#FFE800', '#FFB300', '#B36B00', '#B32500']  // nodeColors = ['#55DDE0', '#33658A', '#2F4858', '#F5AE2D', '	#F26419', '	#A4243B', '#D8C99B', '#D8973C', '#BD632F', '#273E47',
  //   '#FAA275', '#FF8C61', '#C36A85', '#985277', '#5C374C', '#7C6A0A', '#FFDAC6', '#FA9500', '#EB6424', '#FB6107', '#F3DE2C', '#7CB518',
  //   '#5C8001', '#FBB02D', '	#8ECAE6', '#219EBC', '#126782', '#023047', '#FFB703', '#FD9E02', '#FB8500', '#33658A', '#86BBD8', '#2F4858', '#F26419']
  constructor(props) {
    super(props);
    this.chartType = !isNil(props.chartType) ? props.chartType : CHART_TYPE.PROFICIENCY;
    this.state = {
      rawNodes: [], edges: this.edges, nodes: [], pathToNode: [], showContentOptions: false, contentOptionsPosition: {},
      lastHoveredPoint: null, lastClickedNode: '', isChartRendered: false, heirarchyLevel: 0
    }
  }

  componentDidMount() {
    if (this.chartType === CHART_TYPE.PROFICIENCY) {
      this.loadGraphData();
    } else {
      this.setState({
        nodes: this.getFormattedNodes(this.props.nodes),
        edges: this.getFormatedEdges(this.props.edges)
      })
      setTimeout(() => this.createGraph(), 1000)
    }
  }

  loadGraphData() {
    let assignment_id = ""
    let userId = this.props.isauthordashboard ? this.props.selectedstudentId : getCurrentUserId()
    if (userId === undefined || userId === "" || userId === null) {
      userId = getCurrentUserId()
    }
    if (this.props.assignment_id !== undefined && this.props.assignment_id !== null) {
      assignment_id = this.props.assignment_id
    }

    const reqData = { params: { 'user_id': userId, "isPT": this.props.isPT, "course_id": getMenuSelectedItem(), "assignment_id": assignment_id } }
    const params = this.state.heirarchyLevel === 0 ? '' : `level1=${this.state.lastClickedNode}`
    getGraphData(params, reqData).then(res => {
      if (this.state.heirarchyLevel === 0) {
        // if (this.chartType === CHART_TYPE.PROFICIENCY) {
        this.setState({
          rawNodes: this.getNodePosition(uniqBy(res.data.nodesArray, 'id'), res.data.display_location),
          edges: this.getFormatedEdges(res.data.edgesArray),
          pathToNode: res.data.pathToNode
        })
        // }
      }
    })
  }
  getUniqueChapters() {
    const uniqueChapters = uniqBy(this.state.rawNodes, 'chapter');
    let items = [];
    uniqueChapters.forEach((item, index) => {
      items.push({ chapterId: item.chapter, color: this.nodeColors[index] })
    })

    this.chapterColors = items;
  }

  getNodePosition = (nodes, positions) => {
    if (!isNil(positions)) {
      let fixPosition = positions.locations;
      nodes.forEach(element => {
        let node = filter(fixPosition, function (o) { return o.id === element.id });
        if (!isNil(node) && !isEmpty(node)) {
          element.x = node[0].x;
          element.y = node[0].y
        }
      })
    }


    return [...nodes]

  }
  getFormattedNodes(nodes) {

    nodes.forEach(element => {
      if (element.type.toLowerCase() === 'other') {
        element.color = '#ccc';
      } else if (element.type.toLowerCase() === 'correct') {
        element.color = '#BFD4FF';
      } else {
        element.color = '#ff6666';
      }

      element.size = 40;
      element.shape = "dot";
      element.font = { face: "Open Sans", align: "left", color: 'black' }

    });

    return [...nodes]
  }
  getFormatedEdges(edges) {
    if (this.chartType === CHART_TYPE.PROFICIENCY) {
      edges.forEach((edge, index) => {
        edge.smooth = { type: 'curvedCCW', roundness: 0.2 }
        if (edge.type === 'prereq') {
          edge.color = '#848C9D';
        } else {
          edge.color = '#fff'
        }
      })
    } else {
      edges.forEach((edge) => {
        edge.dashes = 'true';
        edge.width = 2;
        edge.arrows = 'to';
        if (edge.label === "None Found") {
          edge.color = '#ccc';
          edge.font = { face: "Open Sans", align: "top", color: '#ccc' }
        }
        else {
          edge.color = '#848C9D';
          edge.font = { face: "Open Sans", align: "top", color: 'black' }
        }
      })
    }
    return [...edges];
  }

  createNodes() {
    let nodeItems = [];
    if (!this.nodeGenerated && this.state.rawNodes.length > 0) {
      this.getUniqueChapters();
      for (var i = 0; i < this.state.rawNodes.length; i++) {
        const node = this.state.rawNodes[i];

        nodeItems.push(<GraphNode id={node.id} label={node.label}
          type={node.type} chapter={node.chapter.toString()} x={node.x} y={node.y}
          onNodeCreation={this.onNodeCreation} chapterColors={this.chapterColors}
          prof={node.proficiency_score} avg={node.average_score}></GraphNode>)
      }
      this.nodeGenerated = true;

    }
    return nodeItems;
  }

  onNodeCreation = (node) => {
    let tempArr = this.state.nodes;
    tempArr.push(node)
    this.setState({
      nodes: tempArr
    })
    if (this.state.nodes.length === this.state.rawNodes.length) {
      this.createGraph();
    }
  }

  createGraph = () => {
    const container = document.getElementById(this.props.componentId);
    this.nodesView = new window.vis.DataSet(this.state.nodes);
    this.edgesView = new window.vis.DataSet(this.state.edges);


    let data = {
      nodes: this.nodesView,
      edges: this.edgesView,
    };
    let options = {
      physics: false,
      interaction: { hover: true },
      nodes: {
        borderWidth: 1,
        size: 40,
        color: {
          border: "#222222",
          background: "#666666",
        },
        font: { color: "#eeeeee" },
        shadow: false,
      },
      edges: {
        color: "#BFD4FF",
        shadow: false
      }
    };

    if (this.chartType !== CHART_TYPE.PROFICIENCY) {
      options.physics = {
        "barnesHut": {
          "springConstant": 0,
          "avoidOverlap": 0.4,
        }
      }
    }
    this.network = new window.vis.Network(container, data, options);
    const openPopupCallBack = this.props.openContentOptionsModal;
    const tabchange = this.props.handletabchange;
    const nodeHoverHanlder = this.nodeHoverHanlder.bind(this);
    const onChartRendered = this.onChartRendered.bind(this);
    const nodeClickHandler = this.nodeClickHandler.bind(this);
    this.network.on("afterDrawing", (ctx) => {
      onChartRendered();
    });

    // this.network.once('stabilizationIterationsDone', () => {
    //   this.network.moveTo({
    //     position: { x: 700, y: 1000 },
    //     scale: 0.7,
    //     offset: { x: 0, y: 0 },
    //     animation: {
    //       duration: 1000, // in milliseconds
    //       easingFunction: "easeInOutQuad"
    //     }
    //   });
    // })

    // this.network.once('afterDrawing', () => {
    //   this.network.moveTo({
    //     position: { x: 700, y: 1100 },
    //     scale: 0.7,
    //     offset: { x: 0, y: 0 },
    //     animation: {
    //       duration: 1000, // in milliseconds
    //       easingFunction: "easeInOutQuad"
    //     }
    //   });
    // })
    if (this.chartType === CHART_TYPE.PROFICIENCY) {
      this.network.on("click", function (params) {
        if (isNil(params.nodes[0]) && isEmpty(params.nodes[0])) {
          openPopupCallBack(params.event, null, null, false);
          nodeClickHandler(params);
        }
      });
      this.network.on("doubleClick", function (params) {
        if (showFullApp()) {
          nodeClickHandler(params)
          tabchange();
          if (!isNil(params.nodes[0]) && !isEmpty(params.nodes[0])) {

            const arr = (params.nodes[0]).split('-');
            // debugger;
            openPopupCallBack(params.event, arr.length > 1 ? arr[1] : arr[0], arr.length > 1 ? "Section" : "Chapter", false);

          } else {
            openPopupCallBack(params.event, null, null, false);
          }
        }
      });
      var element = document.getElementById("nodeContainer");
      element.parentNode.removeChild(element);
    }

  }
  onChartRendered = () => {
    if (!this.state.isChartRendered && this.chartType === CHART_TYPE.PROFICIENCY) {
      var scaleOption = { scale: 0.5 };
      this.network.moveTo(scaleOption);
    }
    this.setState({ isChartRendered: true })
  }

  hightlightPostReqEdges = (selectedNode) => {
    let tempEdges = [];
    this.edgesView.forEach((edge) => {
      if ((edge.to === selectedNode || edge.from === selectedNode) && edge.type === 'prereq') {
        edge.color = '#848C9D'
      } else if (edge.type === 'prereq' && selectedNode !== '') {
        edge.color = '#BFD4FF'
      } else if (edge.type === 'prereq' && selectedNode === '') {
        edge.color = '#848C9D'
      }

      tempEdges.push(edge)

    })
    this.edgesView.update(tempEdges);
  }
  nodeHoverHanlder(params) {
    this.setState({ lastHoveredPoint: params.event })
  }
  nodeClickHandler = (params) => {
    if (params.nodes.length > 0) {
      const arr = params.nodes[0]
      this.hightlightPostReqEdges(params.nodes[0]);
      this.setState({ lastClickedNode: params.nodes[0] });
      //const item = this.state.nodes.filter((item) => item.id === this.state.lastClickedNode);
      // if (item[0]['type'] === 'chapter') {
      //   this.setState({ heirarchyLevel: 1 });
      // } else {
      //   this.setState({ heirarchyLevel: 2 });
      // }
      //this.loadGraphData();
    } else {
      this.hightlightPostReqEdges('');
    }

    let allEdges = this.edgesView.get({ returnType: 'Array' });
    let updateEdgesArray = [...filter(clone(allEdges), function (o) { return o.type === 'path' })]
    this.edgesView.remove(updateEdgesArray)
    if (!isNil(this.state.pathToNode) && !isEmpty(this.state.pathToNode)) {
      this.neighbourhoodHighlight(params, this.state.pathToNode);
    }

  }

  neighbourhoodHighlight = (params, pathToNode) => {
    // if something is selected:
    let allNodes = this.nodesView.get({ returnType: "Object" });

    if (params.nodes.length > 0) {
      this.highlightActive = true;
      var selectedNode = params.nodes[0];

      const item = pathToNode.filter((item) => item.id === this.state.lastClickedNode);
      let selectableNodes = [];
      if (!isNil(item) && !isEmpty(item)) {
        selectableNodes = [...item[0].value]
        let pathEdges = item[0]['links'];
        let edgesArr = [];
        pathEdges.forEach((edge) => {
          edge.smooth = { type: 'curvedCCW', roundness: 0.2 }
          edge.background = {
            enabled: true,
            color: "#006CBB",
            size: 3,
            dashes: true,
            shadow: true
          }
          edgesArr.push(edge)
        })
        this.edgesView.add(edgesArr);
      }

      for (var nodeId in allNodes) {
        allNodes[nodeId].color = "rgba(255,255,255,0.5)";
        allNodes[nodeId].shadow = false;
      }
      var connectedNodes = [];
      selectableNodes.forEach((node) => {
        for (var nodeId in allNodes) {
          if (nodeId.toString() === node) {
            connectedNodes.push(nodeId)
          }
        }
      })
      // all first degree nodes get their own color and their label back
      for (let i = 0; i < connectedNodes.length; i++) {
        allNodes[connectedNodes[i]].color = this.getColor(allNodes[connectedNodes[i]].chapter);

      }
      // the main node gets its own color and its label back.
      allNodes[selectedNode].color = this.getColor(allNodes[selectedNode].chapter);
      allNodes[selectedNode].shadow = true
    } else if (this.highlightActive === true) {
      // reset all nodes
      for (var nodeId in allNodes) {
        allNodes[nodeId].color = this.getColor(allNodes[nodeId].chapter);;

      }
      this.hightlightPostReqEdges('');
      this.highlightActive = false;
    }

    // transform the object into an array
    var updateArray = [];
    for (nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        updateArray.push(allNodes[nodeId]);
      }
    }
    this.nodesView.update(updateArray);
  }


  getColor(chapterId) {
    var item = filter(this.chapterColors, ['chapterId', chapterId]);
    if (!isNil(item) && !isEmpty(item)) {
      return item[0]['color'];
    }
    return '#661100';
  }
  openOptionPopup(params) {
    let position = {};
    let isTouchEvent = false
    if (this.state.lastHoveredPoint.nativeEvent instanceof TouchEvent) {
      isTouchEvent = true
    }

    let topPosition = this.state.lastHoveredPoint.y
    let leftPosition = this.state.lastHoveredPoint.x
    if (isTouchEvent) {
      var touch = this.state.lastHoveredPoint.changedTouches[0];
      topPosition = touch.pageY
      leftPosition = touch.pageX - 50
    }
    position = {
      top: topPosition,
      left: leftPosition
    }
    this.setState({
      showContentOptions: true,
      isContentOptionsLoading: false,
      contentOptionsPosition: position,
    });
  }

  addConnections = (elem, index) => {
    // need to replace this with a tree of the network, then get child direct children of the element
    elem.connections = this.network.getConnectedNodes(index);
  }
  exportNetwork = () => {
    var nodes = this.objectToArray(this.network.getPositions());
    //nodes.forEach(this.addConnections);
    // pretty print node data
    var exportValue = JSON.stringify(nodes, undefined, 2);
    console.log('sunil', exportValue)
  }
  objectToArray = (obj) => {
    return Object.keys(obj).map(function (key) {
      obj[key].id = key;
      return obj[key];
    });
  }

  render() {
    return <div id="graphContainer" className="graphContainer">
      <div id={this.props.componentId} className="chartContainer"
        style={this.chartType === 'basic' ? { background: '#fff', border: '1px solid #cccccc' } : { background: '#BFD4FF' }}>

      </div>
      {
        !this.state.isChartRendered ? <div className="graph-progress-bar-container">
          <Spinner animation="border" role="status" />
        </div> : null
      }

      <div id="nodeContainer" className="nodeContainer">
        {this.createNodes(this.state.rawNodes)}
      </div>
      {/* <button style={{position:'absolute'}} onClick={()=>this.exportNetwork()}>Export Position</button> */}
    </div>

  }
}

export default GraphComponent;