import React from "react";
import { Row, Button, Col, Image, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { addchallenges, getchallengebyId, updateChallenge, imgURL, getProblemstatement, getpartnumberByGroupId, savekbdata } from '../../../common/API';
import { Link, withRouter } from 'react-router-dom';
import { getCurrentUserId, Challenge_status, getCurrentUserRole, User_Role } from '../../../common/Functions';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import { ckConfig } from "../../../utils/CkEditorConfig";
import { isNil } from "lodash";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import ChallengeComments from './ChallengeComments';
import Select from 'react-select';
import parse from 'html-react-parser';
import BrowseImageIcon from '../../../assets/images/icon-browse-image.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const dummy_imageurl = imgURL + "/media/challenge_dummy.png"
let kbextracteddata = ""
const getUrlExtension = (url) => {
    return url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim();
}
const diff = (diffMe, diffBy) => diffMe.split(diffBy).join('')



const editorConfiguration = {
    toolbar: {
        items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
            '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
            '-', // break point
            '|', 'alignment',
            'link', 'uploadImage', 'blockQuote', 'codeBlock',
            '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
            '|', 'MathType'
        ],
        shouldNotGroupWhenFull: true
    }
};

class AddChallenge extends React.Component {
    state = {

        apseditorData: "",
        apseditor: null,

        ps: "",
        pseditorData: "",
        pseditor: null,
        editorData: "",
        editor: null,
        validated: false,
        subsection: null,
        subsectionId: null,
        challengename: "",
        question_image: null,
        feedback_image: null,
        displayname: "",
        answertype: "",
        complexitylevel: "",
        source: "",
        inlinesuitable: "",
        accessmode: "",
        displaymode: "",
        requiredknowledge: "",
        interest: "",
        aspiration: "",
        expectedtime: "",
        avgcompletiontime: "",
        trythispin: false,
        qatestflag: false,
        subsectiondata: [],
        thumbnail_image: null,
        mode: "Add",
        challenge_status: null,
        Challenge_ID: null,
        modalShow: false,
        previewimageurl: null,
        isthumbnail: false,
        subsectionheader: null,
        userrole: getCurrentUserRole(),
        modelheader: null,
        showcommentsfields: false,
        reviewcomments: "",
        showcommentmodal: false,
        fieldname: null,
        challenge_name_comment: "",
        question_comment: "",
        display_name_comment: "",
        question_image_comment: "",
        feedback_image_comment: "",
        answer_type_comment: "",
        complexity_level_comment: "",
        source_comment: "",
        thumbnail_comment: "",
        inline_suitable_comment: "",
        reviewer_user_id: null,
        expert_user_id: null,
        current_loggedin_user_id: getCurrentUserId(),
        current_loggedin_user_role: getCurrentUserRole(),
        challenge_name_comment_color_change: false,
        question_comment_color_change: false,
        display_name_comment_color_change: false,
        question_image_comment_color_change: false,
        feedback_image_comment_color_change: false,
        answer_type_comment_color_change: false,
        complexity_level_comment_color_change: false,
        source_comment_color_change: false,
        thumbnail_comment_color_change: false,
        inline_suitable_comment_color_change: false,
        button_chellenge_status: null,


        challenge_name_comment_isread: false,
        question_comment_isread: false,
        display_name_comment_isread: false,
        question_image_comment_isread: false,
        feedback_image_comment_isread: false,
        answer_type_comment_isread: false,
        complexity_level_comment_isread: false,
        source_comment_isread: false,
        thumbnail_comment_isread: false,
        inline_suitable_comment_isread: false,



        previous_challenge_name_comment: "",
        previous_question_comment: "",
        previous_display_name_comment: "",
        previous_question_image_comment: "",
        previous_feedback_image_comment: "",
        previous_answer_type_comment: "",
        previous_complexity_level_comment: "",
        previous_source_comment: "",
        previous_thumbnail_comment: "",
        previous_inline_suitable_comment: "",

        // additionalinstructionseditor: null,
        // additionalinstructionseditordata: "",
        // editorContent: "",
        // pseditorContent: "",
        // additionalinstructionseditorContent: "",



        problemstatementdata: [],
        showproblemstatementtextbox: false,
        problem_statement: "",
        problem_statement_value: 0,
        ismultipart: 0,
        group_id: 0,
        showmultipartsection: false,
        multipartcheckboxvalue: false,
        isreadonlyforexpert: false,
        transaction_id: null,
        part_number: 0,

        part1: false,
        part2: false,
        part3: false,
        part4: false,
        part5: false,
        part6: false,
        part7: false,
        part8: false,
        part9: false,
        part10: false,
        partnumberlist: [],
        kbdata: "",
        loader: false,
        opendialogkbdata: false

    }
    partnumbercontrol = null
    bindnumbercontrol(groupid, ismultipart) {

        if (groupid > 0 && ismultipart) {
            getpartnumberByGroupId(groupid).then(res => {

                if (res.status === 200) {
                    if (res.data.partnumbers !== null) {
                        if (res.data.partnumbers.length > 0) {
                            this.setState({ partnumberlist: res.data.partnumbers })
                        }
                        else {
                            this.setState({ partnumberlist: [] })
                        }
                    }
                    else {
                        this.setState({ partnumberlist: [] })
                    }
                }
            })
        }
        else {
            this.setState({ partnumberlist: [] })
        }
    }
    componentDidMount() {
        if (this.state.userrole === User_Role.ContentExpert) {
            this.setState({ isreadonlyforexpert: true })
        }
        getProblemstatement().then(res => {
            if (res.data.problemstatements.length > 0) {

                this.setState({ problemstatementdata: res.data.problemstatements, showproblemstatementtextbox: false })
            }
            else {
                this.setState({ showproblemstatementtextbox: true })
            }
        })
        
        let challengeId = this.props.match.params.challengeId
        if (challengeId !== undefined && challengeId !== null) {
            challengeId = challengeId.substring(1)
            getchallengebyId(challengeId).then(res => {
                if (res.status === 200) {
                    let pstitle = "";
                    if (res.data.challenge.problem_statement !== "" && res.data.challenge.problem_statement !== '' && res.data.challenge.problem_statement !== null && res.data.challenge.problem_statement !== undefined) {
                        let str = res.data.challenge.problem_statement.replace(/(<([^>]+)>)/ig, '');
                        pstitle = parse(str)
                    }
                    //debugger;
                    this.bindnumbercontrol(res.data.challenge.multipartgroupid, res.data.challenge.ismultipart)
                    this.setState({
                        mode: "Edit",
                        Challenge_ID: challengeId,
                        subsectionId: res.data.challenge.subsection_id,
                        challengename: res.data.challenge.challenge_name,
                        displayname: res.data.challenge.display_name === "null" ? "" : res.data.challenge.display_name,
                        answertype: res.data.challenge.answer_type,
                        complexitylevel: res.data.challenge.complexity_level,
                        source: res.data.challenge.source === "null" ? "" : res.data.challenge.source !== "undefined" ? res.data.challenge.source : "",
                        inlinesuitable: res.data.challenge.inline_suitable,
                        challenge_status: res.data.challenge.challenge_state,
                        button_chellenge_status: res.data.challenge.challenge_state,
                        subsectionheader: res.data.challenge.subsec,
                        thumbnail_image: imgURL + res.data.challenge.thumbnail,
                        question_image: imgURL + res.data.challenge.question_image,
                        feedback_image: imgURL + res.data.challenge.feedback_image,
                        challenge_name_comment: res.data.challenge.challenge_name_comment === null ? "" : res.data.challenge.challenge_name_comment,
                        question_comment: res.data.challenge.question_comment === null ? "" : res.data.challenge.question_comment,
                        display_name_comment: res.data.challenge.display_name_comment === null ? "" : res.data.challenge.display_name_comment,
                        question_image_comment: res.data.challenge.question_image_comment === null ? "" : res.data.challenge.question_image_comment,
                        feedback_image_comment: res.data.challenge.feedback_image_comment === null ? "" : res.data.challenge.feedback_image_comment,
                        answer_type_comment: res.data.challenge.answer_type_comment === null ? "" : res.data.challenge.answer_type_comment,
                        complexity_level_comment: res.data.challenge.complexity_level_comment === null ? "" : res.data.challenge.complexity_level_comment,
                        source_comment: res.data.challenge.source_comment === null ? "" : res.data.challenge.source_comment,
                        thumbnail_comment: res.data.challenge.thumbnail_comment === null ? "" : res.data.challenge.thumbnail_comment,
                        inline_suitable_comment: res.data.challenge.inline_suitable_comment === null ? "" : res.data.challenge.inline_suitable_comment,

                        previous_challenge_name_comment: res.data.challenge.challenge_name_comment === null ? "" : res.data.challenge.challenge_name_comment,
                        previous_question_comment: res.data.challenge.question_comment === null ? "" : res.data.challenge.question_comment,
                        previous_display_name_comment: res.data.challenge.display_name_comment === null ? "" : res.data.challenge.display_name_comment,
                        previous_question_image_comment: res.data.challenge.question_image_comment === null ? "" : res.data.challenge.question_image_comment,
                        previous_feedback_image_comment: res.data.challenge.feedback_image_comment === null ? "" : res.data.challenge.feedback_image_comment,
                        previous_answer_type_comment: res.data.challenge.answer_type_comment === null ? "" : res.data.challenge.answer_type_comment,
                        previous_complexity_level_comment: res.data.challenge.complexity_level_comment === null ? "" : res.data.challenge.complexity_level_comment,
                        previous_source_comment: res.data.challenge.source_comment === null ? "" : res.data.challenge.source_comment,
                        previous_thumbnail_comment: res.data.challenge.thumbnail_comment === null ? "" : res.data.challenge.thumbnail_comment,
                        previous_inline_suitable_comment: res.data.challenge.inline_suitable_comment === null ? "" : res.data.challenge.inline_suitable_comment,

                        challenge_name_comment_color_change: res.data.challenge.challenge_name_comment_color_change,
                        question_comment_color_change: res.data.challenge.question_comment_color_change,
                        display_name_comment_color_change: res.data.challenge.display_name_comment_color_change,
                        question_image_comment_color_change: res.data.challenge.question_image_comment_color_change,
                        feedback_image_comment_color_change: res.data.challenge.feedback_image_comment_color_change,
                        answer_type_comment_color_change: res.data.challenge.answer_type_comment_color_change,
                        complexity_level_comment_color_change: res.data.challenge.complexity_level_comment_color_change,
                        source_comment_color_change: res.data.challenge.source_comment_color_change,
                        thumbnail_comment_color_change: res.data.challenge.thumbnail_comment_color_change,
                        inline_suitable_comment_color_change: res.data.challenge.inline_suitable_comment_color_change,
                        reviewer_user_id: res.data.challenge.reviewer_user_id,
                        expert_user_id: res.data.challenge.expert_user_id,

                        challenge_name_comment_isread: res.data.challenge.challenge_name_comment_isread,
                        question_comment_isread: res.data.challenge.question_comment_isread,
                        display_name_comment_isread: res.data.challenge.display_name_comment_isread,
                        question_image_comment_isread: res.data.challenge.question_image_comment_isread,
                        feedback_image_comment_isread: res.data.challenge.feedback_image_comment_isread,
                        answer_type_comment_isread: res.data.challenge.answer_type_comment_isread,
                        complexity_level_comment_isread: res.data.challenge.complexity_level_comment_isread,
                        source_comment_isread: res.data.challenge.source_comment_isread,
                        thumbnail_comment_isread: res.data.challenge.thumbnail_comment_isread,
                        inline_suitable_comment_isread: res.data.challenge.inline_suitable_comment_isread,

                        problem_statement: res.data.challenge.problem_statement,
                        part_number: res.data.challenge.part_number,
                        ps: res.data.challenge.problem_statement,
                        group_id: res.data.challenge.multipartgroupid,
                        ismultipart: res.data.challenge.ismultipart,
                        multipartcheckboxvalue: res.data.challenge.ismultipart === 1 ? true : false,
                        editorData: res.data.challenge.question,
                        apseditorData: res.data.challenge.additional_instructions,
                        pseditorData: pstitle,
                        transaction_id: res.data.challenge.transaction_id

                    }, () => {

                        setTimeout(() => {
                            if (!isNil(this.state.apseditor)) {
                                this.state.apseditor.setData(this.state.apseditorData)
                            }
                            if (!isNil(this.state.editor)) {
                                this.state.editor.setData(this.state.editorData)
                            }
                            if (!isNil(this.state.pseditor)) {
                                this.state.pseditor.setData(this.state.pseditorData)
                            }
                        }, 500);


                        if (this.state.reviewer_user_id !== null) {
                            this.setState({ showcommentsfields: true })
                        }
                        if (this.state.multipartcheckboxvalue === true) {
                            this.setState({ showmultipartsection: true })
                        }
                    });

                }
            })
        }
        else {
            this.setState({ challenge_status: Challenge_status.underdevelopment, answertype: "FRQ", inlinesuitable: "True" })
            const { state } = this.props.location
            if (state.ismultipartdata) {

                this.bindnumbercontrol(state.groupid, true)
                this.setState({
                    group_id: parseInt(state.groupid, 10),
                    showmultipartsection: true,
                    multipartcheckboxvalue: state.ismultipartdata
                    , ismultipart: 1,
                    problem_statement: state.problemstatement,
                    ps: state.problemstatement,
                    pseditorData: state.problemstatement,
                    pseditorContent: state.problemstatement
                })
            }
            this.setState({
                subsectionId: state.id,
                subsectionheader: "Section: " + state.section_name + " | Chapter: " + state.chapter_name
            })
        }
    }
    handlereviewercomment(e) {
        switch (this.state.fieldname) {
            case "challenge_name_comment":
                this.setState({ challenge_name_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "question_comment":
                this.setState({ question_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "display_name_comment":
                this.setState({ display_name_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "question_image_comment":
                this.setState({ question_image_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "feedback_image_comment":
                this.setState({ feedback_image_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "answer_type_comment":
                this.setState({ answer_type_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "complexity_level_comment":
                this.setState({ complexity_level_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "source_comment":
                this.setState({ source_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "thumbnail_comment":
                this.setState({ thumbnail_comment: e.target.value, reviewcomments: e.target.value });
                break;
            case "inline_suitable_comment":
                this.setState({ inline_suitable_comment: e.target.value, reviewcomments: e.target.value });
                break;
        }

    }

    handlecomments = (event, commentfieldname, headername) => {
        this.setState({
            showcommentmodal: true, modelheader: headername, fieldname: commentfieldname,
        }, () => {
            switch (commentfieldname) {
                case "challenge_name_comment":
                    let isread = false;
                    if (this.state.previous_challenge_name_comment === this.state.challenge_name_comment) {
                        isread = true
                    }
                    this.setState({ reviewcomments: this.state.challenge_name_comment, challenge_name_comment_color_change: false, challenge_name_comment_isread: isread });
                    break;
                case "question_comment":
                    let isread1 = false;
                    if (this.state.previous_question_comment === this.state.question_comment) {
                        isread1 = true
                    }
                    this.setState({ reviewcomments: this.state.question_comment, question_comment_color_change: false, question_comment_isread: isread1 });
                    break;
                case "display_name_comment":
                    let isread2 = false;
                    if (this.state.previous_display_name_comment === this.state.display_name_comment) {
                        isread2 = true
                    }
                    this.setState({ reviewcomments: this.state.display_name_comment, display_name_comment_color_change: false, display_name_comment_isread: isread2 });
                    break;
                case "question_image_comment":
                    let isread3 = false;
                    if (this.state.previous_question_image_comment === this.state.question_image_comment) {
                        isread3 = true
                    }
                    this.setState({ reviewcomments: this.state.question_image_comment, question_image_comment_color_change: false, question_image_comment_isread: isread3 });
                    break;
                case "feedback_image_comment":
                    let isread13 = false;
                    if (this.state.previous_feedback_image_comment === this.state.feedback_image_comment) {
                        isread13 = true
                    }
                    this.setState({ reviewcomments: this.state.feedback_image_comment, feedback_image_comment_color_change: false, feedback_image_comment_isread: isread13 });
                    break;
                case "answer_type_comment":
                    let isread4 = false;
                    if (this.state.previous_answer_type_comment === this.state.answer_type_comment) {
                        isread4 = true
                    }
                    this.setState({ reviewcomments: this.state.answer_type_comment, answer_type_comment_color_change: false, answer_type_comment_isread: isread4 });
                    break;
                case "complexity_level_comment":
                    let isread5 = false;
                    if (this.state.previous_complexity_level_comment === this.state.complexity_level_comment) {
                        isread5 = true
                    }
                    this.setState({ reviewcomments: this.state.complexity_level_comment, complexity_level_comment_color_change: false, complexity_level_comment_isread: isread5 });
                    break;
                case "source_comment":
                    let isread6 = false;
                    if (this.state.previous_source_comment === this.state.source_comment) {
                        isread6 = true
                    }
                    this.setState({ reviewcomments: this.state.source_comment, source_comment_color_change: false, source_comment_isread: isread6 });
                    break;
                case "thumbnail_comment":
                    let isread7 = false;
                    if (this.state.previous_thumbnail_comment === this.state.thumbnail_comment) {
                        isread7 = true
                    }
                    this.setState({ reviewcomments: this.state.thumbnail_comment, thumbnail_comment_comment_color_change: false, thumbnail_comment_comment_isread: isread7 });
                    break;
                case "inline_suitable_comment":
                    let isread8 = false;
                    if (this.state.previous_inline_suitable_comment === this.state.inline_suitable_comment) {
                        isread8 = true
                    }
                    this.setState({ reviewcomments: this.state.inline_suitable_comment, inline_suitable_comment_color_change: false, inline_suitable_commentt_isread: isread8 });
                    break;
            }
        });
    }
    handleclosemodal() {
        switch (this.state.fieldname) {
            case "challenge_name_comment":
                let isread = false;
                if (this.state.previous_challenge_name_comment === this.state.challenge_name_comment) {
                    isread = true
                }
                this.setState({ challenge_name_comment_isread: isread });
                break;
            case "question_comment":
                let isread1 = false;
                if (this.state.previous_question_comment === this.state.question_comment) {
                    isread1 = true
                }
                this.setState({ question_comment_isread: isread1 });
                break;
            case "display_name_comment":
                let isread2 = false;
                if (this.state.previous_display_name_comment === this.state.display_name_comment) {
                    isread2 = true
                }
                this.setState({ display_name_comment_isread: isread2 });
                break;
            case "question_image_comment":
                let isread3 = false;
                if (this.state.previous_question_image_comment === this.state.question_image_comment) {
                    isread3 = true
                }
                this.setState({ question_image_comment_isread: isread3 });
                break;
            case "feedback_image_comment":
                let isread31 = false;
                if (this.state.previous_feedback_image_comment === this.state.feedback_image_comment) {
                    isread31 = true
                }
                this.setState({ feedback_image_comment_isread: isread31 });
                break;
            case "answer_type_comment":
                let isread4 = false;
                if (this.state.previous_answer_type_comment === this.state.answer_type_comment) {
                    isread4 = true
                }
                this.setState({ answer_type_comment_isread: isread4 });
                break;
            case "complexity_level_comment":
                let isread5 = false;
                if (this.state.previous_complexity_level_comment === this.state.complexity_level_comment) {
                    isread5 = true
                }
                this.setState({ complexity_level_comment_isread: isread5 });
                break;
            case "source_comment":
                let isread6 = false;
                if (this.state.previous_source_comment === this.state.source_comment) {
                    isread6 = true
                }
                this.setState({ source_comment_isread: isread6 });
                break;
            case "thumbnail_comment":
                let isread7 = false;
                if (this.state.previous_thumbnail_comment === this.state.thumbnail_comment) {
                    isread7 = true
                }
                this.setState({ thumbnail_comment_comment_isread: isread7 });
                break;
            case "inline_suitable_comment":
                let isread8 = false;
                if (this.state.previous_inline_suitable_comment === this.state.inline_suitable_comment) {
                    isread8 = true
                }
                this.setState({ inline_suitable_commentt_isread: isread8 });
                break;
        }

        this.setState({ showcommentmodal: false })
    }

    handletrythispin(e) {
        this.setState({
            trythispin: e.target.checked
        });
    }
    handleqatestflag(e) {
        this.setState({
            qatestflag: e.target.checked
        });
    }
    handleavgcompletiontime(e) {
        this.setState({
            avgcompletiontime: e.target.value
        });
    }
    handleexpectedtime(e) {
        this.setState({
            expectedtime: e.target.value
        });
    }
    handleinterest(e) {
        this.setState({
            interest: e.target.value
        });
    }
    handleaspiration(e) {
        this.setState({
            aspiration: e.target.value
        });
    }
    handledisplaymode(e) {
        this.setState({
            displaymode: e.target.value
        });
    }
    handlerequiredknowledge(e) {
        this.setState({
            requiredknowledge: e.target.value
        });
    }
    handleaccessmode(e) {
        this.setState({
            accessmode: e.target.value
        });
    }

    handlesource(e) {
        this.setState({
            source: e.target.value
        });
    }

    handleinlinesuitable(e) {
        this.setState({
            inlinesuitable: e.target.value
        });
    }

    handlechallengestatus(e) {
        this.setState({
            challenge_status: e.target.value
        });
    }

    handlechallengename(e) {
        this.setState({
            challengename: e.target.value
        });
    }
    handleanswertype(e) {
        this.setState({
            answertype: e.target.value
        });
    }

    handledisplayname(e) {
        this.setState({
            displayname: e.target.value
        });
    }
    handlecomplexitylevel(e) {
        this.setState({
            complexitylevel: e.target.value
        });
    }

    handlepartnumber(e) {
        this.setState({
            part_number: e.target.value
        });
    }


    handleproblemstatement = (e) => {
        //debugger;
        if (e.value === 0) {
            this.setState({ showproblemstatementtextbox: true, group_id: e.value })
        }
        else {
            this.setState({
                problem_statement: e.label, group_id: e.value, showproblemstatementtextbox: false
            });
        }
        this.bindnumbercontrol(e.value, this.state.ismultipart)
    }
    handlemultipart = (e) => {
        this.setState({ multipartcheckboxvalue: e.target.checked })
        if (e.target.checked === true) {
            this.setState({ showmultipartsection: true, ismultipart: 1 })
            if (this.state.group_id === 0) {
                this.setState({ showproblemstatementtextbox: true })
            }
        }
        else {
            this.setState({ showmultipartsection: false, ismultipart: 0 })
        }
    };

    handleproblemstatementtext = (e) => {
        this.setState({
            problem_statement: e.target.value
        });
    }

    handleQuestionImageChange = (e) => {
        this.setState({
            question_image: e.target.files[0]
        })
    };

    handleFeedbackImageChange = (e) => {
        this.setState({
            feedback_image: e.target.files[0]
        })
    };

    handleThumbnailImageChange = (e) => {
        this.setState({
            thumbnail_image: e.target.files[0]
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let form_data = new FormData();
            form_data.append('subsection_id', this.state.subsectionId);
            form_data.append('challenge_name', this.state.challengename);
            form_data.append('transaction_id', this.state.transaction_id);

            // let data = this.state.questionvalue.toString("html")
            // if (data.includes("<p>&nbsp;</p>")) {
            //     data = data.replace("<p>&nbsp;</p>", "")
            // }
            form_data.append('question', this.state.editorContent);

            if (this.state.apseditorContent !== "" && this.state.apseditorContent !== undefined && this.state.apseditorContent !== "undefined")
                form_data.append('additional_instructions', this.state.apseditorContent);

            // form_data.append('additional_instructions', this.state.additionalinstructionseditorContent);
            form_data.append('display_name', this.state.displayname);
            form_data.append('answer_type', this.state.answertype);
            form_data.append('complexity_level', this.state.complexitylevel);
            form_data.append('source', this.state.source);
            form_data.append('inline_suitable', this.state.inlinesuitable);
            form_data.append('user_id', this.state.current_loggedin_user_id);
            form_data.append('user_role', this.state.current_loggedin_user_role);
            if (this.state.mode === "Add") {

                if (this.state.question_image !== null) {
                    form_data.append('question_image', this.state.question_image);
                }
                else {

                    var imgExt = getUrlExtension(dummy_imageurl);
                    const response = await fetch(dummy_imageurl);
                    const blob = await response.blob();
                    const file = new File([blob], "dummy." + imgExt, {
                        type: "image/png",
                    });
                    form_data.append('question_image', file);
                }

                if (this.state.feedback_image !== null) {
                    form_data.append('feedback_image', this.state.feedback_image);
                }
                else {

                    var imgExt = getUrlExtension(dummy_imageurl);
                    const response = await fetch(dummy_imageurl);
                    const blob = await response.blob();
                    const file = new File([blob], "dummy." + imgExt, {
                        type: "image/png",
                    });
                    form_data.append('feedback_image', file);
                }

                if (this.state.thumbnail_image !== null) {
                    form_data.append('thumbnail', this.state.thumbnail_image);
                }
                else {

                    var imgExt1 = getUrlExtension(dummy_imageurl);
                    const response = await fetch(dummy_imageurl);
                    const blob = await response.blob();
                    const file = new File([blob], "dummy." + imgExt1, {
                        type: "image/png",
                    });
                    form_data.append('thumbnail', file);
                }

            }
            else {
                if (this.state.question_image.name !== undefined) {
                    form_data.append('question_image', this.state.question_image);
                }

                if (this.state.feedback_image.name !== undefined) {
                    form_data.append('feedback_image', this.state.feedback_image);
                }

                if (this.state.thumbnail_image.name !== undefined) {
                    form_data.append('thumbnail', this.state.thumbnail_image);
                }
            }
            form_data.append('challenge_state', this.state.challenge_status);
            form_data.append('Challenge_ID', this.state.Challenge_ID);
            form_data.append('challenge_name_comment', this.state.challenge_name_comment);
            form_data.append('question_comment', this.state.question_comment);
            form_data.append('display_name_comment', this.state.display_name_comment);
            form_data.append('question_image_comment', this.state.question_image_comment);
            form_data.append('feedback_image_comment', this.state.feedback_image_comment);
            form_data.append('answer_type_comment', this.state.answer_type_comment);
            form_data.append('complexity_level_comment', this.state.complexity_level_comment);
            form_data.append('source_comment', this.state.source_comment);
            form_data.append('thumbnail_comment', this.state.thumbnail_comment);
            form_data.append('inline_suitable_comment', this.state.inline_suitable_comment);

            form_data.append('challenge_name_comment_isread', this.state.challenge_name_comment_isread);
            form_data.append('question_comment_isread', this.state.question_comment_isread);
            form_data.append('display_name_comment_isread', this.state.display_name_comment_isread);
            form_data.append('question_image_comment_isread', this.state.question_image_comment_isread);
            form_data.append('feedback_image_comment_isread', this.state.feedback_image_comment_isread);
            form_data.append('answer_type_comment_isread', this.state.answer_type_comment_isread);
            form_data.append('complexity_level_comment_isread', this.state.complexity_level_comment_isread);
            form_data.append('source_comment_isread', this.state.source_comment_isread);
            form_data.append('thumbnail_comment_isread', this.state.thumbnail_comment_isread);
            form_data.append('inline_suitable_comment_isread', this.state.inline_suitable_comment_isread);

            form_data.append('ismultipart', this.state.ismultipart);

            if (this.state.pseditorContent !== "" && this.state.pseditorContent !== "undefined" && this.state.pseditorContent !== undefined)
                form_data.append('problem_statement', this.state.pseditorContent);
            else
                form_data.append('problem_statement', this.state.problem_statement);
            form_data.append('group_id', this.state.group_id);

            if (this.state.part_number === null) {
                form_data.append('part_number', 0);
            }
            else {
                form_data.append('part_number', this.state.part_number);
            }

            if (this.state.Challenge_ID !== null) {
                await updateChallenge(form_data).then(res => {
                    if (res.status === 200) {
                        toast.success("Challenge Updated!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.props.history.push('/curriculum-problems');
                    }
                }).catch(err => {
                    toast.error("Error!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    console.error(err.message)
                })
            }
            else {
                await addchallenges(form_data).then(res => {
                    if (res.status === 200) {
                        toast.success("Challenge Saved!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.props.history.push('/curriculum-problems');
                    }
                }).catch(err => {
                    toast.error("Error!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    console.error(err.message)
                })
            }
        }
        this.setState({
            validated: true
        });

    }

    onFileChange = (e) => {
        // debugger;
        var fileInput = document.getElementById('file-uploadKB');
        var file = fileInput.files[0];
        var fileType = file.name.split('.').pop().toLowerCase();
        // Check file type
        if (fileType === 'docx') {
            this.readDocx(file);
        } else if (fileType === 'pdf') {
            this.readPdf(file);
        } else {
            alert('Unsupported file format. Please upload a .docx or .pdf file.');
        }
        this.setState({ loader: true })
        setTimeout(() => {
            this.savekbDataHandler();
            fileInput.value = '';
        }, 2000)
    }
    readDocx(file) {
        var reader = new FileReader();
        reader.onload = function (event) {
            var arrayBuffer = reader.result;
            window.mammoth.extractRawText({ arrayBuffer: arrayBuffer })
                .then(function (result) {
                    // debugger;                   
                    // document.getElementById('extractedKb').textContent = result.value;
                    kbextracteddata = result.value

                })
                .catch(function (err) {
                    console.error("Error reading .docx file:", err);
                });
        };
        reader.readAsArrayBuffer(file);


    }

    readPdf(file) {
        var reader = new FileReader();
        reader.onload = function (event) {
            var typedArray = new Uint8Array(reader.result);

            window.pdfjsLib.getDocument(typedArray).promise.then(function (pdf) {
                var pagesPromises = [];

                // Loop through all pages
                for (var i = 1; i <= pdf.numPages; i++) {
                    pagesPromises.push(
                        pdf.getPage(i).then(function (page) {
                            return page.getTextContent().then(function (textContent) {
                                return textContent.items.map(function (item) {
                                    return item.str;
                                }).join(' ');
                            });
                        })
                    );
                }

                // When all pages are processed
                Promise.all(pagesPromises).then(function (pagesText) {
                    // Join pages' text and display it
                    // document.getElementById('extractedKb').textContent = pagesText.join('\n\n');
                    // this.savekbDataHandler(pagesText.join('\n\n'))
                    kbextracteddata = pagesText.join('\n\n');
                });
            }).catch(function (error) {
                console.error("Error reading PDF file:", error);
            });
        };
        reader.readAsArrayBuffer(file);

    }
    savekbDataHandler = () => {
        // debugger;
        this.setState({ loader: true })
        let form_data = new FormData();
        form_data.append('challenge_id', this.state.Challenge_ID);
        form_data.append('knoledgebase', kbextracteddata);
        savekbdata(form_data).then(res => {
            if (res.data !== undefined && res.data !== null) {
                this.setState({ kbdata: res.data.knoledgebase }, () => {
                    toast.success("KB Updated for this challenge!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.setState({ loader: false, opendialogkbdata: true })
                })
            }
            else {
                this.setState({ loader: false })
            }


        }).catch(err => {
            console.error(err.message)
            this.setState({ loader: false })
        })
    }
    closeopendialogkbdatahandler = () => {
        this.setState({ opendialogkbdata: false })
    }

    render() {
        const { editorState } = this.state;
        let challengestatus = this.state.button_chellenge_status
        let button = null;
        let partnumbercontrolsdata = []
        if (this.state.partnumberlist.length > 0) {

            for (let i = 1; i <= 10; i++) {
                let detail = ""
                if (this.state.mode === "Add") {
                    if (this.state.partnumberlist.includes(i)) {
                        detail = <option style={{ color: "#007bff" }} value={i} disabled>{i}</option>
                    }
                    else {
                        detail = <option value={i}>{i}</option>
                    }
                }
                else {
                    if (this.state.partnumberlist.includes(i) && i !== this.state.part_number) {
                        detail = <option style={{ color: "#007bff" }} value={i} disabled>{i}</option>
                    }
                    else {
                        detail = <option value={i}>{i}</option>
                    }
                }
                partnumbercontrolsdata.push(
                    <React.Fragment key={i}>{detail}</React.Fragment>
                )
            }
        }
        else {
            for (let i = 1; i <= 10; i++) {
                let detail = ""
                detail = <option value={i}>{i}</option>
                partnumbercontrolsdata.push(
                    <React.Fragment key={i}>{detail}</React.Fragment>
                )
            }
        }
        switch (this.state.userrole) {
            case User_Role.ContentAuthor:
                if (challengestatus === undefined || challengestatus === null || challengestatus === Challenge_status.underdevelopment || challengestatus === Challenge_status.reviewed) {
                    button = <>
                        <Button type="submit" variant="primary" style={{ marginBottom: "20px", marginTop: "20px" }}>Save</Button>
                    </>
                }
                else if (challengestatus === Challenge_status.reviewinprogress || challengestatus === Challenge_status.betareleased || challengestatus === Challenge_status.released || challengestatus === Challenge_status.underreview) {
                    button = <Button variant="secondary" style={{ marginBottom: "20px", marginLeft: "10px", marginTop: "20px" }} disabled={true}>Save</Button>
                }
                break;
            case User_Role.ContentReviewer:
                if (challengestatus === Challenge_status.underdevelopment || challengestatus === Challenge_status.released || challengestatus === Challenge_status.betareleased || challengestatus === Challenge_status.reviewed) {
                    button = <>
                        <Button variant="secondary" style={{ marginBottom: "20px", marginLeft: "10px", marginTop: "20px" }} disabled={true}>Save</Button>
                    </>
                }
                else if (challengestatus === Challenge_status.underreview || challengestatus === Challenge_status.reviewinprogress) {
                    button = <Button type="submit" variant="primary" style={{ marginBottom: "20px", marginTop: "20px" }}>Save</Button>
                }
                break;
            case User_Role.ContentExpert:
                button = <Button type="submit" variant="primary" style={{ marginBottom: "20px", marginTop: "20px" }}>Save</Button>
                break;
        }
        return (

            <>

                <style>
                    {`
    #cke_1_top {
         max-height: 200px !important;
    }`
                    }

                </style>
                <Dialog
                    hideBackdrop={false}
                    open={this.state.opendialogkbdata}
                    onClose={this.closeopendialogkbdatahandler}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ marginTop: "10px" }}>

                                <span dangerouslySetInnerHTML={{ __html: this.state.kbdata }} />
                            </div>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="dragLabelcancel leftmargin" onClick={this.closeopendialogkbdatahandler}>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>

                <Modal
                    show={this.state.showcommentmodal} onHide={this.handleclosemodal.bind(this)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.state.modelheader}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            class="form-control bg-yellow"
                            rows="10"
                            value={this.state.reviewcomments}
                            disabled={this.state.isreadonlyforexpert}
                            onChange={this.handlereviewercomment.bind(this)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleclosemodal.bind(this)} disabled={this.state.isreadonlyforexpert}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <div style={{ paddingTop: "1rem !important" }}>
                    <Row style={{ alignItems: "center" }}>
                        <Col sm="4"><h2> Add Challenge </h2></Col>
                        <Col sm="8">
                            <span style={{ fontSize: "17px", textDecoration: "none", fontWeight: "600", color: "#28a745" }}>{challengestatus}</span>
                        </Col>
                    </Row>
                </div>
                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                    <Form.Group as={Row} className="mb-2" controlId="formsubsection" style={{ alignItems: "center" }}>
                        <Form.Label column="sm" lg={3}>
                            Sub Section
                        </Form.Label>
                        <Col sm="9">
                            <Form.Label >
                                {this.state.subsectionheader}
                            </Form.Label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formsubsection" style={{ alignItems: "center" }}>
                        <Form.Label column="sm" lg={3}>
                            Is Multipart?
                        </Form.Label>
                        <Col sm="9">
                            <Form.Check
                                inline
                                name="multipart"
                                type="checkbox"
                                id="multipartchallenge"
                                checked={this.state.multipartcheckboxvalue}
                                onChange={this.handlemultipart.bind(this)}
                                disabled={this.state.isreadonlyforexpert}
                            />
                        </Col>
                    </Form.Group>
                    {
                        this.state.showmultipartsection ? (
                            <>
                                <Form.Group as={Row} className="mb-2" controlId="formsubsection" style={{ alignItems: "center" }}>
                                    <Form.Label column="sm" lg={3}>
                                        Select Problem
                                    </Form.Label>
                                    <Col sm="9">
                                        <Select
                                            name="problemstatement"
                                            options={this.state.problemstatementdata}
                                            className="basic-multi-select"
                                            //value={this.state.group_id}
                                            formatOptionLabel={function (data) {
                                                return (
                                                    <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                );
                                            }}
                                            value={this.state.problemstatementdata.find(option => option.value === this.state.group_id)}
                                            classNamePrefix="select"
                                            onChange={this.handleproblemstatement.bind(this)}
                                            disabled={this.state.isreadonlyforexpert}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-2" controlId="formanswertype">
                                    <Form.Label column="sm" lg={3} >Part Number</Form.Label>
                                    <Col sm="9"><Form.Control size="sm" style={{ width: "140px" }}
                                        as="select"
                                        value={this.state.part_number}
                                        onChange={this.handlepartnumber.bind(this)}
                                    >
                                        <option value="" >Select</option>
                                        {partnumbercontrolsdata}
                                        {/* <option value="1" disabled={this.state.part1}>1</option>
                                        <option value="2" disabled={this.state.part2}>2</option>
                                        <option value="3" disabled={this.state.part3}>3</option>
                                        <option value="4" disabled={this.state.part4}>4</option>
                                        <option value="5" disabled={this.state.part5}>5</option>
                                        <option value="6" disabled={this.state.part6}>6</option>
                                        <option value="7" disabled={this.state.part7}>7</option>
                                        <option value="8" disabled={this.state.part8}>8</option>
                                        <option value="9" disabled={this.state.part8}>9</option>
                                        <option value="10" disabled={this.state.part10}>10</option> */}
                                    </Form.Control>

                                    </Col>
                                </Form.Group>
                                {
                                    this.state.showproblemstatementtextbox ? (<Form.Group as={Row} className="mb-2" controlId="formdisplayname">
                                        <Form.Label column="sm" lg={3}>
                                            Problem Statement
                                        </Form.Label>
                                        <Col sm="9">
                                            {/* <Form.Control placeholder="Problem Statement" size="sm" as="textarea" rows={6}
                                                style={{ width: "410px" }}                                                
                                                value={this.state.problem_statement}
                                                onChange={this.handleproblemstatementtext.bind(this)} /> */}
                                            <div style={{ width: '600px' }}>
                                                <CKEditor
                                                    style={{ width: "100%" }}
                                                    data={this.state.pseditorData}
                                                    editor={ClassicEditor}
                                                    onReady={(e) => {
                                                        this.setState({ pseditor: e.editor });
                                                        //this.state.pseditor.setData(this.state.pseditorData.trim())
                                                    }}
                                                    config={editorConfiguration}
                                                    onChange={(e, editor) => { this.setState({ pseditorContent: editor.getData() }) }}

                                                /></div>
                                        </Col>
                                    </Form.Group>
                                    ) :
                                        null
                                }

                            </>
                        ) : null
                    }

                    <Form.Group as={Row} className="mb-2" controlId="formchallengename" style={{ alignItems: "center" }}>
                        <Form.Label column="sm" lg={3}>
                            Challenge Name
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control placeholder="Challenge Name" size="sm" type="text"
                                style={{ width: "410px" }}
                                required
                                value={this.state.challengename}
                                onChange={this.handlechallengename.bind(this)}
                                disabled={this.state.isreadonlyforexpert}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter challenge name.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm="3">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.challenge_name_comment}
                                            iscolorchange={this.state.challenge_name_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "challenge_name_comment", "Review Comment")}> </ChallengeComments>
                                    ) : null
                            }

                        </Col>

                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formquestion">
                        <Form.Label column="sm" lg={3}>
                            Question
                        </Form.Label>
                        <Col sm="8">
                            <div style={{ width: '550px' }}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    style={{ width: "100%" }}
                                    data={this.state.editorData}
                                    onReady={(e) => {
                                        this.setState({ editor: e.editor });
                                        //this.state.editor.setData(this.state.editorData.trim())
                                    }}
                                    config={editorConfiguration}
                                    onChange={(e, editor) => { this.setState({ editorContent: editor.getData() }) }}
                                //disabled={this.state.isreadonlyforexpert}
                                />
                            </div>
                        </Col>
                        <Col sm="1">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.question_comment}
                                            iscolorchange={this.state.question_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "question_comment", "Review Comment")}> </ChallengeComments>

                                    ) : null
                            }
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="formadditionalinstructions">
                        <Form.Label column="sm" lg={3}>
                            Additional Instructions
                        </Form.Label>
                        <Col sm="8">
                            <div style={{ width: '550px' }}>
                                {/* <CKEditor
                                    style={{ width: "100%" }}
                                    initData={this.state.additionalinstructionseditorContent}
                                    onInstanceReady={(e) => {
                                        this.setState({ additionalinstructionseditor: e.editor });
                                        this.state.editor.setData(this.state.additionalinstructionseditorContent.trim())
                                    }}
                                    config={{
                                        allowedContent: true,
                                        toolbarGroups: ckConfig
                                    }}
                                    onChange={(e) => { this.setState({ additionalinstructionseditorContent: e.editor.getData() }) }}
                                    disabled={this.state.isreadonlyforexpert}
                                /> */}


                                <CKEditor
                                    editor={ClassicEditor}
                                    style={{ width: "100%" }}
                                    data={this.state.apseditorData}
                                    onReady={(e) => {
                                        this.setState({ apseditor: e.editor });
                                        //this.state.editor.setData(this.state.editorData.trim())
                                    }}
                                    config={editorConfiguration}
                                    onChange={(e, editor) => { this.setState({ apseditorContent: editor.getData() }) }}

                                />
                            </div>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="formdisplayname">
                        <Form.Label column="sm" lg={3}>
                            Display Name
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control placeholder="Display Name" size="sm" type="text"
                                style={{ width: "410px" }}
                                required
                                value={this.state.displayname}
                                onChange={this.handledisplayname.bind(this)}
                                disabled={this.state.isreadonlyforexpert}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter display name.
                            </Form.Control.Feedback>

                        </Col>
                        <Col sm="3">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.display_name_comment}
                                            iscolorchange={this.state.display_name_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "display_name_comment", "Review Comment")}>
                                        </ChallengeComments>
                                    ) : null
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formquestionimage">
                        <Form.Label column="sm" lg={3}>Question image</Form.Label>
                        <Col sm="4">
                            <Form.Control type="file" size="sm" accept="image/png, image/jpeg"
                                onChange={this.handleQuestionImageChange.bind(this)} disabled={this.state.isreadonlyforexpert} />
                            <Form.Control.Feedback type="invalid">
                                Please upload Image.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm="1">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.question_image_comment}
                                            iscolorchange={this.state.question_image_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "question_image_comment", "Review Comment")}>
                                        </ChallengeComments>


                                    ) : null
                            }
                        </Col>
                        {
                            this.state.question_image !== null && this.state.question_image !== undefined && this.state.question_image !== "" ? (
                                <Col sm="4">
                                    <div class="geeks">
                                        <Image src={this.state.question_image}
                                            alt="" />
                                    </div>
                                </Col>
                            ) : null
                        }
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formanswertype">
                        <Form.Label column="sm" lg={3} >Answer Type</Form.Label>
                        <Col sm="2"><Form.Control size="sm" style={{ width: "140px" }}
                            as="select" required custom
                            value={this.state.answertype}
                            disabled={this.state.isreadonlyforexpert}
                            onChange={this.handleanswertype.bind(this)}
                        >
                            <option value="">Select</option>
                            <option value="FRQ">FRQ</option>
                            <option value="MCQ">MCQ</option>
                            <option value="MSQ">MSQ</option>
                            <option value="Ranking">Ranking</option>
                            <option value="Text">Text</option>
                        </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select answer type.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm="7">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.answer_type_comment}
                                            iscolorchange={this.state.answer_type_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "answer_type_comment", "Review Comment")}>
                                        </ChallengeComments>
                                    ) : null
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formcomplexitylevel">
                        <Form.Label column="sm" lg={3} >Complexity level</Form.Label>
                        <Col sm="2"><Form.Control size="sm" style={{ width: "140px" }}
                            as="select" required custom
                            onChange={this.handlecomplexitylevel.bind(this)}
                            value={this.state.complexitylevel}
                            disabled={this.state.isreadonlyforexpert}
                        >
                            <option value="">Select</option>
                            <option value="Hard">Hard</option>
                            <option value="Medium">Medium</option>
                            <option value="Easy">Easy</option>
                        </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select complexity level.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm="7">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.complexity_level_comment}
                                            iscolorchange={this.state.complexity_level_comment_color_change}
                                            disabled={this.state.isreadonlyforexpert}
                                            handlecomments={(e) => this.handlecomments(e, "complexity_level_comment", "Review Comment")}>
                                        </ChallengeComments>
                                    ) : null
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formsource">
                        <Form.Label column="sm" lg={3} >Source</Form.Label>
                        <Col sm="6"><Form.Control style={{ width: "410px" }}
                            onChange={this.handlesource.bind(this)} value={this.state.source} disabled={this.state.isreadonlyforexpert} />
                        </Col>
                        <Col sm="3">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.source_comment}
                                            iscolorchange={this.state.source_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "source_comment", "Review Comment")}>
                                        </ChallengeComments>

                                    ) : null
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formthumbnailimage">
                        <Form.Label column="sm" lg={3}>Thumbnail image</Form.Label>
                        <Col sm="4">
                            <Form.Control type="file" size="sm" accept="image/png, image/jpeg"
                                onChange={this.handleThumbnailImageChange.bind(this)} disabled={this.state.isreadonlyforexpert} />
                        </Col>
                        <Col sm="2">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.thumbnail_comment}
                                            iscolorchange={this.state.thumbnail_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "thumbnail_comment", "Review Comment")}>
                                        </ChallengeComments>
                                    ) : null
                            }
                        </Col>
                        {
                            this.state.thumbnail_image !== null && this.state.thumbnail_image !== undefined && this.state.thumbnail_image !== "" ? (
                                <Col sm="3">
                                    <div class="geeksthumbnail">
                                        <Image src={this.state.thumbnail_image}
                                            alt="" thumbnail={true} />
                                    </div>
                                </Col>
                            ) : null
                        }
                    </Form.Group>


                    <Form.Group as={Row} className="mb-2" controlId="formfeedbackimage">
                        <Form.Label column="sm" lg={3}>Feedback image</Form.Label>
                        <Col sm="4">
                            <Form.Control type="file" size="sm" accept="image/png, image/jpeg"
                                onChange={this.handleFeedbackImageChange.bind(this)} disabled={this.state.isreadonlyforexpert} />
                            <Form.Control.Feedback type="invalid">
                                Please upload Image.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm="1">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.feedback_image_comment}
                                            iscolorchange={this.state.feedback_image_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "feedback_image_comment", "Review Comment")}>
                                        </ChallengeComments>


                                    ) : null
                            }
                        </Col>
                        {
                            this.state.feedback_image !== null && this.state.feedback_image !== undefined && this.state.feedback_image !== "" ? (
                                <Col sm="4">
                                    <div class="geeks">
                                        <Image src={this.state.feedback_image}
                                            alt="" thumbnail={true} />
                                    </div>
                                </Col>
                            ) : null
                        }
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="forminlinesuitable">
                        <Form.Label column="sm" lg={3} >Inline suitable</Form.Label>
                        <Col sm="2">
                            <Form.Control style={{ width: "140px" }} size="sm"
                                as="select" required
                                custom
                                onChange={this.handleinlinesuitable.bind(this)}
                                value={this.state.inlinesuitable}
                                disabled={this.state.isreadonlyforexpert}
                            >
                                <option value="">Select</option>
                                <option value="True">True</option>
                                <option value="False">False</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select inline suitable.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm="7">
                            {
                                this.state.showcommentsfields === true ?
                                    (
                                        <ChallengeComments commentvalue={this.state.inline_suitable_comment}
                                            iscolorchange={this.state.inline_suitable_comment_color_change}
                                            handlecomments={(e) => this.handlecomments(e, "inline_suitable_comment", "Review Comment")}>
                                        </ChallengeComments>
                                    ) : null
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formchallengestatus">
                        <Form.Label column="sm" lg={3} >Status</Form.Label>
                        <Col sm="9">
                            <Form.Control style={{ width: "200px" }} size="sm"
                                as="select" required
                                custom
                                onChange={this.handlechallengestatus.bind(this)}
                                value={this.state.challenge_status}
                            >
                                <option value="">Select</option>
                                <option value="Under Development">Under Development</option>
                                <option value="Under Review">Under Review</option>
                                <option value="Review In Progress">Review In Progress</option>
                                <option value="Reviewed">Reviewed</option>
                                <option value="Beta Released">Beta Released</option>
                                <option value="Released">Released</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select status.
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-8" controlId="formthumbnailimage">
                        <Form.Label column="sm" lg={3}  ></Form.Label>
                        <Col sm="9" className="d-flex align-items-center justify-content-between pb-3">
                            {/* <Button type="submit" variant="primary" style={{ marginBottom: "20px" }}>Save</Button> */}
                            {button}
                            <Link to="/curriculum-problems" className="btn btn-outline-dark" style={{ textDecoration: 'none', marginBottom: "20px", marginTop: "20px", marginLeft: "10px" }}>Cancel</Link>
                        
                            <div className='' style={{ cursor: "pointer", textDecoration: 'none', marginTop: "45px", marginLeft: "10px", visibility :!isNil(this.props.match.params.challengeId) ? "visible" : "hidden"  }}>
                                <label for="file-uploadKB" class="browseImageBtnAdd">
                                    <img src={BrowseImageIcon} /> Add KB
                                </label>
                                <input id="file-uploadKB" accept=".docx, .pdf" type="file" onChange={this.onFileChange} className='imageUploadInput' />

                            </div>

                        </Col>
                    </Form.Group>
                </Form>
                <ToastContainer />

                {
                    this.state.loader && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#164b99",
                                position: 'absolute',
                                bottom: '5%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )
                }
            </>
        )
    }
}
export default withRouter(AddChallenge);