import React, { useEffect, useState } from 'react';
import './RealtimeQA.scss';
import { Col, Row } from 'react-bootstrap';
import bannerImg from '../../assets/images/qa-banner-img.png';
import {
    createsessionforclassroomquestion, stopsessionforclassroomquestion
    , getrepresentativequestions
    , UpdateClassroomTranscription
    , getSessionList
    , reviseTranscriptInClassApp
} from '../../common/API'
import { APP_URL, getCurrentUserId, getMenuSelectedItem } from '../../common/Functions';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from 'react-bootstrap/Modal';
import Button from '../Common/Button/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import QRCode from 'qrcode.react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import repQueImg from '../../assets/images/head-question-img.svg';
import ToggleButton from '@mui/material/ToggleButton';
import DeleteIcon from '../../assets/images/recording-delete-icon.svg';
import DownloadIcon from '../../assets/images/download-icon.svg';
import sortingIcon from '../../assets/images/filter-icon.svg';
import stopRec from '../../assets/images/stop-recording-icon.svg'
import startRec from '../../assets/images/start-recording-icon.svg'
import { isNil } from 'lodash';
import { CSVLink } from "react-csv"
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TableHead, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PreviewIcon from '@mui/icons-material/Preview';
import Cookies from 'universal-cookie';


const RealtimeQAComponent = () => {
    const cookies = new Cookies()
    const [session_id, setsession_id] = useState(null)
    const [loading, setloading] = useState(false)
    const [issessionstart, setissessionstart] = useState(false)
    const [questionlist, setquestionlist] = useState([])
    const [modalopen, setmodalopen] = useState(false)
    const [sessiontranscript, setsessiontranscript] = useState("")
    const [editedid, seteditedid] = useState(null)
    const [currentindex, setcurrentindex] = useState(null)
    const [hasRecordingStarted, setHasRecordingStarted] = useState(false)
    const [parentindex, setparentindex] = useState(null)
    const [isPrevSession, setisPrevSession] = useState(false)
    const [question, setquestion] = useState("")
    const [answerForEdit, setAnswerForEdit] = useState("")
    const [sessionList, setsessionList] = useState([])
    const [copied, setcopied] = useState(false)
    const [tabValue, setTabValue] = useState("Current")
    const [questionAnswerDivs, setquestionAnswerDivs] = useState([])
    const [expanded, setExpanded] = useState(null)
    const [existingAnswer, setExistingAnswer] = useState('')
    const [csvData, setcsvData] = useState([])
    const [csvDataStudent, setcsvDataStudent] = useState([])
    const [exportButtonDiv, setexportButtonDiv] = useState([])
    const [datesorting, setdatesorting] = useState('')
    const [previousSelectedSessionId, setpreviousSelectedSessionId] = useState(null)
    const [modalloading, setmodalloading] = useState(false)
    const [sessionName, setSessionName] = useState('');
    const [displaySessionName, setDisplaySessionName] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [contextText, setcontextText] = useState('')
    const [noOfThemes, setnoOfThemes] = useState(3)
    const [noOfQuestionsTheme, setnoOfQuestionsTheme] = useState(2)
    const [isQueShown, setIsQueShown] = useState(true);
    const [isconfig, setisconfig] = useState(true)
    const [configModalopen, setConfigModalopen] = useState(false)
    const [viewStudentQuestionData, setviewStudentQuestionData] = useState([])
    const [showStudentQuestionModal, setshowStudentQuestionModal] = useState(false)
    const [errorTextThemes, seterrorTextThemes] = useState("")
    const [errorTextQuestions, seterrorTextQuestions] = useState("")
    const [previousSessionList, setpreviousSessionList] = useState([])
    const queClick = event => {
        //setIsQueShown(true);
    };


    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition();
    let timeout;

    useEffect(() => {
        setloading(true)

        const reqData = { params: { "tutor_id": getCurrentUserId(), "course_id": getMenuSelectedItem() } };
        getSessionList(reqData).then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                let lastActiveSession = []
                lastActiveSession = res.data.sort((a, b) => Date.parse(b.createdate) - Date.parse(a.createdate))[0]
                if (!isNil(lastActiveSession) && lastActiveSession.issessionstop === 0) {
                    setissessionstart(true)
                    setSessionName(lastActiveSession.session_name)
                    setsession_id(lastActiveSession.sessionid)
                }
            }
        })

        setloading(false)
    }, [])

    const getnewcountdata = () => {
        getquestionlist()
    }

    const handleCloseConfigModal = () => {
        setisconfig(isconfig)
        setConfigModalopen(false)
    }

    const handleCloseStudentQuestionModal = () => {
        setshowStudentQuestionModal(false)
    }

    const handleSetConfig = () => {
        setisconfig(!isconfig)
        seterrorTextQuestions("")
        seterrorTextThemes("")
        setConfigModalopen(true)
    }

    const handlestopquestionsession = () => {
        setcopied(false)
        setloading(true)
        setSessionName('')
        const reqData = {
            tutor_id: getCurrentUserId(),
            session_id: session_id,
            noofthemes: noOfThemes,
            noofquestionstheme: noOfQuestionsTheme,
            contexttext: contextText
        }

        stopsessionforclassroomquestion(reqData).then(res => {
            if (res.data !== undefined) {
                setquestionlist(res.data)
                setloading(false)
                setissessionstart(false)
            }
        }).catch(err => {
            console.error(err.message)
            setloading(false)
            setissessionstart(false)
            setsession_id(null)
            toast.error("Opps! Something wrong", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    const handleDateSorting = () => {
        if (datesorting === "desc") {
            setdatesorting("asc")
            setsessionList(sessionList.sort((a, b) => Date.parse(a.createdate) - Date.parse(b.createdate)))
        }
        else {
            setdatesorting("desc")
            setsessionList(sessionList.sort((a, b) => Date.parse(b.createdate) - Date.parse(a.createdate)))
        }
    }

    const handlecreatesession = () => {
        if (sessionName !== '') {
            setloading(true)
            setissessionstart(true)

            const reqData = {
                tutor_id: getCurrentUserId(),
                sessionName: sessionName,
                course_id: getMenuSelectedItem()
            }

            createsessionforclassroomquestion(reqData).then(res => {
                if (res.data !== undefined) {
                    setsession_id(res.data.session_id);
                    setDisplaySessionName(sessionName);
                    setloading(false);
                    setquestionlist([]);
                }
            }).catch(err => {
                console.error(err.message)
                setloading(false)
                toast.error("Opps! Something wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        } else {
            toast.error("Kindly provide a valid session name.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    }

    const getquestionlist = (sessionId) => {

        let sessionID = (isNil(sessionId) ? session_id : sessionId)
        if (sessionID !== null) {
            setloading(true)
            const reqData = {
                params: {
                    session_id: sessionID
                }
            }
            getrepresentativequestions(reqData).then(res => {
                if (res.data.representative_questions !== undefined) {
                    setquestionlist(res.data.representative_questions)
                    setloading(false)
                }
            }).catch(err => {
                console.error(err.message)
                setloading(false)
                toast.error("Opps! Something wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        }
    }

    const listenContinuously = (index, id, parentindex, question, savedTranscript, selectedItem = null) => {
        if (tabValue === "Current") {
            if (id === editedid) {
                setloading(true);
                setHasRecordingStarted(false);
                seteditedid(null);
                SpeechRecognition.stopListening();
                timeout = setTimeout(() => {
                    savetranscript(selectedQuestion.session_transcript);
                    resetTranscript();
                    setloading(false);
                    setSelectedQuestion(null);
                    setExistingAnswer('');
                }, 4000)

            } else {
                setSelectedQuestion(selectedItem);
                let existingAns = !isNil(savedTranscript) ? savedTranscript : '';
                setExistingAnswer(existingAns);
                setHasRecordingStarted(true);
                setcurrentindex(index)
                setparentindex(parentindex)
                seteditedid(id)
                setquestion(question)
                SpeechRecognition.startListening({
                    continuous: true,
                    language: 'en-GB',
                })
            }
        }
        else {
            setmodalopen(true)
            seteditedid(id)
            setcurrentindex(index)
            setparentindex(parentindex)
            setquestion(question)
            // if (sessiontranscript !== undefined)
            //     setsessiontranscript(sessiontranscript)

            SpeechRecognition.startListening({
                continuous: true,
                language: 'en-GB',
            });
        }

    }

    useEffect(() => {
        if (finalTranscript !== '') {
            setsessiontranscript(finalTranscript);
            if (!isNil(selectedQuestion)) {
                selectedQuestion.session_transcript = existingAnswer + " " + finalTranscript;
            }
            console.log('Got final result:', finalTranscript);
        }
    }, [interimTranscript, finalTranscript]);

    const handleClosemodal = () => {
        setmodalopen(false)
        SpeechRecognition.stopListening()
    }

    const transcripthandler = (e) => {
        setAnswerForEdit(e.target.value)
    }

    const reviewtranscript = (answer) => {
        setmodalloading(true)

        let reqData = {
            transcript: answer
        }
        reviseTranscriptInClassApp({ params: reqData }).then(res => {
            if (res.data.response !== undefined && res.data.response !== null) {
                setAnswerForEdit("")
                setAnswerForEdit(res.data.response)
                setmodalloading(false)
                // getQuestionDiv(previousSelectedSessionId)
                // setmodalopen(false)
            }
        })
    }

    const savetranscript = (answer) => {
        setmodalloading(true)
        clearTimeout(timeout)
        resetTranscript()
        SpeechRecognition.stopListening()
        const reqDataS = {
            id: editedid,
            session_transcript: answer,
            question: question
        }

        UpdateClassroomTranscription(reqDataS).then(res => {
            if (tabValue === "Previous") {
                getQuestionDiv(previousSelectedSessionId)
                setmodalopen(false)
                setmodalloading(false)
            }
            else {
                if (questionlist.length > 0) {
                    let questionlistdata = [...questionlist]
                    if (questionlist[parentindex].children != undefined && questionlist[parentindex].children.length > 0) {
                        let tempArr = questionlist[parentindex].children;
                        tempArr[currentindex].representative_question = question;
                        tempArr[currentindex].session_transcript = answer;
                        questionlistdata[parentindex]["children"] = tempArr
                        setquestionlist(questionlistdata)
                        setmodalopen(false)
                    }
                }
            }

        }).catch(err => {
            console.error(err.message)
            toast.error("Opps! Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    const handleChange = (e) => {
        if (e.target.value > 0) {
            setisPrevSession(true)
            setsession_id(e.target.value);
            getquestionlist(e.target.value);
        }
        else {
            setissessionstart(false)
            setisPrevSession(false)
            setsession_id(null)
            getquestionlist(null)
        }
    }

    const questionChangeHandler = (e) => {
        setquestion(e.target.value)
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(session_id);
        setcopied(true)
    }

    const handleTabChange = (newValue) => {
        if (newValue === "Previous") {
            setisPrevSession(true)

            const reqData = { params: { "tutor_id": getCurrentUserId(), "course_id": getMenuSelectedItem() } };
            getSessionList(reqData).then(res => {
                setdatesorting("desc")
                setpreviousSessionList(res.data)
                setsessionList(res.data.sort((a, b) => Date.parse(b.createdate) - Date.parse(a.createdate)))
            })
        }
        else
            setisPrevSession(false)

        setTabValue(newValue);
    }

    const getQuestionDiv = (sessionId) => {

        var divs = []
        let csvExportData = [["Question No.", "Question", "Answer", "Upvote"]];
        let csvExportDataStudent = [["Student Name", "Question"]]
        var mainDiv = []
        let qaDiv = []
        setloading(true)
        setpreviousSelectedSessionId(sessionId)
        const reqData = {
            params: {
                session_id: sessionId
            }
        }
        getrepresentativequestions(reqData).then(res => {

            if (res.data.student_questions !== undefined && res.data.student_questions.length > 0) {
                setviewStudentQuestionData(res.data.student_questions)
                res.data.student_questions.map((student) => {
                    csvExportDataStudent.push([student.student_name, student.student_question])
                })
                setcsvDataStudent(csvExportDataStudent)
            }
            else {
                setviewStudentQuestionData(null)
            }

            if (res.data.representative_questions !== undefined && res.data.representative_questions.length > 0) {
                let counter = 0
                let downloadCounter = 0;
                res.data.representative_questions.map((item, parentindex) => {
                    qaDiv = [];
                    let id = sessionId
                    qaDiv.push
                        (
                            <div id={id} className='themeRow'>
                                <Row className='ml-1' style={{ background: "#fff" }}>
                                    <Col className='col-12 pl-0 themeTitle'>Theme: {item.theme}</Col>
                                </Row>

                            </div>
                        )
                    if (item.children !== undefined && item.children.length > 0) {
                        counter = 0;
                        item.children.map((data, index) => {
                            downloadCounter += 1;
                            counter += 1;
                            qaDiv.push
                                (
                                    <div>
                                        <p className='queText' onClick={() => queClick()} >Q{counter}. {data.representative_question} </p>
                                        {isQueShown && (
                                            <Row className='ml-3 pb-2'>
                                                <Col className='col-12 pt-2 pb-3'>
                                                    <div className='d-flex'>
                                                        <button className='lightPinkSmallBtn10'>Upvotes: {data.total_likes}</button>
                                                        <button onClick={() => editQuestion(index, data.id, parentindex, data.representative_question, data.session_transcript)} className='lightBlueSmallBtn10'>Edit Question<i className='fa fa-pencil pl-2' aria-hidden="true"></i></button>
                                                    </div>
                                                </Col>
                                                <Col className='col-12'>
                                                    <div className='trascriptBox'>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <span className='text-uppercase'>Transcript</span>
                                                            <button onClick={() => editQuestion(index, data.id, parentindex, data.representative_question, data.session_transcript)} className='editTranscriptBtn'> </button>
                                                        </div>
                                                        <p>{data.session_transcript}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}

                                    </div>
                                )
                            csvExportData.push(
                                [downloadCounter, data.representative_question, data.session_transcript, data.total_likes]
                            )
                        })
                    }
                    divs.push(qaDiv)

                });
                setcsvData(csvExportData)
            }
            let pId = sessionId + "-" + parentindex
            mainDiv.push
                (
                    <div id={pId}>
                        <Row className='csvBtnContainer'>
                            <Col className='col-12'>
                                {/* {console.log("csv data - " + JSON.stringify(csvExportData))}                                                                                                 */}
                                <CSVLink data={csvExportData} className='exportCSVbtn exportRepresentative' filename={sessionId + "_RepresentativeQuestions.csv"}> Export Representative Questions <img alt='download Representative Questions' src={DownloadIcon}></img></CSVLink>
                                <CSVLink data={csvExportDataStudent} className='exportCSVbtn exportStudent' filename={sessionId + "_studentsquestions.csv"}>Export Student Questions <img alt='Download Student Questions' src={DownloadIcon}></img></CSVLink>
                                <button className='exportCSVbtn viewQuestions' onClick={() => handleViewStudentQuestionClick()}>View Student Questions <PreviewIcon style={{ marginLeft: '3px', height: '18px', width: '18px' }} /></button>
                            </Col>
                        </Row>
                    </div>
                )

            mainDiv.push(divs)
            setquestionAnswerDivs(mainDiv)
            setloading(false)
        })
    }

    const handleViewStudentQuestionClick = () => {
        setshowStudentQuestionModal(true)
    }

    const editQuestion = (index, id, parentindex, question, answer) => {
        setmodalopen(true)
        seteditedid(id)
        setcurrentindex(index)
        setparentindex(parentindex)
        setquestion(question)
        setAnswerForEdit(answer)
        // if (sessiontranscript !== undefined)
        //     setsessiontranscript(sessiontranscript)
        setmodalloading(false)
    }

    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric', weekday: 'long' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);

        const options1 = { weekday: 'long' };
        const currentDayOfWeek = formattedDate.toLocaleString('en-US', options1);

        const sDay = currentDayOfWeek.split(',')[0] + ", "
        const sDate = currentDayOfWeek.split(',')[1].split(" ")[1]
        const sMonth = currentDayOfWeek.split(',')[1].split(" ")[2]
        const sYear = currentDayOfWeek.split(',')[2]

        // Return the formatted date with uppercase month abbreviation and desired format
        return `${sDay} ${sDate} ${sMonth} ${sYear}`;
    }

    const handleAccordionChange = id => (_, isExpanded) => {
        setExpanded(isExpanded ? id : null);
    }

    function EnhancedTableHead(props) {
        // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        //     props;
        // const createSortHandler = (property) => (event) => {
        //     onRequestSort(event, property);
        // };

        return (
            <TableHead style={{ display: 'contents', width: '100%' }}>
                <TableRow>
                    <TableCell
                        style={{ width: '25%' }}
                        key="studentname"
                        align={'left'}
                        padding={'normal'}
                    >
                        Student Name
                    </TableCell>
                    <TableCell
                        style={{ width: '75%' }}
                        align={'left'}
                        padding={'normal'}
                    >
                        Student Questions
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const setConfigValues = (val, from) => {
        if (val > 0) {
            if (from === "theme") {
                setnoOfThemes(val)
            }
            else {
                setnoOfQuestionsTheme(val)
            }
        }
        else {
            if (from === "theme") {
                seterrorTextThemes("No. of themes must be atleast 1.")
            }
            else {
                seterrorTextQuestions("No. of questions per theme must be atleast 1.")
            }
        }
    }

    const searchbtnClickHandler = (e) => {

        let searchVal = e.target.value

        if (searchVal !== undefined && searchVal !== null && searchVal !== "") {
            let filteredSessionList
            if (searchVal.includes("/") && !isNaN(new Date(searchVal))) {
                filteredSessionList = previousSessionList.filter((a) => formatDate(a.createdate) === formatDate(searchVal))
            }
            else {
                filteredSessionList = previousSessionList.filter((a) => a.sessionid.includes(searchVal))
            }

            setsessionList(filteredSessionList)
        }
        else {
            const reqData = { params: { "tutor_id": getCurrentUserId(), "course_id": getMenuSelectedItem() } };
            getSessionList(reqData).then(res => {
                setdatesorting("desc")
                setsessionList(res.data.sort((a, b) => Date.parse(b.createdate) - Date.parse(a.createdate)))
            })
        }
    }

    return (
        <>
            <Modal className='studentModal' show={showStudentQuestionModal} onHide={handleCloseStudentQuestionModal}>
                <Modal.Header closeButton>
                    <Modal.Title> View Student Questions </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        style={{ width: '99%' }}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <TableBody>
                                            <EnhancedTableHead />
                                            {
                                                viewStudentQuestionData !== undefined && viewStudentQuestionData !== null && viewStudentQuestionData.length > 0 ?
                                                    viewStudentQuestionData.map((student, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ verticalAlign: 'top' }} align="left">{student.student_name}</TableCell>
                                                                <TableCell style={{ verticalAlign: 'top' }} align="left">{student.student_question}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan="2">
                                                            No questions found from any students on this session...!
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                </Modal.Body>
            </Modal >

            <Modal show={configModalopen} onHide={handleCloseConfigModal}>
                <Modal.Header closeButton>
                    <Modal.Title> Set Config </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label style={{ marginRight: '5px' }}> No. of themes </label>
                        <input class="form-control bg-yellow" type='number' value={noOfThemes} onChange={(e) => setConfigValues(e.target.value, "theme")} placeholder="No. of themes..."></input>
                        <label style={{ marginLeft: '5px', color: 'red', marginTop: "10px", fontSize: "14px" }}>
                            {errorTextThemes} </label><br />

                        <label style={{ marginRight: '5px' }}> No. of questions per theme </label>
                        <input class="form-control bg-yellow" type='number' value={noOfQuestionsTheme} onChange={(e) => setConfigValues(e.target.value, "questions")} placeholder="No. of questions per theme..."></input>
                        <label style={{ marginLeft: '5px', color: 'red', marginTop: "10px", fontSize: "14px" }}>
                            {errorTextQuestions} </label><br />

                        <label style={{ marginRight: '5px' }}> Context </label>
                        <input disabled class="form-control bg-yellow" value={contextText} onChange={(e) => setcontextText(e.target.value)} placeholder="Context..."></input>
                    </div>
                    {
                        modalloading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#164b99",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="yellow-btn"
                        clicked={handleCloseConfigModal}>
                        Update
                    </Button>
                    <Button className="grey-btn"
                        clicked={handleCloseConfigModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalopen} onHide={handleClosemodal}>
                <Modal.Header closeButton>
                    <Modal.Title> Edit Question / Transcript</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: "500px" }}>
                        <textarea
                            style={{ width: "95%" }}
                            rows={3}
                            value={question}
                            onChange={(e) => questionChangeHandler(e)}
                        />
                    </div>
                    <div style={{ width: "500px" }}>
                        <textarea
                            style={{ width: "95%" }}
                            rows={10}
                            value={answerForEdit}
                            onChange={(e) => transcripthandler(e)}
                        />
                    </div>



                    {
                        modalloading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#164b99",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="yellow-btn"
                        clicked={() => reviewtranscript(answerForEdit)}
                    >
                        Review
                    </Button>
                    <Button className="yellow-btn"
                        clicked={() => savetranscript(answerForEdit)}
                    >
                        Save
                    </Button>
                    <Button className="grey-btn"
                        clicked={handleClosemodal}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='realtimeQa'>
                <div className='container-fluid banner-container'>
                    <div className='container bannerContainer'>
                        <Row className='align-items-end'>
                            <Col className='col-12 col-lg-6 order-lg-1 order-2 pb-4' >
                                <h2>Creating an interactive and <br className='d-none d-lg-block' />engaging learning environment.</h2>
                                {
                                    !issessionstart ? (<p>Start the session for students questions.</p>) :
                                        <p>Session name: <strong>{sessionName}</strong></p>
                                }
                                <div className='d-block d-sm-flex align-items-center'>
                                    <div className='sessionBtnContainer'>
                                        {
                                            !issessionstart ? (<div> <input type="text"
                                                onChange={(e) => {
                                                    setSessionName(e.target.value)
                                                }
                                                }
                                                placeholder="Session name...">
                                            </input>
                                                <button onClick={handlecreatesession}> Start New Session</button>

                                            </div>) :
                                                (
                                                    <div className='setStopBtns'>
                                                        <button onClick={handleSetConfig}>Set Config</button>
                                                        <button onClick={handlestopquestionsession}>Stop Session</button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                    {!issessionstart || isPrevSession ? null
                                        :
                                        <div className='pl-0 no-border'>
                                            <div className='codeBox'>
                                                <p className='codeNum'>{session_id}</p>
                                                {session_id !== null ?
                                                    (<CopyToClipboard style={{ marginLeft: '5px' }} text={session_id}
                                                        onCopy={copyToClipboard}>
                                                        <button className='copyCodeBtn'><ContentCopyIcon onClick={copyToClipboard}></ContentCopyIcon></button>
                                                    </CopyToClipboard>) : null
                                                }
                                                {copied ? <span style={{ color: 'green', fontSize: "13px" }}>Copied.</span> : null}
                                            </div>
                                        </div>
                                    }


                                </div>
                            </Col>
                            <Col className='col-12 col-lg-3 order-lg-2 order-3 d-flex text-center justify-content-lg-end justify-content-center'>
                                {
                                    !issessionstart || isPrevSession ? null :
                                        <div>
                                            <QRCode value={`${APP_URL}question/?sessionId=${session_id}`} />
                                            <p>Students: Scan for Q&A</p>
                                        </div>
                                }
                            </Col>
                            <Col className='col-12 col-lg-3 order-lg-3 order-1'>
                                <img src={bannerImg} className='img-fluid p-5 p-lg-1' />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='container bg-white-with-shadow my-3 my-md-5'>
                        <Row className='pt-3 pb-4 pb-1'>
                            <Col className='col-12 d-flex align-items-center'>
                                <img src={repQueImg} alt='' />
                                <h3 className='bannerHead pl-2'>Representative Questions From Your Students</h3>
                            </Col>
                        </Row>
                        <Box sx='box' className="qTabs">
                            <Tabs activeKey={tabValue} onSelect={handleTabChange} className="tabs ">
                                <Tab eventKey="Current" title="Current Session">
                                    <Row className='pt-4'>
                                        <Col className='col-12 col-md-9'>
                                            <p className='sessionNameText'>Session: {displaySessionName} (#{session_id})</p>
                                        </Col>
                                        <Col className='col-12 col-md-3 pt-2 pt-sm-0'>
                                            <div className='d-flex justify-content-sm-end'>
                                                <button className='lightBlueSmallBtn' onClick={() => getnewcountdata()}>Refresh<i className='fa fa-refresh pl-2' aria-hidden="true"></i></button>
                                                <button className='lightBlueSmallBtn'>Edit</button>
                                            </div>
                                        </Col>
                                    </Row>

                                    {questionlist !== undefined && questionlist.length > 0 ? (
                                        questionlist.map((item, parentindex) => {
                                            return (
                                                <>
                                                    <div className='themeRow'>
                                                        <Row className='py-1 ml-1' style={{ background: "#fff" }}>
                                                            <Col className='col-12 themeTitle'>Theme: {item.theme}</Col>
                                                        </Row>
                                                    </div>
                                                    {
                                                        item.children !== undefined && item.children.length > 0 ? (
                                                            item.children.map((data, index) => {
                                                                let totallikes = (data.total_likes !== "" && data.total_likes !== null) ? data.total_likes : 0
                                                                let arrodata = ``
                                                                return (
                                                                    <Row className="ml-3">
                                                                        <Col className='col-12 pb-3'>
                                                                            {
                                                                                data.id === editedid && hasRecordingStarted ? (<textarea style={{ width: '95%' }} rows={2} value={question} onChange={(e) => questionChangeHandler(e)} />) :
                                                                                    (<p className='queText'>Q{index + 1}.  {data.representative_question}</p>)
                                                                            }
                                                                            <div className='d-flex'>
                                                                                <button className='lightPinkSmallBtn10'>Upvotes: {data.total_likes}</button>
                                                                                <button onClick={() => editQuestion(index, data.id, parentindex, data.representative_question, data.session_transcript)} className='lightBlueSmallBtn10'>Edit Question<i className='fa fa-pencil pl-2' aria-hidden="true"></i></button>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-12'>
                                                                            {

                                                                                <div className='trascriptBox'>
                                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                                        <span className='text-uppercase'>Transcript</span>
                                                                                        <button onClick={() => editQuestion(index, data.id, parentindex, data.representative_question, data.session_transcript)} className='editTranscriptBtn'></button>
                                                                                    </div>
                                                                                    <p>{data.session_transcript}</p>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col className='col-12'>
                                                                            <div className='transcriptBtns'>
                                                                                <button className='deleteTranscriptBtn' disabled>
                                                                                    <img src={DeleteIcon} />
                                                                                    <span>Delete</span>
                                                                                </button>
                                                                                <ToggleButton className='recordingBtn' disabled={data.id !== editedid && hasRecordingStarted} onClick={() => listenContinuously(index, data.id, parentindex, data.representative_question, data.session_transcript, data)}
                                                                                    value="check">
                                                                                    <img src={data.id === editedid && hasRecordingStarted ? stopRec : startRec}></img>
                                                                                    <span>{data.id === editedid && hasRecordingStarted ? 'Stop Recording' : 'Start Recording'}</span>
                                                                                </ToggleButton>
                                                                                <button className='wavetBtn'>
                                                                                    <div className={data.id === editedid && hasRecordingStarted ? 'waveAni' : 'waveAniStop'}></div>
                                                                                    <span>--:--</span>
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                )
                                                            })

                                                        )
                                                            : null}
                                                </>
                                            )
                                        })
                                    ) : null
                                    }
                                </Tab>
                                <Tab eventKey="Previous" title="Previous Session">
                                    <div>
                                        <Row className='py-4'>
                                            <Col className='col-12 col-md-9 searchSession'>
                                                <TextField style={{ width: '375px' }} label="" placeholder='Search Session ID or Date (mm/dd/yyyy)'
                                                    onChange={(e) => searchbtnClickHandler(e)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'> <SearchIcon /> </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Col>
                                            <Col className='col-12 col-md-3 d-flex align-items-center justify-content-end '>
                                                <button onClick={() => handleDateSorting()} className='sortByBtn'>
                                                    Sort By Dates <img src={sortingIcon} alt='Sort by date (Ascending/Descending)' onClick={() => handleDateSorting()} ></img>
                                                </button>
                                            </Col>
                                        </Row>
                                        {sessionList !== undefined && sessionList !== null && sessionList.length > 0 ?
                                            sessionList.map((session) => {
                                                return (
                                                    <div id={session.sessionid}>
                                                        {!session.issessionstop ? null : (
                                                            <div className='psAcc'>
                                                                <Accordion expanded={expanded === session.sessionid} onChange={handleAccordionChange(session.sessionid)}>
                                                                    <AccordionSummary onClick={() => getQuestionDiv(session.sessionid)} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                                        <Typography >
                                                                            <Row>
                                                                                <Col className='col-12 col-md-12'>
                                                                                    <p style={{ float: 'left' }} className='sessionNameText'>
                                                                                        {formatDate(session.createdate)} ({!isNil(session.session_name) ? session.session_name + "," : ''} #{session.sessionid}  {!isNil(session.createdby) ? ", By " + session.createdby : ''})
                                                                                    </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            <div className='acc-content'>
                                                                                {questionAnswerDivs}
                                                                            </div>
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                            : null}
                                    </div>
                                </Tab>
                            </Tabs>
                        </Box>
                    </div>
                    <ToastContainer />
                </div>
                {
                    loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#164b99",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )
                }
            </div >
        </>
    )

}

export default RealtimeQAComponent