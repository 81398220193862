import React, { Component } from "react";
import "./sketchpad.scss";
import Aux from "../../hoc/Wrapper";
import { Row, Col, Image } from 'react-bootstrap';
import Draggable from "react-draggable";
import { fetchKnowledgeGraph, generateLatexFromMathML, fetchSubSectionContent, generateSVGFromMathML, insertStudentError, askAnyProblemQuestion, getUIConfigDetails, saveUIConfigDetails, saveuseractions } from '../../common/API';
import {
  Problem_source, User_Role, formatAnyProblemAPIResponse, getCurrentUserId, getCurrentUserRole, getMathmlFromWiris, obtainSubstitution
  , showFullApp, equationContainerClass, UserEvaluateFeedback, detectOS,
  getInstituteId, detectDevice,
  TABLET, DESKTOP
} from '../../common/Functions';
import { demoUserId, Institute, substituteText } from '../../common/Constants';
import {
  fetchWiris, updateWiris, createSketchpadBoard, createAwwappBoard, deleteAwwappBoard, checkEquation, Checkmywork_capture_image, imgURL
  , checkmyworkfeedbackupdate, checkmyworkfeedbackthumbsupdownupdate, deleteallequations, saveUpdateFRQEquations
} from '../../common/API';
//import { MathpixLoader, MathpixMarkdown } from 'mathpix-markdown-it'
import Spinner from "react-bootstrap/Spinner";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterNoneRoundedIcon from '@mui/icons-material/FilterNoneRounded';
import { clone, filter, isEmpty, isNil, maxBy, slice, cloneDeep, isNull } from "lodash";
import EquationDisplay from "./EquationDisplay";
import TextComponent from "./TextComponent/TextComponent";
import Flow from '../../components/SolutionGraph/flow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentOptionsPopup from '../../components/Common/ContentOptionsPopup/ContentOptionsPopup';
import HomeCurriculumContentCard from '../../components/CurriculumContent/CurriculumContentCard/HomeCurriculumContentCard';
import Canvas from "../../components/canvas/Canvas";
import { CanvasProvider } from "../../components/canvas/CanvasContext";
import { Switch, ToggleButton, Tooltip } from '@mui/material';
import { ModalBody } from "react-bootstrap";
import OpenEditEquationIcon from '../../assets/images/edit-equation.svg';
import CloseEditEquationIcon from '../../assets/images/edit-equation-close.svg';
import AskTutIcon from '../../assets/images/Ask_icon.svg';
//import LogoSign from '../../assets/images/CopySimple.svg';
import AskAITutIcon from '../../assets/images/ai-tutor.png';
import CircularProgress from '@mui/material/CircularProgress';
import { width } from "@mui/system";
import ShowMathToolbarIcon from '../../assets/images/icon-show-toolbar.svg';
import HideMathToolbarIcon from '../../assets/images/icon-hide-toolbar.svg';
import ImganalysingWork from '../../assets/images/img-analysing-work.png';
import aiPlatoReadImg from "../../assets/images/img-onboard-aiplato-read.svg";
import TourContent from "../Tour/TourContent";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import html2canvas from "html2canvas";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import SplitPane, { Pane } from 'react-split-pane';
import equationBoardVideo from "../../assets/videos/Equation_Board1.mp4";
import CkEditorComponent from "../../components/CkEditorComponent/CkEditorComponent";
import AnyProblem from "../../components/CurriculumContent/AnyProblem/AnyProblem";
import checkWorkIcon from '../../assets/images/icon-check.svg';
import { Modal, Button } from 'react-bootstrap';
import whiteBoardVideo from "../../assets/videos/WhiteBoard-Video_HelpCenter.mp4";
import whiteBoardVideoRice from "../../assets/videos/WhiteBoard-Video_HelpCenter_Rice.mp4";
import VideocamIcon from '@mui/icons-material/Videocam';
import SchoolIcon from '@mui/icons-material/School';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Modal1 from '../../components/Common/ModalComponent/ModalComponent';
import MathJaxRenderer from "../../components/Common/MathJaxRenderer/MathJaxRenderer";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PencilIcon from '../../assets/images/icon-pencil.svg';
import UploadImageIcon from '../../assets/images/icon-upload-image.svg';
import CalcIcon from '../../assets/images/icon-calculation.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';

const CONTENT_TYPE = {
  EQUATION: 'eq',
  TEXT: 'text'
}
class SketchpadBoard extends Component {

  mathImageSyncTimer = undefined
  webSocket;
  primaryServer = "";
  isBoardInitialized = false;
  tempText;
  timeout;
  lastTap = 0;
  equationCounter = 0;
  wsInterval;
  contentModalBody = null;
  hasEquationWithBorder = false;
  clearCanvas = null;
  lastAiTutorState = false;
  isMessageForMacOs = false;
  showEqualMessageCounter = 0;

  constructor() {
    super();
    this.state = {
      showEqI: false,
      wirisSize: "small",
      mathImage: [],
      showModal: false,
      selectedImageIndex: null,
      boardId: null,
      teacherStudentId: null,
      mouseDrag: false,
      question_image: null,
      window_height: '0',
      window_width: '0',
      challengeId: null,
      challengeMapId: null,
      challengeCompleted: false,
      openBackdrop: true,
      uploadedCorrectImages: [],
      selectedToolbarItemId: 'aww-toolbar-draw',
      equations: [],
      responseArray: [],
      texts: '',
      savedText: '',
      equationHighlighter: [],
      lastClickedPosition: { x: 0, y: 0 },
      isWirisHadEditMode: false,
      textId: 1,
      challengeType: CONTENT_TYPE.EQUATION,
      hasSketchpadView: true,
      contentOptionsPosition: {
        top: 0,
        left: 0
      },
      showContentOptions: false,
      isContentOptionsLoading: false,
      isVisible: true,
      checkmyworkdisabled: false,
      loader: false,
      // isAnyProblem: true,
      sessionId: null,
      hidewirispanel: true,
      showhidebuttontext: "Hide Math Toolbar",
      anyProblemStatement: '',
      eraseCanvas: false,
      isFromProfToTutor: false,
      equationBoardTooltip: '',
      isVisibleEquationboard: false,
      isBlinkCheckMyWork: false,
      checkForStreamlineMsg: false,
      ckEditorMathML: '',
      editorHeight: '390px',
      askAIenabled: false,
      askAIquestion: "",
      askAIBgColor: false,
      equationClass: "",
      showModalVideo: false,
      isWhiteBoardVideoAutoPlayDone: false,
      whiteBoardVideoDiv: '',
      equationClick: false,
      thumbsupvalue: 0,
      linematch: null,
      showWarningModal: false,
      warningModalBody: null,
      hasWarningPopup: false,
      deleteconfirmodelopen: false,
      isallequationdelete: false,
      strokes: "",
      canvasDeleteModelOpen: false,
      deleteconfirmodelopensingle: false,
      deleteequationindex: null,
      showEqI: false,
      doNotShowChk: false,
      isblankEquationModal: false
    }
    this.firstOpen = true;
    this.initializeBoard = this.initializeBoard.bind(this)
    this.boardClickedHandler = this.boardClickedHandler.bind(this);
  }

  sk_ref = React.createRef();
  left_Panel_Classes = showFullApp() ? "col-md-8 pb-2" : "col-md-12 pb-2";
  right_Panel_Classes = "col-md-4";
  askAI_panel_Classes = "col-md-0"
  parentHeight = 0
  parentWidth = 0
  canvasStrokes;
  canvasCount;
  psource = window.location.pathname.includes("answerboardpt") ? Problem_source.apptesthomework : window.location.pathname.includes("answerboardhow") ? Problem_source.homework : Problem_source.interactiveproblems
  canvasStrokes;
  canvasCount;

  componentWillMount() {

    // console.log("problem source.....", this.psource)
    if (showFullApp() && this.psource === Problem_source.apptesthomework) {
      if (detectOS() === 'MacOS' && !this.state.hasWarningPopup) {
        this.setState({
          warningModalBody:
            <ul>
              <li>Handwriting is slow with a mouse.  To enable it, you can click pen <img alt='Pen Icon' src={PencilIcon} />.  Rather than handwriting with a mouse, we recommend "Upload Handwritten Page" <img alt='Pen Icon' src={UploadImageIcon} />, or "Equation Keyboard" <img alt='Pen Icon' src={CalcIcon} />. Students can also handwrite easily with low-cost Pen Tablets (e.g. Wacom).  </li>
              <li>Avoid hand-drawing diagrams.  Diagram interpretation will be in the next release. </li>

            </ul>
        })
        this.setState({ showWarningModal: true, hasWarningPopup: true })
        //alert(`If you're having trouble writing equations with a pen on a whiteboard, please use the equation editor tool.`);
      } else {
        this.setState({
          warningModalBody:
            <ul>
              <li>Enter equations by writing on whiteboard using this Pen <img alt='Pen Icon' src={PencilIcon} />, or by using this equation keyboard <img alt='Pen Icon' src={CalcIcon} />.  You can also upload a handwritten page by clicking on <img alt='Pen Icon' src={UploadImageIcon} />.</li>
              <li>Avoid hand-drawing diagrams.  Diagram interpretation will be in the next release.  </li>
              {/* <li>For practice test we display the timer, we don't enforce it, to allow you extra time for hand-writing, or typing or uploading. </li> */}

            </ul>
        })
        this.setState({ showWarningModal: true })
      }
    }

    if (this.props.isAnyProblem) {
      this.setState({ isVisible: false })
    }

    if (this.props.showEquationPanel) {
      this.setState({ isVisibleEquationboard: true })
    }

  }

  componentDidMount() {
    this.setState({ loader: true })
    if (this.props.location !== undefined) {
      let searchParamsRoom = new URLSearchParams(this.props.location.search);
      if (this.props.location.state !== undefined && this.props.location.state.prevPath !== undefined) {
        if (this.props.location.state.prevPath.toString() === "/") {
          this.setState({ isFromProfToTutor: true })
        }
      }
      if (searchParamsRoom.get('roomName') !== null && getCurrentUserRole() === User_Role.Professor) {
        this.setState({ isFromProfToTutor: true })
      }
    }
    this.setState({ isAnyProblem: this.props.isAnyProblem })
    this.setState({
      equationBoardTooltip:
        <Aux>
          <TourContent
            tourTitle="Please note"
            contentImg={aiPlatoReadImg}
            isVideoWidth={true}
            contentVideo={equationBoardVideo}
            contentHeader="See How aiPlato Reads It"
            contentDetails="Check your handwritten notes as they get interpreted by aiPlato to ensure your work stays on the right track."
          />
        </Aux>
    })
    if (this.props.question.type === 'Text') {
      this.setState({ challengeType: CONTENT_TYPE.TEXT })
    }
    this.props.location.state = {
      ...this.props.location.state,
      checkEquationBoard: this.checkEquationBoard,
      submitFunction: this.submitEquation,
      knowledgeGraphFunction: this.getKnowledgeGraph
    }
    let params = new URLSearchParams();
    if (this.props.question.custom === false) {
      params.append('challengeId', this.props.question.id.slice(1, this.props.question.id.length));
      this.setState({ challengeId: this.props.question.id.slice(1, this.props.question.id.length) })
    }
    params.append('domain', window.location.origin)
    params.append('question_type', this.props.questiontype)
    let p_Source = this.props.problem_source
    if (window.location.pathname.includes("answerboardassignment")) {
      p_Source = Problem_source.assignment
    }
    params.append('problem_source', p_Source)
    params.append('assignment_id', this.props.assignment_id);

    params.append('userId', this.props.studentId > 0 ? this.props.studentId : getCurrentUserId())
    this.primaryServer = "wss://52.14.176.237/"
    if (!this.props.teacher) {
      createAwwappBoard(params).then(res => {
        this.setState({ boardId: res.data.boardId, challengeMapId: res.data.challengeMapId ? res.data.challengeMapId : null })
        this.props.setBoardId(res.data.boardId)
        this.props.location.state = {
          ...this.props.location.state,
          challengeMapId: res.data.challengeMapId ? res.data.challengeMapId : null,
          boardId: res.data.boardId,
        }
        if (this.state.challengeType === CONTENT_TYPE.EQUATION) {
          if (this.props.isAnyProblem) {
            let elementcontainer = document.querySelector(".wirisAnyProblem")
            elementcontainer.setAttribute("style", "display:none");
            let equationcontainer_any = document.querySelector(".equationcontainer_any")
            equationcontainer_any.setAttribute("style", "display:none");
          }
          this.initializeBoard();
          this.setState({ loader: false })
        }
      }).catch(err => {
        console.error(err.message);
        this.setState({ loader: false })
      });
    }
    else {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.setState({ loader: false })
      if (searchParams.get('boardId')) {
        this.props.setBoardId(searchParams.get('boardId'))
        this.setState({
          boardId: searchParams.get('boardId'),
          teacherStudentId: searchParams.get('student'),
          challengeMapId: searchParams.get('challengeMapId')
        })
        this.firstOpen = false;
        if (this.state.challengeType === CONTENT_TYPE.EQUATION) {
          this.initializeBoard();
        }
      }
    }

    if (!this.props.isAnyProblem) {

      getUIConfigDetails({
        params: {
          'userId': this.props.studentId > 0 ? this.props.studentId : getCurrentUserId()
        }
      }).then(res => {
        if (res.status === 200) {
          if (JSON.stringify(res.data) !== "[]") {
            //console.log("here 11111")
            if (!res.data.isWhiteBoardVideoAutoPlayDone) {

              this.setState({
                showModalVideo: true,
                //     isWhiteBoardVideoAutoPlayDone: true,
                whiteBoardVideoDiv:
                  <div className='videoModalDiv'>
                    <div className='videoDiv'>
                      <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={getInstituteId() === Institute.Rice ? whiteBoardVideoRice : whiteBoardVideo} type="video/mp4" />
                      </video>
                    </div>
                    <div className='videoHeader'>
                      <span>Welcome to Interactive Problems <SchoolIcon className='schoolIcon' /> <AutoAwesomeIcon className='awesomeIcon' /></span>
                    </div>
                    <div className='videoDescription'>
                      <p>Watch out instructional video for insights on how to maximize your
                        learning or click on the <VideocamIcon className='helpIcon' /> help icon anytime you need assistance.</p>
                    </div>
                    <div className='videoDescription'>
                      <input type="checkbox" value={this.state.doNotShowChk} onChange={(e) => this.noShowCheckboxChange(e)}></input>&nbsp;&nbsp;
                      <label style={{ marginTop: '5px' }} class="addhomeworkcss">Don't show this again.</label>
                    </div>
                    <div className='modal-button text-center'>
                      <Button className="videoClosebtn" onClick={this.closeVideoModal}> CLOSE </Button>
                    </div>
                  </div>
              })
            }
          }
          else {
            //     console.log("here")
            this.setState({
              showModalVideo: true,
              //     isWhiteBoardVideoAutoPlayDone: true,
              whiteBoardVideoDiv:
                <div className='videoModalDiv'>
                  <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                      <source src={getInstituteId() === Institute.Rice ? whiteBoardVideoRice : whiteBoardVideo} type="video/mp4" />
                    </video>
                  </div>
                  <div className='videoHeader'>
                    <span>Welcome to Interactive Problems <SchoolIcon className='schoolIcon' /> <AutoAwesomeIcon className='awesomeIcon' /></span>
                  </div>
                  <div className='videoDescription'>
                    <p>Watch out instructional video for insights on how to maximize your
                      learning or click on the <VideocamIcon className='helpIcon' /> help icon anytime you need assistance.</p>
                  </div>
                  <div className='videoDescription'>
                    <input type="checkbox" value={this.state.doNotShowChk} onChange={(e) => this.noShowCheckboxChange(e)}></input>&nbsp;&nbsp;
                    <label style={{ marginTop: '5px' }} class="addhomeworkcss">Don't show this again.</label>
                  </div>
                  <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={this.closeVideoModal}> CLOSE </Button>
                  </div>
                </div>
            })
          }
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.askAIenabled !== this.lastAiTutorState) {
      this.lastAiTutorState = this.state.askAIenabled
    }
    if (prevProps !== this.props) {
      if (this.props.hasActiveConference) {
        this.resumeFetchWirisInterval();
        console.log('start')
      } else if (!this.props.hasActiveConference && !isNil(this.mathImageSyncTimer)) {
        this.clearFetchWirisInterval()
        console.log('clear')
      }
      if (this.state.askAIenabled !== this.props.askAITutorEnabled) {

        if (this.props.askAITutorEnabled) {
          this.left_Panel_Classes = "col-md-5 pb-2"
          this.right_Panel_Classes = "col-md-3";
          this.askAI_panel_Classes = "col-md-4";
          document.getElementById('root').scrollTo(0, document.body.scrollHeight);
        }
        else {
          this.left_Panel_Classes = showFullApp() ? "col-md-8 pb-2" : "col-md-12 pb-2";
          this.right_Panel_Classes = "col-md-4";
          this.askAI_panel_Classes = "col-md-0"
        }

        this.setState({ askAIenabled: this.props.askAITutorEnabled })
      }

      if (this.state.anyProblemStatement === '' && this.props.anyProblemStatement !== '') {
        this.setState({ anyProblemStatement: this.props.anyProblemStatement, isVisible: true });
        this.askAnyProblem('', this.props.anyProblemStatement);
      }


      if (this.props.askAIquestion !== "" && this.props.askAIquestion !== undefined && this.props.askAIquestion !== null) {
        this.setState({ askAIquestion: this.props.askAIquestion, askAIBgColor: this.props.askAIBgColor, equationClass: this.props.equationClass, equationClick: this.props.equationClick })
      }


    }
    if (this.props.isAnyProblem && !this.props.actionBtnDisabled) {
      let elementcontainer = document.querySelector(".wirisAnyProblem")
      elementcontainer.setAttribute("style", "display:block");

      let equationcontainer_any = document.querySelector(".equationcontainer_any")
      equationcontainer_any.setAttribute("style", "display:block");
    }
  }
  initializeBoard = () => {
    if (!this.isBoardInitialized) {
      window.editor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en' });
      window.editor.insertInto(document.getElementById('editorContainer'));
      if (this.state.challengeType === CONTENT_TYPE.EQUATION && !this.state.isAnyProblem) {
        if (this.props.hasActiveConference) {
          this.mathImageSyncTimer = setInterval(this.updateMathImages, 1000);
        } else {
          this.updateMathImages()
        }
        this.isBoardInitialized = true;
      }
    }
  };
  handleDoubleTapEvent = (event) => {
    var currentTime = new Date().getTime();
    var tapLength = currentTime - this.lastTap;
    let x = 0;
    let y = 0;
    clearTimeout(this.timeout);
    if (tapLength < 300 && tapLength > 0) {
      let coord = event.currentTarget.getBoundingClientRect();
      if (event.touches && event.touches[0]) {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } else if (event.originalEvent && event.originalEvent.changedTouches[0]) {
        x = event.originalEvent.changedTouches[0].clientX;
        y = event.originalEvent.changedTouches[0].clientY;
      } else if (event.clientX && event.clientY) {
        x = event.clientX;
        y = event.clientY;
      }
      this.setState({
        lastClickedPosition: {
          x: x - coord.left,
          y: y - (coord.top + 15)
        }
      })
      this.openWirisModal();
      event.preventDefault();
    } else {
      this.timeout = setTimeout(function () {

        clearTimeout(this.timeout);
      }, 500);
    }
    this.lastTap = currentTime;
  }
  setLastClickedPoint = (e) => {
    let coord = e.currentTarget.getBoundingClientRect();
    let container = document.getElementById('sketchpad');
    if (e.detail === 2) {
      this.setState({
        lastClickedPosition: {
          x: e.clientX - coord.left,
          y: e.clientY - (coord.top + 15)
        }
      })
      if (this.state.selectedToolbarItemId !== 'aww-toolbar-text') {
        this.openWirisModal();
      } else {
        this.addTextComponent(e.clientX - coord.left, e.clientY - (coord.top + 15))
      }

    }
  }

  handleEqIClosemodal = () => {
    this.setState({ showEqI: false })
  }

  handleWarningPopuClose = () => {
    this.setState({ showWarningModal: false })
    if (!this.props.isAnyProblem) {

      // getUIConfigDetails({
      //   params: {
      //     'userId': getCurrentUserId()
      //   }
      // }).then(res => {
      //   if (res.status === 200) {
      //     if (JSON.stringify(res.data) !== "[]") {
      //       if (!res.data.isWhiteBoardVideoAutoPlayDone) {
      //         this.setState({ isWhiteBoardVideoAutoPlayDone: true })
      //         this.handleHelpCenterClickEvent();
      //       }
      //     }
      //     else {
      //       this.handleHelpCenterClickEvent();
      //     }
      //   }
      // })
    }
  }
  setWarningPopup = () => {
    if (this.state.hasWarningPopup) {
      this.setState({ hasWarningPopup: false })
    }

  }
  addTextComponent = (x, y) => {
    const position = {
      x: x + 'px',
      y: y + 'px',
      text: "",
      width: 0,
      height: 0,
      id: this.state.textId
    }
    this.setState({ textId: this.state.textId + 1 });
    this.setState({ texts: [...this.state.texts, position] })
  }

  textComponentChangeHandler = (text, id) => {
    // let arr = [];
    // for (let i = 0; i < this.state.texts.length; i++) {
    //   let selectedText = this.state.texts[i];
    //   if (selectedText['id'] === id) {
    //     selectedText.text = e.target.value;
    //     selectedText.width = e.target.offsetWidth;
    //     selectedText.height = e.target.offsetHeight;
    //   }
    //   arr.push(selectedText);
    // }
    this.setState({ texts: text })
    this.props.getAnswerText(text)
    this.saveText()
  }

  saveText = (e, id) => {
    let itemsArr = []
    itemsArr.push({ content: this.state.texts, index: itemsArr.length, type: CONTENT_TYPE.TEXT })

    this.exportMathML(itemsArr)
  }
  removeText = (id) => {
    const arr = this.state.texts.filter((o) => o.id !== id);
    this.setState({ texts: [...arr] });
  }
  boardClickedHandler = (e) => {
    if (this.state.selectedToolbarItemId !== "aww-toolbar-select") {
      return;
    }
    let that = window.sketchpad;
    if (that.inputEventsDisabled) {
      return false;
    }
    e.preventDefault();
    that.mouseLB = true;
    var p = that.mainPos(e.clientX, e.clientY);
    this.state.selectedImageIndex = (Math.ceil(p.y / 30) > 0 && 0 < p.x) ? Math.ceil(p.y / 30) - 1 : null;
    // console.log(p, this.state.selectedImageIndex);

    if (this.state.selectedImageIndex != null && this.state.selectedImageIndex <= this.state.mathImage.length - 1) {
      if (this.state.mathImage[this.state.selectedImageIndex].op.width >= p.x) {
        this.setState({ selectedImageIndex: this.state.selectedImageIndex });
        window.editor.setMathML(this.state.mathImage[this.state.selectedImageIndex].mathML);
        this.openWirisModal(true)
        if (!this.state.challengeCompleted) {
          document.querySelector('#modal').style.display = 'block';
        }
      }
    }
  }

  editEquation = (index, eqindex) => {

    if (this.props.isAnyProblem) {
      this.state.equations.forEach(item => {
        ////debugger
        if (item.mathml === this.state.equations[eqindex].mathml) {
          ////debugger
          //  window.editor.setMathML(item.mathml);
          const domEditableElement = document.querySelector('.ck-editor__editable')
          const editorInstance = domEditableElement.ckeditorInstance;
          editorInstance.setData(item.mathml);
        }
      })

      this.setState({ isWirisHadEditMode: true })
    }
    else {
      this.state.mathImage.forEach((item, idx) => {
        if (item.op.opid.toString() === index) {
          this.setState({ selectedImageIndex: idx });
          let mathml = cloneDeep(item.mathML)
          window.editor.setMathML(mathml);
          //<math xmlns="http://www.w3.org/1998/Math/MathML"><mi>a</mi><mo>=</mo><mi>b</mi><mi>c</mi></math>
        }
      })
      this.setState({ isWirisHadEditMode: true })
      this.openWirisModal(true);

    }
    if (!showFullApp()) {
      this.props.handleHideShowEquation()
    }
    this.clearEquationBorder();
  }

  closedeleteconfirmodelsingle = () => {
    this.setState({ deleteconfirmodelopensingle: false })
  }
  handledeletesingleequations = () => {

    this.clearFetchWirisInterval();
    let tempArr = [];
    let idx = 0;
    if (this.state.deleteequationindex !== null) {
      this.state.equations.forEach((item) => {
        if (item.opid.toString() !== this.state.deleteequationindex) {
          if (item.type === 'eq') {
            tempArr.push({ content: item.mathml, index: idx, type: CONTENT_TYPE.EQUATION, isFixed: item.isFixed })
          } else {
            tempArr.push({ content: item.text, index: idx, type: CONTENT_TYPE.TEXT, isFixed: item.isFixed })
          }
          idx++;
        }
      })
      // this.setState({ equations: tempArr });

      this.exportMathML(tempArr);
      this.clearEquationBorder();
      this.setState({ deleteconfirmodelopensingle: false })
    }

  }

  deleteEquation = (index) => {
    this.setState({ deleteequationindex: index }, () => {
      this.setState({ deleteconfirmodelopensingle: true })
    })

  }

  updateMathImages = () => {
    //debugger
    //this.setState({ loader: true })
    const reqData = {
      params: {
        board: this.state.boardId,
      }
    }

    fetchWiris(reqData).then(res => {
      //debugger
      this.onBoardItemsRecieved(res);
      this.setState({ loader: false })
    }).catch(err => {
      console.error(err.message);
      // this.props.history.goBack();
    })
  }

  onBoardItemsRecieved = (res) => {
    //debugger

    this.setState({ mathImage: res.data['data'].map(item => JSON.parse(item)) });
    // //debugger
    if (this.state.challengeType === CONTENT_TYPE.EQUATION) {
      let tempMath = res.data['data'].map(item => JSON.parse(item));
      let tempArr = [];
      const container = document.getElementById('sketchpad');
      let index = 0;
      tempMath.forEach((item) => {
        // //debugger
        let mathItem = this.unicodeToChar(item.mathML)
        let opdata = item.op
        debugger
        tempArr.push({
          mathml: mathItem,
          x: (item.op.x * container.clientWidth) + "px",
          y: (item.op.y * container.clientHeight) + "px",
          opid: item.op.opid,
          type: item.type,
          text: item.text,
          class: '',
          isFixed: item.isFixed,
          inputLatex: item.inputLatex
        })

      })
      if (tempArr.length > 0) {
        this.equationCounter = (maxBy(tempArr, function (o) { return o.opid; }))['opid'];
        this.equationCounter++;
      }

      this.setState({ equations: tempArr });

    } else {
      if (this.state.mathImage.length > 0) {
        this.setState({ savedText: this.state.mathImage[0]['text'] })
      }
    }
  }

  highlightEquationsOnEvaluate = () => {
    let tempMath = cloneDeep(this.state.equations);
    let index = 0;
    tempMath.forEach((item) => {
      let highlightItem = [];
      if (item.type === CONTENT_TYPE.EQUATION) {
        highlightItem = filter(this.state.equationHighlighter, ['line', index]);
        index++;
      }

      let color = ''
      if (highlightItem.length > 0) {
        color = highlightItem[0]['class']
      }
      item.class = color;
    })

    this.setState({ equations: tempMath });
  }
  /*
   showToastMsgANDSaveFlag = (mode, data) => {
     toast('Please streamline your steps just the way you would when showing it to tutor. This also helps reduce AI tutors response time as you check your work!', {
       type: toast.TYPE.INFO,
       title: "Is your solution streamlined ?",
       position: toast.POSITION.BOTTOM_RIGHT,
       autoClose: true
     })
     let reqData
     if (mode === "save") {
       reqData = {
         userId: getCurrentUserId(),
         isShowStreamlineMsg: true,
         showPracticeProblemsTour: false,
         showDashboardTour: false,
         showCurriculumContentTour: false
       }
     }
     else {
       reqData = {
         userId: getCurrentUserId(),
         isShowStreamlineMsg: true,
         showPracticeProblemsTour: data.showPracticeProblemsTour,
         showDashboardTour: data.showDashboardTour,
         showCurriculumContentTour: data.showCurriculumContentTour
 
       }
     }
 
     saveUIConfigDetails(reqData).then(res => {
       if (res.status === 200) {
         console.log("saved successfully")
         // flag stored in DB successfully !
       }
     })
   }
 */

  unicodeToChar = (text) => {
    return text.replace(/\\u[\dA-F]{4}/gi,
      function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
      });
  }
  setEquationBorder = (response) => {
    this.hasEquationWithBorder = false;
    if (!isNil(response)) {
      let tempColl = [];
      response.result.forEach((item) => {
        let obj = { line: item.line_match - 1, class: '' }
        if (item.status === 'incorrect') {
          obj.class = 'errorContainer';
        } else if (item.status === 'unrecognized') {
          obj.class = item.eq_status === 'correct' ? 'correctContainer' : 'errorContainer';
        } else {
          obj.class = 'correctContainer'
        }
        this.hasEquationWithBorder = true;
        tempColl.push(obj)
      });

      if (!isNil(response.hint_obj)) {
        let obj = { line: response.hint_obj.line_match - 1, class: 'errorContainer' };
        tempColl.push(obj)
      }
      this.setState({ equationHighlighter: tempColl }, () => {
        this.highlightEquationsOnEvaluate()
      });
    } else {
      this.setState({ equationHighlighter: [] });
    }
  }


  clearEquationBorder = () => {
    this.setState({ equationHighlighter: [] });
  }
  componentWillUnmount() {
    this.clearFetchWirisInterval();
    clearInterval(this.wsInterval);
    clearInterval(this.mathImageSyncTimer);
  }

  clearFetchWirisInterval = () => {
    if (this.mathImageSyncTimer) {
      clearInterval(this.mathImageSyncTimer)
    }
  }

  resumeFetchWirisInterval = () => {
    if (this.props.hasActiveConference) {
      clearInterval(this.mathImageSyncTimer);
      this.mathImageSyncTimer = setInterval(this.updateMathImages, 1000);
    }

  }
  clearEditor = () => {
    window.editor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>');
  }

  openWirisModal = (hasEditMode = false) => {
    if (!this.firstOpen && !hasEditMode) {
      this.clearEditor();
      this.firstOpen = true;
      window.editor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>');
    }
    // if (!this.state.challengeCompleted) 
    // {
    // 
    document.getElementById("modal").style.display = "block";
    window.editor.focus();
  };

  closeWirisModal = () => {
    if (!this.props.isAnyProblem) {
      document.getElementById('modal').style.display = 'none';
    }
    this.clearEditor();
    this.setState({ selectedImageIndex: null, isWirisHadEditMode: false })
  }

  showhidewirispanel = () => {
    if (this.state.showhidebuttontext === "Show Math Toolbar") {
      this.setState({ showhidebuttontext: "Hide Math Toolbar" })
      let data1 = document.querySelector(".wrs_toolbar")
      data1.setAttribute("style", "display:block");
    }
    else {
      this.setState({ showhidebuttontext: "Show Math Toolbar" })
      let data1 = document.querySelector(".wrs_toolbar")
      data1.setAttribute("style", "display:none");
    }

  }
  uploadImage = (mathImageObj) => {
    let p = { x: mathImageObj.x, y: mathImageObj.y };
    let e = { x: p.x, y: p.y + 25 }
    let imageId = window.ToolEquation.prototype.onImagehostReady(mathImageObj.imageUrl, p.x, p.y, e.x, e.y, false, window.sketchpad);
    console.log(imageId)
    window.sketchpad.saveSketchpad()
    window.sketchpad.refreshWindow()
    return imageId;
  };

  getKnowledgeGraph = () => {
    fetchKnowledgeGraph({
      params: {
        'challengeId': this.state.challengeId
      }
    }).then(async res => {
      let modalBody = [];
      const ui_json = res.data.ui_json;
      modalBody = (
        <div>
          <div style={{ height: '500px' }}>
            <Flow readOnly={true} uiJson={ui_json} hasCheckMyWOrk={true} isKnowledgeGraph={true}></Flow>
          </div>

        </div>
      )
      this.props.setKnowledgeGraphContent(modalBody, 'Knowledge Graph')
    })
  }
  checkifcorrectversionexists = (correct_version_exists, line_match_number, current_line) => {
    if (correct_version_exists) {
      //return <div className='extra_instructions'>(Note: You have already entered the correct version of this step in line # {line_match_number} 🤔)</div>
      if (line_match_number > current_line) {
        return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it with <label className="lineNumber">line #{line_match_number}</label>. </div>
        //Note: interim incorrect step. You have already entered the correct version of this step in line # {line_match_number}</div>
        //Great work on noticing the mistake in line 1 and correcting it with the appropriate equation in line 2! 
      }
      else if (line_match_number == -1) {
        return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it in your mind as you have progressed to the more advanced step further in your solution. </div>
      }
      else {
        return <div className='extra_instructions'>Note: It seems like you've retraced your <label className="lineNumber">line #{current_line}</label>, but you actually had it right the first time in your <label className="lineNumber">line #{line_match_number}</label>. Stick with your initial approach.</div>
      }
      //Let’s assume you know the correct step, since you used the correct version of this step in your line # {line_match_number} in order to arrive at this line 🤔
      // I see that you've attempted this step again, but it looks like you made an error this time. Remember, you already got this step correct earlier. Let's move on to the next step and build upon your previous success!
      // You've returned to step 5, but your initial attempt was actually correct. Trust your instincts and let's keep moving forward to step 6. Keep up the good work!
    }
    return null
  }

  equationTourPopUp = () => {
    //console.log("yes here")
    //  let modalBody = []
    //  modalBody.push((

    //));

    //this.props.setSidebarContent(modalBody);
  }

  checkEquationBoard = () => {
    if (this.state.equations.length === 0) {
      toast('Please enter some solution/equations to calculate grade...!', {
        type: toast.TYPE.INFO,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: true
      })

      return false;
    }
    else {
      return true;
    }
  }

  submitEquation = async (showPopup = true) => {
    // Added as per Khyati's request - to show msg when equation board has 5 or more equations
    if (this.state.equations.length === 0) {
      this.setState({ isblankEquationModal: true })
    }
    else {
      if (this.state.equations !== "[]") {
        let rows = this.state.equations.find((e) => e.opid > 4)
        if (rows !== undefined) {
          toast('Please streamline your steps to speed up AI tutor assistance, just the way you do it for tutors!', {
            type: toast.TYPE.INFO,
            title: "Is your solution streamlined ?",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: true
          })
        }
      }

      if (!this.props.isAnyProblem) {
        if (this.state.challengeMapId) {
          let modalTitle = null;
          let modalBody = [];
          let assumed_content_list = [];
          let stepNo = 0;
          let line_match = 0;
          let isLastStepCorrect = false;
          let correct_version_exists = false;
          let correct_version_line_no = -1;
          let isChallengeCompleted = false;
          let is_partially_complete = false;
          let headerContent = null, subHeaderContent = null, footerContent = null;
          let noMatch = false;
          let correctEq = [];
          let incorrectHints = [];
          let knowledgeGraphUiJson = null;
          let ui_json
          let substitution_mapkey = null
          let substitution_mapvalue = null
          let finalsubstitutionslist = ""
          if (showPopup) {
            modalBody.push((
              <Aux>
                <div className="progress-bar-container d-block px-2 w-100 text-center">
                  <div className="px-5 mx-5">
                    <img src={ImganalysingWork} alt="" className="img-fluid px-5 text-center" />
                  </div>
                  <div className=" py-3">
                    <p className="">We are analyzing your work <Spinner animation="grow" style={{ height: "5px", width: "5px" }} /> <Spinner animation="grow" style={{ height: "5px", width: "5px", animationDelay: ".2s" }} /> <Spinner animation="grow" style={{ height: "5px", width: "5px", animationDelay: ".4s" }} /></p>
                  </div>

                </div>
              </Aux>
            ));
            this.props.setSidebarContent(modalBody)
          }

          console.log("canvas screenshot code start here..........")
          var canvas = document.getElementById('1_canvas');
          console.log("canvas element.......", canvas)
          var screenshotBase64 = '';
          // Commented by Jesha on 20th Sept 2024 as image parameter is not used anywhere in API
          // if (!isNil(canvas)) {
          //   screenshotBase64 = canvas.toDataURL();
          // }
          console.log("canvas screenshot code end here.......", canvas)
          checkEquation({
            'board': this.state.boardId,
            'userId': this.props.teacher ? this.state.teacherStudentId : getCurrentUserId(),
            'challengeMapId': this.state.challengeMapId,
            'hintUsed': this.props.location.state.hintUsed ? 1 : 0,
            'is_finalsubmission': 0,
            'problem_source': this.props.problem_source,
            'image': screenshotBase64,
            'assignment_id': this.props.assignment_id !== undefined && this.props.assignment_id !== null && this.props.assignment_id !== "" ? this.props.assignment_id : null

          }).then(async res => {
            console.log("equation api run successfully.......")

            // if (res.data !== undefined && res.data !== null) {
            //   if (!isNil(res.data.result)) {
            //     var chfeedbackdata = {
            //       "userId": this.props.teacher ? this.state.teacherStudentId : getCurrentUserId(),
            //       "challenge_id": this.props.challengeId,
            //       "checkmyworkresult": JSON.stringify(res.data.result)
            //     }
            //     checkmyworkfeedbackupdate(chfeedbackdata).then(async res => {
            //     })

            //   }
            // }


            const html = document.getElementsByTagName("html")[0];
            const body = document.getElementsByTagName("body")[0];
            let htmlWidth = html.clientWidth;
            let bodyWidth = body.clientWidth;

            const newWidth = this.sk_ref.current.scrollWidth - this.sk_ref.current.clientWidth;

            if (newWidth > this.sk_ref.current.clientWidth) {
              htmlWidth += newWidth;
              bodyWidth += newWidth;
            }

            html.style.width = htmlWidth + "px";
            body.style.width = bodyWidth + "px";

            const canvas = await html2canvas(this.sk_ref.current);
            const image = canvas.toDataURL("image/png", 1.0);
            var data = {
              "userId": this.props.teacher ? this.state.teacherStudentId : getCurrentUserId(),
              "challenge_id": this.props.challengeId,
              "imagestring": image,
              "boardId": this.state.boardId,
              'problem_source': this.props.problem_source
            }
            Checkmywork_capture_image(data).then(async res => {
            })

            if (showPopup) {
              if (!isEmpty(res.data) && !isEmpty(res.data)) {
                modalBody.pop();

                ui_json = res.data.ui_json;

                if (!isNil(res.data.substitution_map)) {
                  finalsubstitutionslist = obtainSubstitution(res.data.substitution_map)
                }


                this.setEquationBorder(res.data)
                if (ui_json === '[]' && !isNil(res.data.result)) {
                  res.data.result.forEach((item) => {
                    insertStudentError(this.state.challengeId, item.inputLatex).then(res => {

                      console.log('insertStudentError success');
                    })
                  })
                }



                let assumed_content = null
                let propagation = 0
                let feedback_imageurl = ""

                if (res.data.feedbackimage !== undefined && res.data.feedbackimage !== null && res.data.feedbackimage !== "") {
                  feedback_imageurl = imgURL + "/media/" + res.data.feedbackimage
                }

                let feedback_img = (
                  <Aux>
                    <p className="pl-3">
                      {"Consult the diagram below for a clearer picture of the problem's context and to track your progress effectively."}
                      <br></br><br></br>
                      <div className="mb-0" class="">
                        <img src={feedback_imageurl} style={{ maxHeight: "250px" }} />
                      </div></p>
                  </Aux>
                )
                if (res.data.feedbackimage !== undefined && res.data.feedbackimage !== null && res.data.feedbackimage !== "") {
                  modalBody.push(feedback_img);
                }
                for (let result of res.data.result) {
                  line_match = result.line_match;



                  let voteup = false
                  let votedown = false

                  if (this.state.linematch === line_match) {
                    if (this.state.thumbsupvalue === 1) {
                      voteup = true
                      votedown = false
                    }
                    else if (this.state.thumbsupvalue === 2) {
                      voteup = false
                      votedown = true
                    }
                    else {
                      voteup = false
                      votedown = false
                    }
                  }
                  stepNo += 1
                  isLastStepCorrect = result.status === 'correct' ? true : false
                  correct_version_exists = result.correct_version_exists ? result.correct_version_exists : false
                  if (isLastStepCorrect === false)
                    correct_version_exists = result.correct_version_exists ? result.correct_version_exists : false
                  if (correct_version_exists) {
                    correct_version_line_no = result.correct_version_line_no;
                  }

                 

                  if (result.status === 'correct') {
                    
                    const assumed_step_feedback = ["Great job advancing to this step."]
                    let ind_assumed_step_feedback = Math.floor(Math.random() * 1);
                    const correct_step_feedback =
                      ["Well done!", "You've nailed it.", "Spot on.", "Fantastic."];
                    let ind_correct_step_feedback = Math.floor(Math.random() * 4);
                    const correct_step_feedback_ending =
                      ["", ""];
                    let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);

                    modalTitle = 'Correct'
                    // if (result.new) {
                    correctEq.push(result.eq_correct);
                    // }

                    // const latexReqData = {
                    //   params: {
                    //     format: 'svg',
                    //     fontSize: '18px',
                    //     color: Number.isInteger(line_match) ? 'green' : 'black',
                    //     latex: result.inputLatex
                    //   }
                    // }
                    //for (let i=0; i<2;i++){
                    let equationImg = undefined
                    // const response = await generateSVGFromMathML(latexReqData)
                    // if (response.status === 200) {
                    //   const svg = response.data;
                    //   equationImg = svg2img(svg)
                    // } else {
                    //   console.error('Unable to convert latex to svg');
                    // }
                    if (result.propagation) {
                      propagation = 1;
                    }
                    if (!Number.isInteger(line_match)) {
                      assumed_content = (
                        <div>
                          <div className='mb-3 d-flex align-items-center'>
                            {equationImg ?
                              <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                              :
                              // <MathJax.Context
                              //   input='tex'
                              //   onError={(MathJax, error) => {
                              //     console.warn(error);
                              //     console.log("Encountered a MathJax error, re-attempting a typeset!");
                              //     MathJax.Hub.Queue(
                              //       MathJax.Hub.Typeset()
                              //     );
                              //   }}
                              //   script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML">
                              //   <MathJax.Node>{result.inputLatex}</MathJax.Node>
                              // </MathJax.Context>
                              <MathJaxRenderer math={(!isNil(result.mathml) && !isEmpty(result.mathml)) ? result.mathml : result.inputLatex}></MathJaxRenderer>
                            }
                          </div>
                        </div>
                      )
                      assumed_content_list.push(assumed_content)
                    }

                    let content = (
                      <Aux>
                        {/* <p>{parse(result.explanation)}</p> */}
                        {<hr></hr>}

                        <p className="mb-0">
                          {
                            <div className="green">
                              <CheckCircleIcon style={{ fill: "green" }} />
                              {<span><span>{correct_step_feedback[ind_correct_step_feedback]} </span>  <span>{correct_step_feedback_ending[ind_correct_step_feedback_ending]}</span></span>}
                            </div>
                          }
                          <div className='mb-3 d-flex align-items-center'>
                            {/* <div className='mr-2'>Step : </div> */}
                            <div>
                              {equationImg ?
                                <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                :

                                <MathJaxRenderer math={(!isNil(result.mathml) && !isEmpty(result.mathml)) ? result.mathml : result.inputLatex}></MathJaxRenderer>
                              }
                              <br />
                              <MathJaxRenderer math={result.explanation}></MathJaxRenderer>
                            </div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div style={{ cursor: "pointer" }}>

                                <ThumbUpIcon id={`${result.node_id}correctup`} fontSize="small" color="action"
                                  onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsup, result.inputLatex, result.node_id, correct_step_feedback[ind_correct_step_feedback]
                                    , result.line_match, `${result.node_id}correctup`, `${result.node_id}correctdown`)} />

                              </div>
                              <div style={{ marginLeft: "2px", marginRight: "5px", cursor: "pointer", marginTop: "1px" }}>
                                <ThumbDownIcon id={`${result.node_id}correctdown`} fontSize="small" color="action"
                                  onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsdown, result.inputLatex, result.node_id, correct_step_feedback[ind_correct_step_feedback],
                                    result.line_match, `${result.node_id}correctdown`, `${result.node_id}correctup`)}></ThumbDownIcon>

                              </div>
                            </div>

                          </div>
                          <div className="mb-1 mt-4" style={{ display: "flex", justifyContent: "space-between" }}>
                            {showFullApp() ? <div> {`For more help,  `}
                              <button className='linkbutton' tooltip="Get further explanations from the interactive AI tutor."
                                onClick={(e) => this.handleaskAItutor(e, result.line_match, result.inputLatex, "correct")}>
                                <img style={{ height: '24px', width: '24px', color: '#4252AF', marginRight: '6px', marginLeft: "5px" }} alt="Get further explanations from the interactive AI tutor." src={AskAITutIcon} width="20px" height="20px" />Ask AI Tutor</button></div> : null}
                          </div>
                        </p>
                        {Number.isInteger(line_match) && assumed_content_list.length > 1 ?
                          <div>
                            <div className="grey">
                              {assumed_step_feedback[ind_assumed_step_feedback]}
                              {assumed_content_list}
                            </div>
                          </div>
                          : null
                        }
                      </Aux>
                    );
                    content = !Number.isInteger(line_match) ? null : content;
                    assumed_content_list = Number.isInteger(line_match) ? [] : assumed_content_list
                    propagation = Number.isInteger(line_match) ? 0 : propagation
                    headerContent = content;

                    // if (!Number.isInteger(line_match)){
                    //   modalBody.push(assumed_content);
                    // }
                    // else{
                    modalBody.push(content);
                    //}

                  }
                  else if (result.status === 'unrecognized') {
                    this.props.checkanyequationincorrectHandler();
                    const incorrect_step_feedback =
                      ["There is a small mistake in ", "There's a little error in "];
                    let ind_incorrect_step_feedback = Math.floor(Math.random() * 2);
                    const incorrect_step_feedback_ending =
                      [".", ".", "."];
                    let ind_incorrect_step_feedback_ending = Math.floor(Math.random() * 3);


                    const correct_step_feedback =
                      ["Well done!", "You've nailed it.", "Spot on.", "Fantastic."]
                    let ind_correct_step_feedback = Math.floor(Math.random() * 4);
                    const correct_step_feedback_ending =
                      ["", ""];
                    let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);
                    // result.inputLatex = result.inputLatex.replace('\\stackrel{^}', '\\stackrel{\\text{^}}')
                    result.inputLatex = result.inputLatex.replace(/\\stackrel{\^}/g, '\\stackrel{\\text{^}}');
                    headerContent = result.inputLatex ?
                      <> <hr></hr>
                        <div className='mb-2 d-flex align-items-center'>
                          <p className={result.eq_status === 'correct' ? 'correctRecognized' : 'incorrectRecognized'}>
                            <span>
                              {
                                result.eq_status === "correct" ?

                                  <CheckCircleIcon style={{ fill: "green" }} />

                                  : <CancelIcon style={{ fill: "red" }} />
                              }
                              {
                                result.eq_status === 'correct' ?
                                  correct_step_feedback[ind_correct_step_feedback] :
                                  incorrect_step_feedback[ind_incorrect_step_feedback]
                              }
                            </span>
                            <label className="lineNumber"> line #{result.line_match}</label>
                            <span>
                              {
                                result.eq_status === 'correct' ?
                                  correct_step_feedback_ending[ind_correct_step_feedback_ending] :
                                  incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]
                              }
                            </span>

                            <br /><span className="aiAnswerNote">Note: Feedback under review to ensure its quality.</span></p>
                        </div>
                        {/* <MathJax.Context
                        onError={(MathJax, error) => {
                          console.warn(error);
                          console.log("Encountered a MathJax error, re-attempting a typeset!");
                          MathJax.Hub.Queue(
                            MathJax.Hub.Typeset()
                          );
                        }}
                        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML"
                        input='tex'>

                        <MathJax.Node>{result.inputLatex}</MathJax.Node>
                      </MathJax.Context> */}
                        <MathJaxRenderer math={(!isNil(result.mathml) && !isEmpty(result.mathml)) ? result.mathml : result.inputLatex}></MathJaxRenderer>

                      </>

                      : null
                    footerContent = <p className={result.eq_status === 'correct' ? 'correctRecognized' : 'incorrectRecognized'}>
                      <MathJaxRenderer math={formatAnyProblemAPIResponse(result.explanation)}></MathJaxRenderer></p>
                    modalBody.push((
                      <Aux>
                        {headerContent}
                        {footerContent}
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                          <div style={{ cursor: "pointer" }}>
                            <ThumbUpIcon id={`${result.node_id}ueup`} fontSize="small" color="action"
                              onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsup, result.inputLatex, result.node_id, incorrect_step_feedback[ind_incorrect_step_feedback]
                                , result.line_match, `${result.node_id}ueup`, `${result.node_id}uedown`)} />

                          </div>
                          <div style={{ marginLeft: "2px", marginRight: "5px", cursor: "pointer", marginTop: "1px" }}>
                            <ThumbDownIcon id={`${result.node_id}uedown`} fontSize="small" color="action"
                              onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsdown, result.inputLatex, result.node_id, incorrect_step_feedback[ind_incorrect_step_feedback]
                                , result.line_match, `${result.node_id}uedown`, `${result.node_id}ueup`)}></ThumbDownIcon>
                          </div>
                        </div>
                        <div className="mb-1 mt-4" style={{ display: "flex", justifyContent: "space-between" }}>
                          {showFullApp() ? <div> {`For more help,  `}
                            <button className='linkbutton' tooltip="Get further explanations from the interactive AI tutor."
                              onClick={(e) => this.handleaskAItutor(e, result.line_match, result.inputLatex, "unrecognized")}>
                              <img style={{ height: '24px', width: '24px', color: '#4252AF', marginRight: '6px', marginLeft: "5px" }} alt="Get further explanations from the interactive AI tutor." src={AskAITutIcon} width="20px" height="20px" />Ask AI Tutor</button></div> : null}
                        </div>
                      </Aux>
                    ));
                  }
                  else if (result.status === 'incorrect') {
                    debugger;
                    this.props.checkanyequationincorrectHandler();
                    const assumed_step_feedback_incorrect = ["It's evident you've got a good handle on the initial steps. Let's confirm your thought process which led you to the above step.", "Looks like you are doing great with interim steps as you are trying to attempt the above advanced step. Let's confirm your thought process which led you to the above step.", "Your ability to attempt this advanced step shows mastery of the interim steps. Let's review your approach once. Keep up the enthusiasm."]
                    let ind_assumed_step_feedback_incorrect = Math.floor(Math.random() * 3);
                    const assumed_step_propagation_feedback = ["It seems that the following error, in an interim step that you did in your mind, is impacting your result."]
                    // ["Nice effort in attempting this advanced step.  However, it seems that the following error, in an interim step that you did in your mind, is impacting your result.  Let's pinpoint that error to help our understanding."]
                    const incorrect_step_feedback =
                      ["There is a small mistake in", "There's a little error in"];
                    let ind_incorrect_step_feedback = Math.floor(Math.random() * 2);
                    const incorrect_step_feedback_ending =
                      [".", ".", "."];
                    let ind_incorrect_step_feedback_ending = Math.floor(Math.random() * 3);
                    let content = null
                    let contentdatafinal = []

                    // let remedial_text_len = 0
                    // let remedial_source_display_name_len = 0
                    // let remedial_text2_len = 0


                    // remedial_text_len = result.remedial_text.length
                    // remedial_text2_len = result.remedial_text2.length

                    // remedial_source_display_name_len = result.remedial_source_display_name.length

                    // //debugger
                    // var max = Math.max(remedial_text_len, remedial_text2_len, remedial_source_display_name_len);
                    var feedbackthumbsup = ""

                    if (result.remedial_text) {
                      for (let i = 0; i <= result.remedial_text.length; i++) {
                        let data = result.remedial_text[i]
                        let data1 = result.remedial_text2[i]
                        let data3 = result.remedial_source[i]
                        let data4 = result.remedial_source_display_name[i]
                        if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                          data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                          data = data.replace(".,", ".")
                        }
                        if (data !== undefined) {
                          if (feedbackthumbsup !== "") {
                            feedbackthumbsup = feedbackthumbsup + "##" + data.replace(/(<([^>]+)>)/ig, '')
                          }
                          else {
                            feedbackthumbsup = data.replace(/(<([^>]+)>)/ig, '')
                          }
                        }

                        if (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '') {
                          data1 = data1.toString().replace(/^,/, '').replace(/,\s*$/, "");
                          data1 = data1.replace(".,", ".")
                        }
                        if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                          data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                          data3 = data3.replace(".,", ".")
                        }
                        if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                          data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                          data4 = data4.replace(".,", ".")
                        }

                        incorrectHints.push({
                          remedial: true,
                          remedial_text: result.remedial_text[i],
                          remedial_text2: result.remedial_text2[i],
                          remedial_source: result.remedial_source[i]
                        })
                        let contentdata = (
                          <Aux>
                            {/* <hr className="horizontalRuler" /> */}
                            {
                              (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                <div className="mb-1 mt-3 rdTextcss" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                : null
                            }
                            {
                              (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '' && data1 !== ",") ?
                                <div className="mb-1 rdTextcss" dangerouslySetInnerHTML={{ __html: data1.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                : null
                            }
                            {
                              (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                <a href={data3}
                                  target='_blank' rel="noopener noreferrer">
                                  <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                : null
                            }

                          </Aux>
                        );
                        contentdatafinal.push(contentdata)
                      }
                      // content = (
                      //   <Aux>
                      //     {(result.remedial_text && result.remedial_text != null) ? <p dangerouslySetInnerHTML={{ __html: result.remedial_text }}></p> : null}
                      //     {(result.remedial_text2 && result.remedial_text2 != null) ? <p dangerouslySetInnerHTML={{ __html: result.remedial_text2 }}></p> : null}
                      //     {(result.remedial_source && result.remedial_source != "null,undefined") ? <a href={result.remedial_source} target='_blank' rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: result.remedial_source_display_name ? result.remedial_source_display_name : result.remedial_source }}></p></a> : null}
                      //   </Aux>
                      // );
                    } else if (result.hint_remedial_text) {
                      let data3 = result.hint_remedial_text
                      if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                        data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                      }
                      feedbackthumbsup = data3
                      incorrectHints.push({
                        hint: true,
                        hint_remedial_text: data3
                      })
                      content = (
                        <Aux>
                          {data3 ? <div>{data3}</div> : null}
                        </Aux>
                      );
                    } else {
                      if (result.symbolsDiff) {
                        feedbackthumbsup = "Equations you entered don’t match expected steps."
                        if (result.symbolsDiff.length > 0) {
                          content = <>
                            {content}
                            <p className="mb-1 mt-3">Equations you entered don’t match expected steps.</p>
                            <p className="mb-1 mt-3">Unexpected Symbols or Subscripts: <em>{result.symbolsDiff}</em></p>
                            <p className="mb-2">Expected Symbols or Subscripts: <em>{result.expectedSymbols}</em></p>
                            <a href='/docs/Ref_Symbols_and_Equations_AP_1999_C_Fr.pdf' target='_blank' rel="noopener noreferrer"><p>Physics Symbols and Equations Reference Sheet</p></a>
                          </>
                        }
                      }
                      // alert("here")
                      // show dialog for video conf./hint
                      const countIncorrect = res.data.result.filter(item => item.status === "incorrect").length;
                      if (countIncorrect > 2) {
                        setTimeout(() => { this.props.showHintAndConferenceDialog(false) }, 1000)
                      }
                    }

                    const latexReqData = {
                      params: {
                        format: 'svg',
                        fontSize: '18px',
                        color: 'red',
                        latex: result.inputLatex
                      }
                    }
                    let equationImg = undefined
                    // const response = await generateSVGFromMathML(latexReqData)
                    // if (response.status === 200) {
                    //   const svg = response.data;
                    //   equationImg = svg2img(svg)
                    // } else {
                    //   console.error('Unable to convert latex to svg');
                    // }

                    subHeaderContent = !result.no_match ? <>
                      {correctEq.length === 0 ?
                        <p className='sidebar-heading mb-2'>You made this mistake:</p>
                        :
                        <p className='sidebar-heading mb-2'><span className='text-dark'>Seems like you understood this.</span><br /> Then you made this mistake:</p>}
                    </> : null

                    let lastElement3 = this.checkifcorrectversionexists(correct_version_exists, correct_version_line_no, line_match)
                    headerContent = <>
                      {<hr></hr>}
                      {result.no_match ?
                        <>
                          {!isNil(result.valid) && !result.valid ?
                            <p>Your line {result.line_match} is not anything I was expecting. Please correct if you mean to enter an equation.</p>
                            : <p>Line #{result.line_match}:
                              <br></br><br></br>
                              {result.explanation}
                            </p>
                          }
                          <div>
                            <br />
                            {''}
                            {equationImg ?
                              <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                              :
                              // <MathJax.Context
                              //   onError={(MathJax, error) => {
                              //     console.warn(error);
                              //     console.log("Encountered a MathJax error, re-attempting a typeset!");
                              //     MathJax.Hub.Queue(
                              //       MathJax.Hub.Typeset()
                              //     );
                              //   }}
                              //   script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML"
                              //   input='tex'>
                              //   <MathJax.Node>{result.inputLatex}</MathJax.Node>
                              // </MathJax.Context>
                              <MathJaxRenderer math={(!isNil(result.mathml) && !isEmpty(result.mathml)) ? result.mathml : result.inputLatex}></MathJaxRenderer>

                            }
                          </div>
                          {!isNil(result.hint) && result.hint.length > 0 ?
                            <>
                              <p>Hint: <span dangerouslySetInnerHTML={{ __html: result.hint ? result.hint : '' }}></span></p>
                              <a className='mt-2' href={res.data.hint_remedial_source} target='_blank' rel="noopener noreferrer" >{res.data.hint_remedial_resource_display_name ? res.data.hint_remedial_resource_display_name : res.data.hint_remedial_source}</a>
                            </>
                            : null}
                        </>
                        :
                        <>
                          {/* <p><CancelIcon style={{ fill: "red" }} /> {' Your line #' + line_match + ' is incorrect.'}{lastElement3}</p> */}

                          {/* <div className='mb-2 d-flex align-items-center'> */}
                          {lastElement3}
                          <div className="red">
                            {/* <CancelIcon style={{ fill: "red" }} /> {incorrect_step_feedback[ind_incorrect_step_feedback] + 'in line # ' + line_match + ' ' +incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]}                   */}
                            {correct_version_exists ? null : <> <CancelIcon style={{ fill: "red" }} /> <span><span>{incorrect_step_feedback[ind_incorrect_step_feedback]} </span><label className="lineNumber"> line #{line_match}</label><span>{incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]}</span></span> </>}
                            <div>
                              <br />
                              {'Incorrect Step: '}
                              {equationImg ?
                                <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                :
                                // <MathJax.Context
                                //   onError={(MathJax, error) => {
                                //     console.warn(error);
                                //     console.log("Encountered a MathJax error, re-attempting a typeset!");
                                //     MathJax.Hub.Queue(
                                //       MathJax.Hub.Typeset()
                                //     );
                                //   }}
                                //   script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML"
                                //   input='tex'>
                                //   <MathJax.Node>{result.inputLatex}</MathJax.Node>
                                // </MathJax.Context>
                                <MathJaxRenderer math={(!isNil(result.mathml) && !isEmpty(result.mathml)) ? result.mathml : result.inputLatex}></MathJaxRenderer>
                              }
                            </div>
                          </div>
                        </>
                      }
                    </>
                    noMatch = result.no_match ? true : false;
                    // footerContent = content
                    footerContent = <>
                      <div className="red">
                        {contentdatafinal}

                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "5px" }}>
                          <div style={{ cursor: "pointer" }}>
                            <ThumbUpIcon id={`${result.node_id}incorrectup`} fontSize="small" color="action"
                              onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsup, result.inputLatex, result.node_id, feedbackthumbsup
                                , result.line_match, `${result.node_id}incorrectup`, `${result.node_id}incorrectdown`)} />
                          </div>
                          <div style={{ marginLeft: "2px", marginRight: "5px", cursor: "pointer", marginTop: "1px" }}>
                            <ThumbDownIcon id={`${result.node_id}incorrectdown`} fontSize="small" color="action"
                              onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsdown, result.inputLatex, result.node_id, feedbackthumbsup
                                , result.line_match, `${result.node_id}incorrectdown`, `${result.node_id}incorrectup`)}></ThumbDownIcon>
                          </div>
                        </div>
                        {content} <br></br>
                        {Number.isInteger(line_match) && (assumed_content_list.length > 1 || propagation == 1) && !correct_version_exists ?
                          <div>
                            <div className="grey">
                              {propagation == 1 ? assumed_step_propagation_feedback[0] : assumed_step_feedback_incorrect[ind_assumed_step_feedback_incorrect]}
                              {assumed_content_list}
                            </div>
                          </div>
                          : null
                        }
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        {showFullApp() ?
                          <div> {`For more help,  `}<button className='linkbutton' tooltip="Get further explanations from the interactive AI tutor."
                            onClick={(e) => this.handleaskAItutor(e, result.line_match, result.inputLatex, "incorrect")}><img style={{ height: '24px', width: '24px', color: '#4252AF', marginRight: '6px', marginLeft: "5px" }} alt="Get further explanations from the interactive AI tutor." src={AskAITutIcon} width="20px" height="20px" />Ask AI Tutor</button>
                          </div> :
                          null}


                      </div>

                    </>
                    assumed_content_list = Number.isInteger(line_match) ? [] : assumed_content_list
                    propagation = Number.isInteger(line_match) ? 0 : propagation

                    modalBody.push((
                      <Aux>
                        {headerContent}
                        {footerContent}
                        {/* {content} */}
                      </Aux>
                    ));
                   
                  } else if (result.status === 'error') {
                    this.props.checkanyequationincorrectHandler();
                    // show dialog for video conf./hint
                    setTimeout(() => { this.props.showHintAndConferenceDialog() }, 1000)
                    headerContent = result.inputLatex ?
                      <div className='mb-2 d-flex align-items-center'>
                        <p>You've made an error at line {result.line_match}</p>
                        <div className='mr-2'>Erroneous Step: </div>
                        {/* <div>
              <MathJax.Context input='tex'>
                <MathJax.Node>{result.inputLatex}</MathJax.Node>
              </MathJax.Context>
            </div> */}
                      </div>
                      : null
                    footerContent = <p>{result.message}</p>
                    modalBody.push((
                      <Aux>
                        {headerContent}
                        {footerContent}
                        {'Try again and let me know if you need help. You got this!'}
                      </Aux>
                    ));
                    console.log(result.message);
                    break
                  }

                  if (result.is_partially_complete === true) {
                    is_partially_complete = true
                    if (stepNo === res.data.result.length) {
                      modalBody.push((
                        <h5><hr></hr><div className='green'>You've achieved one of the right answers! Keep honing your skills to discover
                          the second solution. Excellent progress!
                        </div></h5>
                      ));
                    }
                  }
                  if (result.final === true) {
                    isChallengeCompleted = true
                    if (stepNo === res.data.result.length) {
                      modalBody.push((

                        <h5><hr></hr><div className='green'>Problem Completed! It's clear that you've been putting in the effort to understand the concepts.</div></h5>
                      ));
                    }

                    this.setState({ challengeCompleted: true });
                    if (this.props.location.state) {
                      this.props.location.state = {
                        ...this.props.location.state,
                        challengeCompleted: true
                      }
                    } else {
                      this.props.location.state = {
                        challengeCompleted: true
                      }
                    }
                  }

                }

                let error_data = null
                let hint_data = null
                let diagnostic_question_data = null

                if (res.data.error) {
                  error_data = (
                    <Aux>
                      <p>You've made error at line {res.data.error.line_match}</p>
                      {res.data.error.message ?
                        <p>{res.data.error.message}</p>
                        : null}
                    </Aux>
                  );
                }
                if (res.data.hint_obj) {
                  hint_data = (
                    <Aux>
                      <p>Your <label className="lineNumber">line #{res.data.hint_obj.line_match}</label> is not anything I was expecting.</p>
                      {res.data.hint_obj.hint_remedial_text ?
                        <p dangerouslySetInnerHTML={{ __html: res.data.hint_obj.hint_remedial_text }}></p>
                        : null}
                      {res.data.hint_obj.hint_remedial_source ?
                        <a href={res.data.hint_obj.hint_remedial_source} target='_blank' rel="noopener noreferrer"><p>{res.data.hint_obj.hint_remedial_resource_display_name ? res.data.hint_obj.hint_remedial_resource_display_name : res.data.hint_obj.hint_remedial_source}</p></a>
                        : null}
                    </Aux>
                  );
                }
                if (res.data.diagnostic_questions_obj) {
                  diagnostic_question_data = (
                    <button className="yellow-btn diagnostic-question-button" onClick={(e) => this.props.showDiagnosticQuestion(e, res.data.diagnostic_questions_obj)}>Stuck here? Let’s figure out why</button>
                  )
                }

                fetchKnowledgeGraph({
                  params: {
                    'challengeId': this.state.challengeId
                  }
                }).then(async response => {
                  knowledgeGraphUiJson = this.getFormattedKnowledgeGraph(ui_json, response.data.ui_json);

                  modalBody = (
                    <div>
                      {modalBody.map(content => content)}
                      {error_data && !this.state.challengeCompleted ?
                        <div>
                          <hr />
                          <div className='red'>
                            {error_data}
                          </div>
                        </div>
                        : null}
                      {hint_data && !this.state.challengeCompleted ?
                        <div>
                          <hr />
                          <div className='red'>
                            {hint_data}
                          </div>
                        </div>
                        : null}
                      {diagnostic_question_data && !this.state.challengeCompleted ?
                        <div>
                          <hr />
                          <div className='red'>
                            {diagnostic_question_data}
                          </div>
                        </div>
                        : null}
                      <hr></hr>
                      {
                        !this.props.forMobile ? (this.getGraphs(knowledgeGraphUiJson, ui_json, finalsubstitutionslist)) : null
                      }

                    </div>
                  )
                  this.props.setSidebarContent(modalBody)
                }).catch(err => {
                  modalBody = (
                    <div>
                      {modalBody.map(content => content)}
                      {error_data && !this.state.challengeCompleted ?
                        <div className='red'>
                          {error_data}
                        </div>
                        : null}
                      {hint_data && !this.state.challengeCompleted ?
                        <div className='red'>
                          {hint_data}
                        </div>
                        : null}
                      {diagnostic_question_data && !this.state.challengeCompleted ?
                        <div className='red'>
                          {diagnostic_question_data}
                        </div>
                        : null}
                      {
                        !this.props.forMobile ? (this.getGraphs(knowledgeGraphUiJson, ui_json, finalsubstitutionslist)) : null
                      }
                    </div>
                  )
                  this.props.setSidebarContent(modalBody)
                })


                if (this.props.location.state.hintUsed) {
                  this.props.location.state.hintUsed = false;
                } else {
                  this.props.location.state = {
                    ...this.props.location.state,
                    hintUsed: false
                  }
                }
                // this.props.setIncorrectHints(incorrectHints);

                // this.placeCorrectEquationImage(correctEq);

              } else {

                modalBody = [(
                  <Aux>
                    <div className="progress-bar-container d-block px-2 w-100 text-start">
                      <div className="py-3 ">
                        <p className="">Streamline your steps and review your entered equation for accurate feedback just the way you would when showing it to tutor to get accurate feedback.</p>
                      </div>

                    </div>
                  </Aux>
                )];
                this.props.setSidebarContent(modalBody)
              }
            }
          }).catch(err => {
            console.error(err.message);
          })
        }
      }
      else {
        this.setState({ loader: true })

        let params = new URLSearchParams();
        params.append('InputMessage', 'check my work');
        params.append('isNew', false);
        params.append('sessionid', this.props.sessionId);
        params.append('userid', getCurrentUserId());

        askAnyProblemQuestion(params).then(res => {
          this.setState({ loader: false })
          if (res.data.response !== undefined && res.data.response !== null && res.data.response !== "") {

            let contentdata = (
              <Aux>

                <div className='modal-p mt-3'>
                  {/* <MathComponent mathml={res.data.response} /> */}
                  <MathJaxRenderer math={res.data.response}></MathJaxRenderer>
                </div>
              </Aux>
            )
            let modalBody = (
              <>{contentdata}</>
            );

            this.props.setSidebarContent(modalBody)
          }

        }).catch((err) => {
          return err;
        })
      }
    }
  }

  getGraphs = (knowledgeGraphUiJson, ui_json, finalsubstitutionslist, hasKnowledgeGraph = true) => {
    if (hasKnowledgeGraph) {
      return <div className="solutionGraphCotainer">
        <Accordion style={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><span className="accordianTitle">Knowledge Graph</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div style={{ height: '500px' }}>
                <Flow readOnly={true} uiJson={knowledgeGraphUiJson} hasCheckMyWOrk={true} isKnowledgeGraph={true} openContentPopup={this.openContentPopup}></Flow>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={true} style={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><span className="accordianTitle">Solution Graph: Your progress so far</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {
                finalsubstitutionslist !== "" && finalsubstitutionslist !== '' && finalsubstitutionslist !== undefined && finalsubstitutionslist !== null && finalsubstitutionslist !== "null" && finalsubstitutionslist !== "null" ? (
                  <>
                    <div style={{ fontSize: "11px", maxWidth: "500px" }}>
                      {substituteText} {finalsubstitutionslist}
                    </div>

                  </>
                ) : null
              }
            </Typography>
            <Typography>
              {ui_json !== "[]" ?
                <div style={{ height: '500px', marginTop: "20px" }}>
                  <div className="legendContainer">
                    <div className="legendItemContainer">
                      <div className="correct"></div>
                      <span>Correct step</span>
                    </div>

                    <div className="legendItemContainer">
                      <div className="optional"></div>
                      <span>Step inferred as correct step</span>
                    </div>

                    <div className="legendItemContainer">
                      <div className="incorrect"></div>
                      <span>Incorrect step</span>
                    </div>

                  </div>


                  <Flow readOnly={true} uiJson={ui_json} hasCheckMyWOrk={true}  ></Flow>
                </div>
                :
                <div style={{ height: '500px' }}>
                  No diagram is available to show.
                </div>
              }

            </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    } else {
      return <div className="solutionGraphCotainer">
        <Accordion expanded={true} style={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><span className="accordianTitle">Solution Graph: Your progress so far</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div style={{ height: '500px' }}>
                <div className="legendContainer">
                  <div className="legendItemContainer">
                    <div className="correct"></div>
                    <span>Correct step</span>
                  </div>

                  <div className="legendItemContainer">
                    <div className="optional"></div>
                    <span>Step inferred as correct step</span>
                  </div>

                  <div className="legendItemContainer">
                    <div className="incorrect"></div>
                    <span>Incorrect step</span>
                  </div>

                </div>
                <Flow readOnly={true} uiJson={ui_json} hasCheckMyWOrk={true} ></Flow>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    }

  }

  openContentOptionsModal = (e, subSectionId, hasPosition = false) => {
    let position = { top: e.pageY + 500, left: e.pageX }
    // if (!hasPosition) {
    //   let isTouchEvent = false
    //   if (e.nativeEvent instanceof TouchEvent) {
    //     isTouchEvent = true
    //   }

    //   let topPosition = e.pageY
    //   let leftPosition = e.pageX - 50
    //   if (isTouchEvent) {
    //     var touch = e.changedTouches[0];
    //     topPosition = touch.pageY
    //     leftPosition = touch.pageX - 50
    //   }
    //   position = {
    //     top: topPosition,
    //     left: leftPosition
    //   }
    // } else {
    //   position = {
    //     top: e.x,
    //     left: e.y
    //   }
    // }


    this.openContentOptions(e,
      <div className="progress-bar-container"><Spinner animation="border" role="status" /></div>,
      position, true)
    const reqData = { params: { subSectionId } }
    fetchSubSectionContent(reqData)
      .then(res => {
        res.data.content.length > 0 ?
          this.openContentOptions(e,
            this.getContentOptionsJSX(res.data.content),
            position, false)
          : this.openContentOptions(e,
            <p>No Content Found.</p>,
            position, false)

      })
      .catch(err => {
        console.error(err.message)
        if (err.response.status === 404) {
          this.openContentOptions(e,
            <p>No Content Found.</p>,
            position, false)
        }
      })
  }

  getContentOptionsJSX = (data) => {
    return (
      <div className="content-options">
        <HomeCurriculumContentCard
          title="Recommended Content for Your Cognitive Profile"
          data={[data[0]]}
          linkStateObj={{ prevPath: ['/'], prevNode: ['Dashboard'] }} />
        {data.length > 1 ?
          <HomeCurriculumContentCard
            title="Other Curated Content Options"
            data={data.splice(1)}
            linkStateObj={{ prevPath: ['/'], prevNode: ['Dashboard'] }} />
          : null}
      </div>
    )
  }

  openContentOptions = (event, modalBody, position, isContentOptionsLoading) => {
    // event.preventDefault();
    this.contentModalBody = modalBody;
    this.setState({
      showContentOptions: true,
      isContentOptionsLoading: isContentOptionsLoading,
      contentOptionsPosition: position,
    });
  }

  openContentPopup = (event, id) => {
    this.openContentOptionsModal(event, id.substring(1, id.length));
  }
  getFormattedKnowledgeGraph = (ui_json, knowledgeGraphJson) => {
    let uiJson = JSON.parse(ui_json);
    let knowledgeGraphUIJson = JSON.parse(knowledgeGraphJson)
    uiJson.forEach((node) => {
      if (!node.data.isNodeCorrect) {
        if (!isNil(node.data.requisiteKnowledge)) {
          node.data.requisiteKnowledge.forEach((knowledgeId) => {
            knowledgeGraphUIJson.forEach((item) => {
              if (item.id === 'R' + knowledgeId) {
                item.data.isNodeCorrect = false;
              }
            })
          })
        }

      }
    })

    return JSON.stringify(knowledgeGraphUIJson);
  }
  svg2img = (svg, elem) => {
    // var placeholder = document.createElement('div');
    // placeholder.innerHTML = svg
    // var elem = placeholder.firstChild;
    // console.log("Width",elem.getAttribute('width'))

    var xml = new XMLSerializer(svg).serializeToString(elem);
    var svg64 = ''
    try {
      svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
    } catch (e) {
      svg64 = btoa(unescape(encodeURIComponent(xml)))
    }
    var b64start = 'data:image/svg+xml;base64,';
    var image64 = b64start + svg64;
    return image64;
  }

  getMathMlWithLine = (mathML, lineNumber) => {
    let mathDiv = document.createElement('div');
    mathDiv.innerHTML = mathML;
    let mtextEl = document.createElement('mtext');
    mtextEl.innerHTML = "Line " + lineNumber + ":&emsp;";
    mathDiv.firstChild.prepend(mtextEl);
    return String(mathDiv.innerHTML);
  }


  generateSvgFromMathML = (mathML) => {
    const reqData = {
      params: {
        format: 'svg',
        fontSize: '18px',
        mml: mathML
      }
    }
    return generateSVGFromMathML(reqData)
      .then(res => {
        return res.data
      })
  }


  updateImageInSketchpad = (image, imgIndex) => {

    let frameHistory = window.sketchpad.room.sketches[0].framesHistory;
    let i = 0;
    let opid = null

    // console.log('THIS IS THE FRAME HISTORY ', frameHistory);
    return new Promise((resolve, reject) => {
      while (i < frameHistory.length) {
        // console.log("LOOPING THROUGH FRAME HISTORY, SEARCHING AT ", i)
        if (frameHistory[i].evs[0].tol == 'equation') {
          // console.log("GOT THE IMAGE IN HISTORY AT INDEX ", i)
          if (imgIndex !== 0) {
            // console.log('NON-ZERO INDEX RECEIVED, DECREASING IT ', imgIndex)
            imgIndex--;
          }
          else {
            // console.log('ZERO INDEX, UPDATING IMAGE IN FRAMEHISTORY')
            frameHistory[i].evs[0].cnf.url = image
            opid = frameHistory[i].bid
            break;
          }
        }
        else {
          // console.log("DID NOT FIND IMAGE AT INDEX ", i, frameHistory[i].evs[0].tol, typeof frameHistory[i].evs[0].tol)
        }
        i++;
      }
      window.sketchpad.saveSketchpad()
      window.sketchpad.refreshWindow()
      this.setState({ selectedImageIndex: null })

      // console.log('GOT THE OPID, RESOLVING PROMISE', opid);
      resolve(opid);
    })
  }

  uploadQuestionImage = (questionImage, clientX, clientY) => {
    let p = { x: clientX, y: clientY };
    let e = { x: p.x, y: p.y }
    let that = window.sketchpad;
    if (that.inputEventsDisabled) {
      return false;
    }
    // e.preventDefault();
    that.mouseLB = true;
    p = that.mainPos(clientX, clientY);
    e = p
    // console.log(p,e)
    let imageId = window.ToolImage.prototype.onImagehostReady(questionImage, p.x, p.y, e.x, e.y, false, window.sketchpad);
    // console.log(imageId)
    window.sketchpad.saveSketchpad()
    window.sketchpad.refreshWindow()
    return imageId;
  };


  placeQuestionImageOnAnswerboard = (clientX, clientY) => {
    // console.log("BASE64 Functions IS CALLED")

    if (this.state.selectedToolbarItemId !== "place-image-button") {
      return;
    }


    var xhr = new XMLHttpRequest();
    let urlImage = "";
    let uploadImage = this.uploadQuestionImage;
    xhr.open("GET", this.props.location.state.image, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      // console.log(this.response);
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target.result;
        //  console.log(res)
        urlImage = res;
        // console.log(urlImage)
        uploadImage(res, clientX, clientY)
        return res;
        //  this.uploadQuestionImage(res,clientX,clientY)
      }
      var file = this.response;
      console.log("READER", reader.readAsDataURL(file))
    };
    xhr.send()
    // console.log("OKKK", xhr, xhr.response, url)
    // var reader = new FileReader();
    // reader.onload = function (event) {
    //   var res = event.target.result;
    //   //  console.log(res)
    //   url = res;
    //   //  this.uploadQuestionImage(res,clientX,clientY)
    // }
    // var file = xhr.response;
    // reader.readAsDataURL(file)
    // console.log("URL",url)
  }




  deleteImageinSketchpad = () => {
    let frameHistory = window.sketchpad.room.sketches[0].framesHistory;
    let newframeHistory = [];
    let i = 0;
    let imageIndex = this.state.selectedImageIndex;
    let mathImgDelete = [];

    mathImgDelete.push(this.state.mathImage[imageIndex].op);

    return new Promise((resolve, reject) => {
      while (i < frameHistory.length) {
        // console.log("LOOPING THROUGH FRAME HISTORY, SEARCHING AT ", i)
        newframeHistory.push(frameHistory[i])
        if (frameHistory[i].evs[0].tol == 'equation') {
          // console.log("GOT THE IMAGE IN HISTORY AT INDEX ", i)
          if (imageIndex !== 0) {
            // console.log('NON-ZERO INDEX RECEIVED, DECREASING IT ', imageIndex)
            imageIndex--;
          }
          else {
            // console.log('ZERO INDEX, UPDATING IMAGE IN FRAMEHISTORY')
            newframeHistory.pop()
            imageIndex--;
          }
        }
        else {
          // console.log("DID NOT FIND IMAGE AT INDEX ", i, frameHistory[i].evs[0].tol, typeof frameHistory[i].evs[0].tol)
        }
        i++;
      }



      updateWiris({
        board: this.state.boardId,
        userId: this.props.teacher ? this.state.teacherStudentId : getCurrentUserId(),
        dOps: JSON.stringify(mathImgDelete),
        problem_source: this.props.problem_source

      }).then(res => {

        window.sketchpad.room.sketches[0].framesHistory = newframeHistory;
        window.sketchpad.saveSketchpad()
        window.sketchpad.refreshWindow()
        this.setState({ mathImage: res.data['data'].map(item => JSON.parse(item)) });
        //    this.clearEditor();

      }).catch(err => {
        console.error(err.message);

        alert('Error occurred');
        // this.props.history.goBack();
      })

      this.setState({ selectedImageIndex: null })

    })
  }

  showEqualSignMessage = (value) => {
    if (value.indexOf('<mo>=</mo>') === -1 && this.showEqualMessageCounter < 2) {
      toast('Please check if the entered equation is in LHS = RHS format. Also, enter the units at the end of the whole equation.', {
        type: toast.TYPE.WARNING,
        title: "",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 6000
      })

      this.showEqualMessageCounter++;
    }

  }

  getMathplxCanvas = (clearCanvas) => {
    this.clearCanvas = clearCanvas;
  }

  updateCanvasCount = (count) => {
    this.canvasCount = count;
    this.handleSaveMyWork();
  }
  mathplxResponseHandler = (response, fromImage, strokes) => {
    let index = 0;
    let tempArr = [];
    let isCanvasChanged = false
    if (strokes !== undefined) {
      // //debugger
      if (this.canvasStrokes !== undefined && strokes.length !== this.canvasStrokes.length) {
        isCanvasChanged = true
      }
      this.canvasStrokes = strokes

      try {
        if (!isNil(response.mathml) && !isEmpty(response.mathml)) {
          this.clearFetchWirisInterval();
          this.state.equations.forEach((item) => {
            if (item.isFixed) {
              if (item.type === 'eq') {
                tempArr.push({ content: item.mathml, index: index, type: CONTENT_TYPE.EQUATION, isFixed: item.isFixed })
              } else {
                tempArr.push({ content: item.text, index: index, type: CONTENT_TYPE.TEXT, isFixed: item.isFixed })
              }
              index++;
            }

          })
          this.setState({ equations: this.state.equations.filter((o) => o.isFixed === true) });
          response.mathml.data.data.forEach((item) => {
            if (item['type'] === 'mathml') {
              this.showEqualSignMessage(item.value);
              const formattedArr = this.getMathMl(item.value);
              formattedArr.forEach((item) => {
                ////debugger
                if (item.type === 'eq') {
                  tempArr.push({ content: item.item, index: index, type: item.type, isFixed: false })
                  index++;
                }
              })
            }
          })

          this.exportMathML(tempArr)
          if (isCanvasChanged) {
            this.clearEquationBorder();
          }
          if (fromImage) {
            this.openPostImageUploadPopup();
          }
        } else if (!isNil(response.mathml) && isEmpty(response.mathml)) {
          this.state.equations.forEach((item) => {
            if (item.isFixed) {
              if (item.type === 'eq') {
                tempArr.push({ content: item.mathml, index: index, type: CONTENT_TYPE.EQUATION, isFixed: item.isFixed })
              } else {
                tempArr.push({ content: item.text, index: index, type: CONTENT_TYPE.TEXT, isFixed: item.isFixed })
              }
              index++;
            }
          })

          if (tempArr.length > 0) {
            this.exportMathML(tempArr)
            if (isCanvasChanged) {
              this.clearEquationBorder();
            }
            this.setState({ equations: this.state.equations.filter((o) => o.isFixed === true) });
          }
          if (fromImage) {
            this.openPostImageUploadPopup();
          }

        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  openPostImageUploadPopup = () => {
    this.playDemoVideo =
      this.setState({
        warningModalBody:
          <ul>

            <li>Please check if your equations/text are interpreted accurately.  You can edit them using Equation Keyboard <img alt='Pen Icon' src={CalcIcon} />. </li>

          </ul>
      })
    this.setState({ showWarningModal: true })
    this.clearEquationBorder();
  }
  getMathMl = (mathml) => {
    let returnArr = [];
    mathml = mathml.replace(/\n/g, " ");
    if (mathml.search('<mtable') !== -1) {
      // Create a DOMParser instance
      const parser = new DOMParser();
      // Parse the MathML string
      const mathmlDoc = parser.parseFromString(mathml, 'text/xml');
      // Get the mtable element containing rows
      const mtable = mathmlDoc.querySelector('mtable');
      // Get all mtr (table row) elements
      const rows = mtable.querySelectorAll('mtr');
      // Loop through rows and generate separate MathML strings
      const separateEquations = [];
      rows.forEach(row => {
        // Create a new MathML document for each row
        if (!row.hasAttribute('xmlns')) {
          // If the xmlns attribute is missing, add it with the correct namespace URI
          row.setAttribute('xmlns', 'http://www.w3.org/1998/Math/MathML');
        }

        const newRowDoc = document.implementation.createDocument('http://www.w3.org/1998/Math/MathML', 'math', null);
        const newRowMath = newRowDoc.documentElement;
        // Create a new mtable for the row
        const newMtable = newRowDoc.createElementNS(null, 'mtable');
        newRowMath.appendChild(newMtable);
        // Clone the current row into the new mtable
        newMtable.appendChild(row.cloneNode(true));
        newMtable.removeAttribute('xmlns');
        // Serialize the new MathML document to string
        const newRowMathMLString = new XMLSerializer().serializeToString(newRowDoc);
        // newRowMathMLString.replace(/http://www.w3.org/1998/Math/MathML/g, "");
        // Add the serialized MathML string to the array
        separateEquations.push(newRowMathMLString);

      });

      // Print or use the separate MathML strings
      separateEquations.forEach((equation, index) => {
        returnArr.push({ item: equation, type: CONTENT_TYPE.EQUATION })
      });
      // }
    } else {
      returnArr.push({ item: mathml, type: CONTENT_TYPE.EQUATION })
    }
    return returnArr;
  }

  getExistingBoardItems = () => {
    const tempArr = clone(this.state.equations);
    let returnArr = [];
    tempArr.forEach((item, index) => {
      item.isFixed = true;
      if (item.type === 'eq') {
        returnArr.push({ content: item.mathml, index: index, type: CONTENT_TYPE.EQUATION, isFixed: item.isFixed })
      } else {
        returnArr.push({ content: item.text, index: index, type: CONTENT_TYPE.TEXT, isFixed: item.isFixed })
      }
    })
    return returnArr;
  }

  handleMultipleEquations = async (e) => {

    this.clearFetchWirisInterval();

    if (this.props.location.state.resetHintCount) {
      this.props.location.state.resetHintCount();
    }
    let mathml = window.editor.getMathML();
    if (mathml === '<math xmlns="http://www.w3.org/1998/Math/MathML"/>') {
      this.setState({ loader: false })
      alert("Please add equation");
      return false;
    }

    if (!this.props.isAnyProblem) {
      this.setState({ loader: true })
      let itemsArr = this.getExistingBoardItems();
      let preArr = slice(itemsArr, 0, this.state.selectedImageIndex);
      let postArr = slice(itemsArr, this.state.selectedImageIndex + 1, itemsArr.length);

      this.showEqualSignMessage(mathml)
      let mathmls = mathml.split('<mspace linebreak="newline"/>');
      mathmls = mathmls.filter(equation => equation.length > 0);
      this.closeWirisModal();
      let i = 0;
      for (const mathmlitemitr of mathmls) {
        let mathmlitem = "";
        if (i === 0) {
          mathmlitem = mathmlitemitr;
          mathmlitem += mathmls.length > 1 ? '</math>' : '';
        }
        else if (i > 0 && i < mathmls.length - 1) {
          mathmlitem = '<math xmlns="http://www.w3.org/1998/Math/MathML">' + mathmlitemitr + '</math>';
        }
        else if (mathmlitemitr !== '</math>') {
          mathmlitem = '<math xmlns="http://www.w3.org/1998/Math/MathML">' + mathmlitemitr;
        }

        if (mathmlitem !== '') {
          if (!this.state.isWirisHadEditMode) {
            itemsArr.push({ content: mathmlitem, index: itemsArr.length, type: CONTENT_TYPE.EQUATION, isFixed: true })
          } else {
            preArr.push({ content: mathmlitem, index: preArr.length, type: CONTENT_TYPE.EQUATION, isFixed: true })
          }
        }
        i++;
      }

      if (this.state.isWirisHadEditMode) {
        itemsArr = [...preArr, ...postArr];
        this.setState({ isWirisHadEditMode: false })
        this.closeWirisModal();
      }
      //alert("444")
      this.exportMathML(itemsArr);
      this.setState({ eraseCanvas: true });

    }
    else {
      const latexReqData = {
        params: {
          mml: mathml
        },
      }
      generateLatexFromMathML(latexReqData).then((res) => {
        var response = res.data.split("\\\\")
        let question = ''
        let equationLatexArry = response.toString().split(/,/)
        if (equationLatexArry.length > 0) {
          for (let i = 0; i < equationLatexArry.length; i++) {
            if (i > 0) {
              question += "\\n" + "$$" + equationLatexArry[i] + "$$";
            } else {
              question += "$$" + equationLatexArry[i] + "$$";
            }

          }

          console.log("final inputmessage for askanyproblem api - " + question)
          this.askAnyProblem(mathml, question);
        }
      }).catch((err) => {
        return err;
      });
    }
  }

  AnyProblemCheckMyWorkHandler = () => {
    if (this.props.location.state.resetHintCount) {
      this.props.location.state.resetHintCount();
    }

    this.askAnyProblem('', this.state.ckEditorMathML);
  }

  askAnyProblem = (mathml, question) => {

    this.setState({ loader: true })
    let equationArry = [];
    let sessionId = 0

    if (this.state.equations.length > 0) {
      equationArry = this.state.equations
    }

    equationArry.push({ class: '', type: CONTENT_TYPE.EQUATION, mathml: this.state.ckEditorMathML !== '' ? this.state.ckEditorMathML : mathml, eqindex: equationArry.length, 'ans': [] })

    let params = new URLSearchParams();
    params.append('InputMessage', question);
    params.append('inputMathML', mathml);
    params.append('isNew', false);
    params.append('sessionid', isNil(this.props.sessionId) ? sessionId : this.props.sessionId);
    params.append('userid', getCurrentUserId());

    askAnyProblemQuestion(params).then(res => {
      if (!isNil(res.data.response)) {
        this.setState({ loader: false })
        equationArry[equationArry.length - 1]['ans'].push(res.data.response);
        if (this.props.isAnyProblem) {
          //   responseArr.push(res.data.response)

          const domEditableElement = document.querySelector('.ck-editor__editable')
          const editorInstance = domEditableElement.ckeditorInstance;
          editorInstance.setData('');
        }
        else {
          this.clearEditor();
        }

        this.setState({ equations: equationArry });
      }

    }).catch((err) => {
      return err;
    })
  }

  exportMathML = (contents) => {
    try {
      this.setState({ loader: true, checkmyworkdisabled: true })
      //this.clearEquationBorder();
      let params = new URLSearchParams();
      let roomId = this.props.roomId
      if (roomId === null || roomId === undefined) {
        roomId = localStorage.getItem("studentroomid")
      }
      let requestArr = [];
      if (this.state.challengeType === CONTENT_TYPE.EQUATION) {
        contents.forEach((item, index) => {
          requestArr.push({
            'op': JSON.stringify({ x: 0, y: 0, opid: index }),
            'mathML': item.type === CONTENT_TYPE.EQUATION ? item.content : '',
            'text': item.type === CONTENT_TYPE.TEXT ? item.content : '',
            'lineNumber': index,
            'type': item.type,
            'isFixed': item.isFixed
          })
        })
      } else {
        contents.forEach((item, index) => {
          requestArr.push({
            'op': JSON.stringify({ x: 0, y: 0, opid: index }),
            'mathML': item.type === CONTENT_TYPE.EQUATION ? item.content : '',
            'text': item.type === CONTENT_TYPE.TEXT ? item.content : '',
            'lineNumber': index,
            'type': item.type,
            'isFixed': item.isFixed
          })
        })
      }
      // if (isNil(this.state.selectedImageIndex)) {
      params.append('board', this.state.boardId);
      params.append('challengeMapId', this.state.challengeMapId);
      params.append('userId', this.props.teacher ? this.state.teacherStudentId : getCurrentUserId());
      params.append('contents', JSON.stringify(requestArr));
      // this.equationCounter++;
      ////debugger
      params.append('roomId', roomId);
      params.append('problem_source', this.props.problem_source);
      params.append('assignment_id', this.props.assignment_id);
      this.setState({ eraseCanvas: false });
      updateWiris(params).then(res => {
        this.setState({ loader: false, checkmyworkdisabled: false })
        // this.submitEquation();
        if (this.state.challengeType === CONTENT_TYPE.EQUATION) {
          this.onBoardItemsRecieved(res)
          //   this.clearEditor();
          this.props.resetNextStepCounter();
          // this.resumeFetchWirisInterval();
        } else {
          this.updateMathImages();
        }
      }).catch(err => {
        console.error(err.message);
        this.setState({ loader: false })

      })

      this.handleSaveMyWork();
    } catch (e) {
      this.setState({ loader: false })
    }

  }

  handleSaveMyWork = () => {
    if (this.canvasStrokes !== undefined) {
      const reqData = {
        "user_id": getCurrentUserId(),
        "challenge_id": this.state.challengeId,
        "board_id": this.state.boardId,
        "equations": JSON.stringify(this.canvasStrokes),
        "canvasCount": JSON.stringify(this.canvasCount)
      }

      saveUpdateFRQEquations(reqData).then(res => {
        if (res.data !== undefined && res.data !== null) {
          // alert("saved successfully.")
        }
      }).catch(err => {
        console.log("API Error: ", err);
      })
    }
  }

  addNewCanvasHandler = () => {
    let index = 0;
    let tempArr = [];
    this.clearFetchWirisInterval();
    this.state.equations.forEach((item) => {
      item.isFixed = true;
      if (item.type === 'eq') {
        tempArr.push({ content: item.mathml, index: index, type: CONTENT_TYPE.EQUATION, isFixed: item.isFixed })
      } else {
        tempArr.push({ content: item.text, index: index, type: CONTENT_TYPE.TEXT, isFixed: item.isFixed })
      }
      index++;


    })

    this.exportMathML(tempArr)
  }

  toggleVisibility = () => {
    this.parentHeight = document.getElementById('canvas_Parent').clientHeight
    this.parentWidth = document.getElementById('canvas_Parent').clientWidth
    if (!this.state.isVisible) {
      this.left_Panel_Classes = "col-md-8 pb-2"
      this.right_Panel_Classes = "col-md-4"
    }
    else {
      this.left_Panel_Classes = "col-md-11 pb-2"
      this.right_Panel_Classes = "col-md-1"
    }
    this.setState({ isVisible: !this.state.isVisible });


  };

  toggleEquationBoardVisibility = () => {
    this.setState({ isVisibleEquationboard: !this.state.isVisibleEquationboard })
  }

  ckEditorDataChangedEvent = (changedData) => {
    this.setState({ ckEditorMathML: changedData })
  }

  saveuser_Actions = () => {
    let question_id = this.props.question.id.slice(1, this.props.question.id.length);
    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
    let reqData = {
      challenge_id: question_id,
      user_id: getCurrentUserId(),
      source: "",
      assignment_id: assignmentId,
      problem_source: this.props.problem_source,
      boardId: this.props.location.state.boardId,
      action_name: "uploadimage"

    }
    saveuseractions(reqData).then(res => {

    }).catch(err => {
      console.error(err.message);
    })



  }
  capture_image_upload = () => {

    var canvas = document.getElementById('1_canvas');
    var screenshotBase64 = '';
    if (!isNil(canvas)) {
      screenshotBase64 = canvas.toDataURL();
    }
    console.log("canvas screenshot code end here.......", canvas)
    let question_id = this.props.question.id.slice(1, this.props.question.id.length);
    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
    let reqData = {
      challenge_id: question_id,
      user_id: getCurrentUserId(),
      source: screenshotBase64,
      assignment_id: assignmentId,
      problem_source: this.props.problem_source,
      boardId: this.props.location.state.boardId,
      action_name: "captureuploadimage"

    }
    saveuseractions(reqData).then(res => {

    }).catch(err => {
      console.error(err.message);
    })



  }

  handleaskAItutor = (e, line_match, inputLatex, equationstatus) => {
    this.props.askaicheckmywork()
    this.props.forhintAiTutorfalsehandler()
    this.props.handleaskAIBySketchpad()
    document.getElementById('root').scrollTo(0, document.body.scrollHeight);
    let equationslistdata = [...this.state.equations]
    var objdata = equationslistdata[parseInt(line_match) - 1]
    if (objdata !== undefined && objdata !== null) {
      let eqmathML = "<p>" + objdata.mathml + "</p>"
      let containerclass = ""
      if (equationstatus === "correct") {
        containerclass = equationContainerClass.correctContainer
      }
      else {
        containerclass = equationContainerClass.errorContainer
      }
      this.setState({ askAIquestion: eqmathML, askAIBgColor: true, equationClass: containerclass, equationClick: true })
    }

    let question_id = this.props.question.id.slice(1, this.props.question.id.length);
    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
    let reqData = {
      challenge_id: question_id,
      user_id: getCurrentUserId(),
      source: "fromevaluateaskai",
      assignment_id: assignmentId,
      problem_source: this.props.problem_source,
      boardId: this.props.location.state.boardId,
      action_name: "askaitutor"

    }
    saveuseractions(reqData).then(res => {

    }).catch(err => {
      console.error(err.message);
    })

  }
  isallequationdeletefalse = () => {
    this.setState({ isallequationdelete: false })
  }
  opendeleteconfirmodel = (e) => {
    this.setState({ deleteconfirmodelopen: true, isallequationdelete: false })

  }

  deleteAllequationhandler = () => {
    this.clearFetchWirisInterval();
    let reqparams = {
      "challenge_id": this.props.challengeId,
      "board_id": this.state.boardId,
      "user_id": getCurrentUserId(),
      "problem_source": this.props.problem_source,
      "assignment_id": this.props.assignment_id
    }
    deleteallequations(reqparams).then(res => {
      this.setState({ equations: [] }, () => {
        toast('All equations are deleted successfully!', {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: true
        })
        this.setState({ deleteconfirmodelopen: false, isallequationdelete: true })
      })



    })

  }
  closedeleteconfirmodel = (e) => {
    this.setState({ deleteconfirmodelopen: false, isallequationdelete: false })

  }


  handleequationcheckbox = (e, index, eqmathML, inputLatex, itemclass) => {
    this.props.forhintAiTutorfalsehandler()
    this.props.handleaskAIBySketchpad()
    let bgcolor = false
    if (itemclass === equationContainerClass.errorContainer) {
      bgcolor = true
    }
    this.props.askaicheckmywork()
    let eqmathML1 = "<p>" + eqmathML + "</p>"
    this.setState({ askAIquestion: eqmathML1, askAIBgColor: bgcolor, equationClass: itemclass, equationClick: true })
  }
  handleequationclick = () => {
    this.setState({ equationClick: false })

  }
  handleremoveAskAIBgcolor = () => {
    this.setState({ askAIBgColor: false })
  }
  handleremovequestions = () => {
    // alert("222")
    this.setState({ askAIquestion: "" })
    this.props.handlehintequationclick()
  }

  handleHelpCenterClickEvent = () => {
    this.setState({ showModalVideo: true })

    this.setState({
      isWhiteBoardVideoAutoPlayDone: true,
      whiteBoardVideoDiv:
        <div className='videoModalDiv'>
          <div className='videoDiv'>
            <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
              <source src={getInstituteId() === Institute.Rice ? whiteBoardVideoRice : whiteBoardVideo} type="video/mp4" />
            </video>
          </div>
          <div className='videoHeader'>
            <span>Welcome to Interactive Problems <SchoolIcon className='schoolIcon' /> <AutoAwesomeIcon className='awesomeIcon' /></span>
          </div>
          <div className='videoDescription'>
            <p>Watch out instructional video for insights on how to maximize your
              learning or click on the <VideocamIcon className='helpIcon' /> help icon anytime you need assistance.</p>
          </div>
          <div className='videoDescription'>
            <input type="checkbox" value={this.state.doNotShowChk} onChange={(e) => this.noShowCheckboxChange(e)}></input>&nbsp;&nbsp;
            <label style={{ marginTop: '5px' }} class="addhomeworkcss">Don't show this again.</label>
          </div>
          <div className='modal-button text-center'>
            <Button className="videoClosebtn" onClick={this.closeVideoModal}> CLOSE </Button>
          </div>
        </div>
    })
  }

  noShowCheckboxChange = (e) => {
    this.setState({ isWhiteBoardVideoAutoPlayDone: e.target.checked })
  }

  closeVideoModal = () => {
    this.setState({ showModalVideo: false })
    let reqData
    getUIConfigDetails({
      params: {
        'userId': getCurrentUserId()
      }
    }).then(res => {
      if (res.status === 200) {
        if (JSON.stringify(res.data) === "[]") {
          reqData = {
            userId: getCurrentUserId(),
            isShowStreamlineMsg: true,
            showPracticeProblemsTour: false,
            showDashboardTour: false,
            showCurriculumContentTour: false,
            isWhiteBoardVideoAutoPlayDone: this.state.isWhiteBoardVideoAutoPlayDone
          }
        }
        else {
          reqData = {
            userId: getCurrentUserId(),
            isShowStreamlineMsg: true,
            showPracticeProblemsTour: res.data.showPracticeProblemsTour,
            showDashboardTour: res.data.showDashboardTour,
            showCurriculumContentTour: res.data.showCurriculumContentTour,
            isWhiteBoardVideoAutoPlayDone: this.state.isWhiteBoardVideoAutoPlayDone
          }
        }

        saveUIConfigDetails(reqData).then(res => {
          if (res.status === 200) {

          }
        })
      }
    })
  }

  checkMyworkthumbsupdownhandle = (e, thumbpsup, inputlatex, nodeId, feedback, linematch, buttonuniqueId, otherbuttonuniquId) => {
    // //debugger
    this.setState({ thumbsupvalue: thumbpsup, linematch: linematch })
    var chfeedbackdata = {
      "userId": this.props.teacher ? this.state.teacherStudentId : getCurrentUserId(),
      "challenge_id": this.props.challengeId,
      "node_id": nodeId,
      "equation": inputlatex,
      "thumbsupdown": thumbpsup,
      "feedback": feedback,
      'problem_source': this.props.problem_source,
      'assignment_id': this.props.assignment_id !== undefined && this.props.assignment_id !== null && this.props.assignment_id !== "" ? this.props.assignment_id : null
    }
    checkmyworkfeedbackthumbsupdownupdate(chfeedbackdata).then(async res => {
      if (res.status === 200) {
        document.getElementById(buttonuniqueId).style.color = "#4252af";
        document.getElementById(otherbuttonuniquId).style.color = "rgba(0, 0, 0, 0.54)";
      }
    }).catch(err => {

      console.error(err.message);
    });
  }

  submitFinalAnswerHandler = () => {
    if (this.state.equations.length > 0) {
      this.props.submitHandler();
    }
    else {
      this.setState({ isblankEquationModal: true })

    }
  }

  closeblankEquationModal = () => {
    this.setState({ isblankEquationModal: false })
  }


  render() {

    return (
      <Aux>

        {
          this.state.loader && (
            <>
              <div style={{
                color: "#164b99",
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
                zIndex: "9999"
              }}>
                <button class="btn btn-primary" type="button" disabled>
                  <span style={{ marginRight: '5px' }} class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Processing...
                </button>
              </div>
            </>

            // <CircularProgress
            //   size={24}
            //   sx={{
            //     color: "#164b99",
            //     position: 'absolute',
            //     top: '50%',
            //     left: '50%',
            //     marginTop: '-12px',
            //     marginLeft: '-12px',
            //     zIndex: "9999"
            //   }}
            // />
          )
        }

        <Modal1 className='modalVideo'
          show={this.state.showModalVideo}
          onHide={this.closeVideoModal}
          draggable={false}
          body={this.state.whiteBoardVideoDiv}
          handleClose={this.closeVideoModal}
          hideHeader={true}
          showButton={false}
        >
        </Modal1>

        <Dialog
          hideBackdrop={false}
          open={this.state.deleteconfirmodelopen}
          onClose={this.closedeleteconfirmodel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ marginTop: "10px", color: "#164b99" }}>
                Are you sure you want to delete all the equations?
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="dragLabelcancel leftmargin" onClick={this.deleteAllequationhandler}>
              Yes
            </button>

            <button className="dragLabelcancel leftmargin" onClick={this.closedeleteconfirmodel}>
              No
            </button>


          </DialogActions>
        </Dialog>





        <Dialog
                 hideBackdrop={false}
          open={this.state.isblankEquationModal}
          onClose={this.closeblankEquationModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ marginTop: "10px", color: "#164b99" }}>
                Please enter equation(s) to process.
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="dragLabelcancel leftmargin" onClick={this.closeblankEquationModal}>
              Ok
            </button>
          </DialogActions>
        </Dialog>


        <Dialog
          hideBackdrop={false}
          open={this.state.deleteconfirmodelopensingle}
          onClose={this.closedeleteconfirmodelsingle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ marginTop: "10px", color: "#164b99" }}>
                Are you sure you want to delete this equation?
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="dragLabelcancel leftmargin" onClick={this.handledeletesingleequations}>
              Yes
            </button>

            <button className="dragLabelcancel leftmargin" onClick={this.closedeleteconfirmodelsingle}>
              No
            </button>


          </DialogActions>
        </Dialog>

        {/* {this.props.isAnyProblem ? null :
          !showFullApp() ? null :
            <div className={this.props.askAITutorEnabled ? 'divBtnHide' : 'divbtnShow'} style={{ position: 'fixed', paddingRight: '0px', width: '100%', zIndex: '11', bottom: '50px' }}>
               <IconButton className="btnUsage" style={{ border: '2px solid #4252AF', padding: '5px', backgroundColor: '#ECF1FE', textAlign: 'center', display: 'flex', flexDirection: 'column', float: 'left', alignItems: 'center', marginLeft: '15px' }}>
                <div className="boardBtns" onClick={this.handleHelpCenterClickEvent}>
                  <VideocamIcon className='helpIcon' />
                </div>
                <p onClick={this.props.handleHelpCenterClickEvent} className='toolLabel'>Usage Guide</p>
              </IconButton> 
             <Button className='btnHelpCenter' onClick={this.handleHelpCenterClickEvent}>
                <VideocamIcon className='helpIcon' />Usage Guide</Button> 
              {this.props.askAITutorEnabled ? null :
                <Tooltip title="Get further explanations from the interactive AI tutor." placement='right' arrow >
                  <IconButton tooltip="Get further explanations from the interactive AI tutor." className="btnAskAITut" style={{ border: '2px solid #4252AF', padding: '5px', display: 'flex', backgroundColor: '#ECF1FE', textAlign: 'center', flexDirection: 'column', float: 'right', alignItems: 'center', marginRight: '20px' }}>
                    <div className="boardBtns" onClick={this.props.askAITutorHandler}>
                      <img alt="Get further explanations from the interactive AI tutor." src={AskTutIcon} />
                    </div>
                    <p onClick={this.props.askAITutorHandler} className='toolLabel'>Ask AI Tutor</p>
                  </IconButton>
                </Tooltip>
              }
            </div>
        } */}
        <div ref={this.sk_ref}
          style={{
            backgroundColor: `${!showFullApp() && this.props.questiontype !== "FRQ" ? "transparent" : this.props.sketchpadDivcolor}`
            , width: "100%", float: 'left',
            paddingLeft: `${showFullApp() ? "15px" : "0px"}`
          }}>
          <div className="barcode-container">
          </div>

          <div className="whiteboard-wapper answerboard_tour">
            <div style={{ width: '100%' }}>
              {
                this.state.challengeType === CONTENT_TYPE.EQUATION ?
                  <div
                    id="aww-wrapper"
                    className="aww"
                    style={{ width: "100%" }}>

                    <div id="board-menus" style={{ visibility: this.state.hasSketchpadView ? 'hidden' : 'hidden' }}>
                      <div className="toolbar__box--top-left flex--top mobile--hidden">
                        <div className="logo__box">
                          <img
                            src="/sample_toolbar_files/loader.gif"
                            alt="A Web Whiteboard"
                            className="loader"
                          />
                        </div>
                      </div>
                      <div className="toolbar__box--middle-left">
                        <div
                          id="main-toolbar"
                          className="toolbar toolbar--vertical fadeInUp--mobile mobile--hidden"
                        >
                          <ul className="tools__menu">

                            <li id="aww-toolbar-select" className="tools__item">
                              <div
                                data-tooltip="Select"
                                className={`tools__item--button has--tooltip ${this.state.selectedToolbarItemId === 'aww-toolbar-select' ? 'is--selected' : ''}`}
                                onClick={() => {
                                  window.sketchpad.setTool('null');
                                  this.setState({ selectedToolbarItemId: 'aww-toolbar-select' })
                                }}
                              >
                                <img
                                  src="/sample_toolbar_files/ict-select.svg"
                                  alt="Select"
                                />
                              </div>
                            </li>

                            <li
                              id="aww-toolbar-draw"
                              className="tools__item has--toolbox has--dropdown"
                            >
                              <div style={{ height: '75px' }}
                                data-tooltip="Draw"
                                className={`tools__item--button has--tooltip ${this.state.selectedToolbarItemId === 'aww-toolbar-draw' ? 'is--selected' : ''}`}
                                onClick={() => {
                                  window.sketchpad.setTool('pen');
                                  this.setState({ selectedToolbarItemId: 'aww-toolbar-draw' })
                                }}

                              >
                                <img
                                  src="/sample_toolbar_files/wiris-equations.svg"
                                  alt="Math"
                                  style={{ width: "42px", cursor: "pointer", position: 'absolute', top: '0' }}
                                />

                                <img
                                  src="/sample_toolbar_files/ict-pencil.svg"
                                  alt="Pen"
                                  style={{ position: 'absolute', top: '40px' }}
                                />
                              </div>

                            </li>
                            <li
                              id="aww-toolbar-erase"
                              className="tools__item has--toolbox has--dropdown "

                            >
                              <div
                                data-tooltip="Erase"
                                className={`tools__item--button has--tooltip ${this.state.selectedToolbarItemId === 'aww-toolbar-erase' ? 'is--selected' : ''}`}
                                onClick={() => {
                                  window.sketchpad.setTool('eraser')
                                  this.setState({ selectedToolbarItemId: 'aww-toolbar-erase' })
                                }}
                              >
                                <img
                                  src="/sample_toolbar_files/ict-eraser.svg"
                                  alt="Eraser"
                                />
                              </div>

                            </li>

                            <li
                              id="aww-toolbar-redo"
                              className="tools__item is--hidden"
                              onClick={() => window.sketchpad.redo()}
                            >
                              <div
                                data-tooltip="Redo"
                                className="aww-toolbar-redo tools__item--button has--tooltip"
                              >
                                <div className="redo-icon"></div>
                              </div>
                            </li>
                          </ul>

                        </div>
                      </div>
                    </div>

                    <div
                      className={`row no-gutters ${this.props.isScreenFull ? 'divpadding' : 'divpadding0'}`}
                      style={{ marginBottom: "40px" }}>
                      <div className={this.left_Panel_Classes}>
                        <div style={{ display: this.state.hasSketchpadView ? 'none' : 'none' }}>
                          <div className="infoText">*Double click on whiteboard to add an equation or text</div>
                          <div id="sketchpad"></div>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-start"
                          style={{ marginLeft: `${showFullApp() ? "60px" : "0px"}`, backgroundColor: '#ffffff', position: 'relative', visibility: !this.state.hasSketchpadView ? 'visible' : 'visible', top: '0px' }}>
                          {this.state.boardId !== null ?
                            <>
                              <div>
                                <CanvasProvider>
                                  <Canvas
                                    canvasHeight={this.parentHeight}
                                    canvasWidth={this.parentWidth}
                                    hasWarningPopup={this.state.hasWarningPopup}
                                    setWarningPopup={this.setWarningPopup}
                                    eraseCanvas={this.state.eraseCanvas}
                                    mathplxResponseHandler={this.mathplxResponseHandler}
                                    userRole={this.props.teacher}
                                    hasActiveConference={this.props.hasActiveConference}
                                    boardId={this.state.boardId}
                                    checkMyWorkHandler={this.props.checkMyWorkHandler}
                                    askTutorHandler={this.props.askTutorHandler}
                                    askAITutorHandler={this.props.askAITutorHandler}
                                    hintHandler={this.props.hintHandler}
                                    hintText={this.props.hintText} // {`Hints ${this.props.question.type === 'FRQ' && totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}`}
                                    hintDisabled={this.props.hintDisabled}  //{shouldShowHint}
                                    submitHandler={this.submitFinalAnswerHandler}
                                    submitNonFRQHandler={this.props.submitNonFRQHandler}
                                    openWirisHandler={this.openWirisModal}
                                    showNextStepHandler={this.props.showNextStepHandler}
                                    showEntireSolutionHandler={this.props.showEntireSolutionHandler}
                                    userId={getCurrentUserId()}
                                    user_Role={getCurrentUserRole()}
                                    challengeId={this.state.challengeId}
                                    questiontype={this.props.questiontype}
                                    showsubmitbutton={this.props.showsubmitbutton}
                                    leftpanelbuttonhide={this.props.leftpanelbuttonhide}
                                    getKnowledgeGraphHandler={this.props.getKnowledgeGraphHandler}
                                    saveSynopsisEquationToDatabaseHandler={this.props.saveSynopsisEquationToDatabaseHandler}
                                    endConferenceHandler={this.props.endConferenceHandler}
                                    checkmyworkdisabled={this.state.checkmyworkdisabled}
                                    isAnyProblem={this.props.isAnyProblem}
                                    anyProblembtnDisabled={this.props.actionBtnDisabled}
                                    checMyWorkForkAnyProblemHandler={this.AnyProblemCheckMyWorkHandler}
                                    addNewCanvasHandler={this.addNewCanvasHandler}
                                    isFromProfToTutor={this.state.isFromProfToTutor}
                                    fullScreenHandler={this.props.fullScreenHandler}
                                    isScreenFull={this.props.isScreenFull}
                                    cHeight={this.props.cHeight}
                                    handleShowEquation={this.props.handleShowEquation}
                                    showEquationPanel={this.props.showEquationPanel}
                                    problem_source={this.props.problem_source}
                                    isBlinkCheckMyWork={this.state.isBlinkCheckMyWork}
                                    equationslist={this.state.equations}
                                    lastAiTutorState={this.lastAiTutorState}
                                    usageGuide={this.handleHelpCenterClickEvent}
                                    // practiceMode={this.props.practiceMode}
                                    // timeLapseString={this.props.timeLapseString}
                                    calculateGradesForPTFRQ={this.props.calculateGradesForPTFRQ}
                                    totalGradeCalculationTrial={this.props.totalGradeCalculationTrial}
                                    isallequationdelete={this.state.isallequationdelete}
                                    updateCanvasCount={this.updateCanvasCount}
                                    deleteAllequationhandler={this.deleteAllequationhandler}
                                    closedeleteconfirmodel={this.closedeleteconfirmodel}
                                    isallequationdeletefalse={this.isallequationdeletefalse}
                                    setShowSolutionBtnVisibility={this.props.setShowSolutionBtnVisibility}
                                    isReview={this.props.isReview}
                                    isGuided={this.props.isGuided}
                                    isGraded={this.props.isGraded}
                                    totalTrials={this.props.totalTrials}
                                    isTimed={this.props.isTimed}
                                    assignmentTime={this.props.assignmentTime}
                                    timeLapseString={this.props.timeLapseString}
                                    isFromAssignment={this.props.isFromAssignment}
                                    saveuser_Actions={this.saveuser_Actions}
                                    capture_image_upload={this.capture_image_upload}
                                    studentId={this.props.studentId}
                                    studentRole={this.props.studentRole}
                                  >
                                  </Canvas>
                                </CanvasProvider>
                                {this.props.isAnyProblem ?

                                  <div className="wirisAnyProblem">
                                    <CkEditorComponent ckEditorId="editorInitialSkP1"
                                      editorHeight={this.state.editorHeight}
                                      placeHolderText=""
                                      onCKEditorDataChange={this.ckEditorDataChangedEvent}
                                    />
                                  </div>
                                  : null}
                              </div>
                              {/* {
                                detectDevice() === DESKTOP ?
                                  <div style={{ marginLeft: "20px" }}>
                                    <span style={{ backgroundColor: "#f5f588", fontSize: "16px", padding: "3px" }}>
                                      Enter equations <strong>step-by-step,</strong> and see detailed help Or <strong> skip to the final answer,</strong> as equation.</span></div>
                                  : null
                              } */}
                            </> : null
                          }
                        </div>
                      </div>
                      {
                        this.props.forMobile ?
                          <>
                            {
                              this.props.questiontype !== "FRQ" ? null :
                                (
                                  <div
                                    style={{ position: 'fixed', top: 'auto', bottom: '57px', maxWidth: '98%' }}
                                    className={this.props.setShowSolutionBtnVisibility ? this.right_Panel_Classes + " whiteBoarddisable" : this.right_Panel_Classes}
                                  >
                                    <div>
                                      <div id="equationContainer" style={{ padding: '0px' }} className={`equationContainer iconcss ${this.state.isVisible ? '' : 'containerOut'}`}>
                                        <Accordion defaultExpanded={true} style={{ width: '100%' }}>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Typography>
                                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span className="text-bold"> Your Equation Board
                                                  {detectDevice() === TABLET ?
                                                    <span onClick={() => { this.setState({ showEqI: true }) }}>
                                                      <InfoIcon />
                                                    </span>
                                                    :
                                                    <Tooltip arrow placement="left" title={this.state.equationBoardTooltip} them
                                                      componentsProps={{
                                                        tooltip: {
                                                          sx: {
                                                            paddingBottom: '20px !important',
                                                            fontSize: '13px !important',
                                                            bgcolor: 'white !important',
                                                            color: 'black !important',
                                                            boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.1) !important',
                                                            '& .MuiTooltip-arrow !important': {
                                                              color: 'white !important',
                                                            },
                                                          },
                                                        },
                                                      }}

                                                    >
                                                      <IconButton>
                                                        <InfoIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  }
                                                </span>
                                                {/* {
                                  !showFullApp() ? (<Tooltip title={'Close'} placement='left' arrow>
                                    <ToggleButton className='showEquationTglBtn' onClick={this.props.handleHideShowEquation}>
                                      <img alt="" src={CloseEditEquationIcon}></img>
                                    </ToggleButton>
                                  </Tooltip>) : null
                                } */}
                                              </div >
                                            </Typography >
                                          </AccordionSummary >
                                          <AccordionDetails style={{ height: '90px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                            <Typography>
                                              {
                                                this.state.equations !== undefined && this.state.equations !== null && this.state.equations.length > 0 ?
                                                  (
                                                    <Row>
                                                      <Col sm="12">
                                                        <button className='linkbutton' style={{ paddingLeft: "15px" }}
                                                          disabled={this.props.studentId === 0 ? false : true}
                                                          onClick={(e) => this.opendeleteconfirmodel(e)}>
                                                          <Tooltip arrow placement="left" title="Delete all Equations">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg>
                                                          </Tooltip><span style={{ paddingLeft: "5px" }}>Delete All Equations</span>
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  )
                                                  : null
                                              }

                                              <Row className='mx-0 mb-2 divflex'>
                                                <Col sm="12">
                                                  {
                                                    this.state.equations.map((item, index) => {
                                                      return (

                                                        <div style={{ display: 'flex', marginTop: '10px', paddingLeft: '20px', top: this.hasEquationWithBorder ? (index + 1) * 70 : (index + 1) * 50, zIndex: '0' }}
                                                          className={item.class}>
                                                          {
                                                            this.state.askAIenabled ? (


                                                              <Tooltip title="Get further explanations from the interactive AI tutor." placement='top' arrow >
                                                                <IconButton>
                                                                  <div className={`boardBtnsequations`} style={{ marginTop: "-6px !important" }} onClick={(e) => this.handleequationcheckbox(e, index, item.mathml, item.inputLatex, item.class)}>
                                                                    <img alt="" src={AskAITutIcon} height="22px" width="22px" />
                                                                  </div>
                                                                </IconButton>
                                                              </Tooltip>
                                                            ) : null
                                                          }
                                                          {
                                                            this.props.isAnyProblem ? null :
                                                              <span style={{ textWrap: 'nowrap' }}>Line {index + 1} :</span>
                                                          }
                                                          {
                                                            item.type === CONTENT_TYPE.EQUATION ?
                                                              <div>
                                                                <div style={{ marginLeft: '2px' }} className="d-flex ">

                                                                  <EquationDisplay setid={index} totalEquation={this.state.equations.length}
                                                                    responseText={item.ans === undefined ? '' : item.ans}
                                                                    isanyProb={this.props.isAnyProblem} mathml={item.mathml} />
                                                                  {
                                                                    this.props.isAnyProblem ?
                                                                      index === 0 ? null : (

                                                                        <div style={{ cursor: 'pointer', marginRight: '10px' }} className=" EditDel" data-index={item.opid}
                                                                          onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}
                                                                          onTouchStart={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>
                                                                          <Tooltip arrow placement="left" title="Click here to edit the equation">
                                                                            <button style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons" onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Edit</button>
                                                                          </Tooltip>
                                                                        </div>)
                                                                      :
                                                                      <div style={{ cursor: 'pointer', marginRight: '10px' }} className=" EditDel"
                                                                        data-index={item.opid}
                                                                        onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'))}
                                                                        onTouchStart={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'))}>
                                                                        <Tooltip arrow placement="left" title="Click here to edit the equation">
                                                                          <button disabled={this.props.studentId === 0 ? false : true} style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons" onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Edit</button>
                                                                        </Tooltip>
                                                                      </div>

                                                                  }
                                                                  {this.props.isAnyProblem ? null :
                                                                    <div style={{ cursor: 'pointer', marginRight: '10px' }} className=" EditDel" data-index={item.opid} onClick={(e) => { this.deleteEquation(e.currentTarget.getAttribute('data-index')) }}
                                                                      onTouchStart={(e) => { this.deleteEquation(e.currentTarget.getAttribute('data-index')) }} >
                                                                      <Tooltip arrow placement="left" title="Click here to delete the equation">
                                                                        <button style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons"
                                                                          disabled={this.props.studentId === 0 ? false : true} onClick={(e) => this.deleteEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Delete</button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  }
                                                                </div>
                                                              </div>
                                                              :
                                                              <div style={{ marginBottom: '15px' }}>{item.text}</div>
                                                          }

                                                        </div>

                                                      )
                                                    })
                                                  }
                                                </Col>
                                              </Row>


                                            </Typography>
                                          </AccordionDetails>
                                        </Accordion >
                                      </div >
                                    </div >
                                  </div >
                                )
                            }
                          </>
                          :
                          (
                            <div style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', height: '91vh' }}
                              //className={this.right_Panel_Classes}
                              className={this.props.setShowSolutionBtnVisibility ? this.right_Panel_Classes + " whiteBoarddisable" : this.right_Panel_Classes}>
                              <div className="equationcontainer_any"
                                style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', height: `${this.props.eHeight}` }}
                              >
                                <div id="equationContainer" className={`equationContainer ${this.state.isVisible ? '' : 'containerOut'}`}>
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span className="text-bold"> Your Equations
                                      {detectDevice() === TABLET ?
                                        <span onClick={() => { this.setState({ showEqI: true }) }}>
                                          <InfoIcon />
                                        </span>
                                        :
                                        <Tooltip arrow placement="left" title={this.state.equationBoardTooltip} them
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                paddingBottom: '20px !important',
                                                fontSize: '13px !important',
                                                bgcolor: 'white !important',
                                                color: 'black !important',
                                                boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.1) !important',
                                                '& .MuiTooltip-arrow !important': {
                                                  color: 'white !important',
                                                },
                                              },
                                            },
                                          }}

                                        >
                                          <IconButton>
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip>
                                      }
                                    </span>
                                    {this.props.isGuided === "false"
                                      // || this.props.isReview === "true" 
                                      ? null :
                                      this.props.isAnyProblem || this.props.question.type === 'MSQ' || this.props.question.type === 'MCQ' ? null :
                                        detectDevice() === TABLET ?
                                          <Button className="btnEvaluate" disabled={this.props.actionBtnDisabled} onClick={this.props.checkMyWorkHandler}>
                                            <TaskAltIcon tooltip="Evaluate your solution and receive step-by-step feedback." style={{ color: '#fff' }} /> Evaluate My Work
                                          </Button>
                                          :
                                          <Tooltip title="Evaluate your solution and receive step-by-step feedback." placement='right' arrow >
                                            <Button className="btnEvaluate" disabled={this.props.actionBtnDisabled} onClick={this.props.checkMyWorkHandler}>
                                              <TaskAltIcon tooltip="Evaluate your solution and receive step-by-step feedback." style={{ color: '#fff' }} /> Evaluate My Work
                                            </Button>
                                          </Tooltip>
                                    }
                                    {
                                      !showFullApp() ? (<Tooltip title={'Close'} placement='left' arrow>
                                        <ToggleButton className='showEquationTglBtn' onClick={this.props.handleHideShowEquation}>
                                          <img alt="" src={CloseEditEquationIcon}></img>
                                        </ToggleButton>
                                      </Tooltip>) : null
                                    }
                                  </div>
                                  {console.log("st id - " + this.props.studentId)}
                                  <Divider sx={{ width: "98%" }} className="mt-2 mb-2" />
                                  {
                                    this.state.equations !== undefined && this.state.equations !== null && this.state.equations.length > 0 ? (
                                      <div className="d-flex align-items-center justify-content-between pb-3">
                                        <div className="col-sm-9 col-md-9 col-lg-9 align-items-center">
                                          <button className='linkbutton'
                                            disabled={this.props.studentId === 0 ? false : true}
                                            onClick={(e) => this.opendeleteconfirmodel(e)}>
                                            <Tooltip arrow placement="left" title="Delete all Equations">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                              </svg>
                                            </Tooltip><span style={{ paddingLeft: "5px" }}>Delete All Equations</span>
                                          </button>
                                        </div>
                                        {detectDevice() === TABLET ? null :
                                          <div className="col-sm-3 col-md-3 col-lg-3 align-items-center actionbuttons">Actions</div>
                                        }

                                      </div>) : null
                                  }

                                  {
                                    this.state.equations.map((item, index) => {
                                      return (
                                        <div
                                          style={{ marginTop: '10px', top: this.hasEquationWithBorder ? (index + 1) * 70 : (index + 1) * 50, zIndex: '0' }}
                                          className={`${item.class} d-flex align-items-center justify-content-between pb-3`}>
                                          {
                                            this.state.askAIenabled ? (

                                              <Tooltip title="Get further explanations from the interactive AI tutor." placement='top' arrow >
                                                <IconButton>
                                                  <div className={`boardBtnsequations`} onClick={(e) => this.handleequationcheckbox(e, index, item.mathml, item.inputLatex, item.class)}>
                                                    <img alt="Get further explanations from the interactive AI tutor." src={AskAITutIcon} height="22px" width="22px" />
                                                  </div>

                                                </IconButton>
                                              </Tooltip>
                                            ) : null
                                          }

                                          {
                                            item.type === CONTENT_TYPE.EQUATION ?
                                              <>
                                                <div className="col-sm-9 col-md-9 col-lg-9">
                                                  {
                                                    this.props.isAnyProblem ? null :
                                                      <div className="d-flex justify-content-start align-items-center">
                                                        <span style={{ textWrap: 'nowrap' }}>Line {index + 1} : </span>
                                                        <span style={{ marginLeft: "5px" }}>
                                                          <EquationDisplay setid={index} totalEquation={this.state.equations.length}
                                                            responseText={item.ans === undefined ? '' : item.ans}
                                                            isanyProb={this.props.isAnyProblem} mathml={item.mathml} />
                                                        </span>

                                                      </div>
                                                  }
                                                  {detectDevice() === TABLET ?
                                                    <div>
                                                      <div className="col-sm-3 col-md-3 col-lg-3 d-flex justify-content-start align-items-center">
                                                        {
                                                          this.props.isAnyProblem ?
                                                            index === 0 ? null : (

                                                              <div style={{ cursor: 'pointer', marginRight: '5px' }} className=" EditDel" data-index={item.opid}
                                                                onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}
                                                                onTouchStart={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>
                                                                <Tooltip arrow placement="left" title="Click here to edit the equation">
                                                                  <button style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons" onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Edit</button>
                                                                </Tooltip>
                                                              </div>)
                                                            :
                                                            <div style={{ cursor: 'pointer', marginRight: '5px' }} className=" EditDel"
                                                              data-index={item.opid}

                                                              onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'))}
                                                              onTouchStart={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'))}>
                                                              <Tooltip arrow placement="left" title="Click here to edit the equation">
                                                                <button disabled={this.props.studentId === 0 ? false : true} style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons" onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Edit</button>
                                                              </Tooltip>
                                                            </div>
                                                        }
                                                        {this.props.isAnyProblem ? null :
                                                          <div style={{ cursor: 'pointer', marginLeft: '10px' }} className=" EditDel" data-index={item.opid}
                                                            onClick={(e) => { this.deleteEquation(e.currentTarget.getAttribute('data-index')) }}
                                                            onTouchStart={(e) => { this.deleteEquation(e.currentTarget.getAttribute('data-index')) }} >
                                                            <Tooltip arrow placement="left" title="Click here to delete the equation">
                                                              <button style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons"
                                                                disabled={this.props.studentId === 0 ? false : true} onClick={(e) => this.deleteEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Delete</button>
                                                            </Tooltip>
                                                          </div>
                                                        }
                                                      </div>
                                                      <div className='titleorcss'></div>
                                                    </div>
                                                    : null
                                                  }
                                                </div>

                                                {detectDevice() !== TABLET ?

                                                  <div className="col-sm-3 col-md-3 col-lg-3 d-flex justify-content-start align-items-center">
                                                    {
                                                      this.props.isAnyProblem ?
                                                        index === 0 ? null : (

                                                          <div style={{ cursor: 'pointer', marginRight: '5px' }} className=" EditDel" data-index={item.opid}
                                                            onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}
                                                            onTouchStart={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>
                                                            <Tooltip arrow placement="left" title="Click here to edit the equation">
                                                              <button style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons" onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Edit</button>
                                                            </Tooltip>
                                                          </div>)
                                                        :
                                                        <div style={{ cursor: 'pointer', marginRight: '5px' }} className=" EditDel"
                                                          data-index={item.opid}

                                                          onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'))}
                                                          onTouchStart={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'))}>
                                                          <Tooltip arrow placement="left" title="Click here to edit the equation">
                                                            <button disabled={this.props.studentId === 0 ? false : true} style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons" onClick={(e) => this.editEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Edit</button>
                                                          </Tooltip>
                                                        </div>
                                                    }
                                                    {this.props.isAnyProblem ? null :
                                                      <div style={{ cursor: 'pointer', marginLeft: '10px' }} className=" EditDel" data-index={item.opid}
                                                        onClick={(e) => { this.deleteEquation(e.currentTarget.getAttribute('data-index')) }}
                                                        onTouchStart={(e) => { this.deleteEquation(e.currentTarget.getAttribute('data-index')) }} >
                                                        <Tooltip arrow placement="left" title="Click here to delete the equation">
                                                          <button style={{ fontSize: '12px', textDecoration: 'underline' }} className="actionbuttons"
                                                            disabled={this.props.studentId === 0 ? false : true} onClick={(e) => this.deleteEquation(e.currentTarget.getAttribute('data-index'), item.eqindex)}>Delete</button>
                                                        </Tooltip>
                                                      </div>
                                                    }
                                                  </div>

                                                  : null
                                                }

                                              </>

                                              :
                                              <div style={{ marginBottom: '15px' }}>{item.text}</div>
                                          }

                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          )
                      }
                      {

                        <div className={this.askAI_panel_Classes} style={{ display: this.state.askAIenabled ? 'flex' : 'none', flexGrow: '1', flexDirection: 'column', height: '91vh', backgroundColor: "#ECF1FE" }}>
                          <div className='anyProblemChartBot'>
                            <AnyProblem
                              handleremovequestions={this.handleremovequestions}
                              equationClass={this.state.equationClass}
                              askAIBgColor={this.state.askAIBgColor}
                              isInline={false}
                              question={this.state.askAIquestion}
                              onClose={() => this.props.closeAskAITutor()}
                              challengeId={this.props.challengeId}
                              boardId={this.state.boardId}
                              equationClick={this.state.equationClick}
                              handleequationclick={this.handleequationclick}
                              problem_source={this.props.problem_source}
                              hinTextforAIAskTutorQuestion={this.props.hinTextforAIAskTutorQuestion}
                              forHintAITutor={this.props.forHintAITutor}

                              handleremoveAskAIBgcolor={this.handleremoveAskAIBgcolor}></AnyProblem>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                  :
                  <div className="aww textAnswerContainer textBoardHeight border-right">
                    <div className="yourAnswerTitle">Your Answer:</div>
                    <TextComponent id='textComponent'
                      value={this.state.savedText}
                      textChangeHandler={this.textComponentChangeHandler}
                      onFocusOut={this.saveText}
                      removeText={this.removeText} />
                  </div>
              }
              {
                !this.props.isAnyProblem ?
                  <Draggable
                    cancel=".wrs_editor ,.wrs_canvasContainer, .wrs_focusElement, .wiris-resizer-small, .wiris-resizer-medium, .wiris-resizer-large, .wiris-button"
                  >
                    <div className="handwriting-content" id="modal">
                      <div className='wiris-resize-container ml-auto'>
                        <span className='wiris-resizer-small wirisSmall' onClick={() => this.setState({ wirisSize: 'small' })}></span>
                        <span className='wiris-resizer-medium' onClick={() => this.setState({ wirisSize: 'medium' })}></span>
                        <span className='wiris-resizer-large' onClick={() => this.setState({ wirisSize: 'large' })}></span>
                        <div onClick={this.closeWirisModal} className='mr-2 wiris-button' style={{ fontSize: "22px", cursor: "pointer", marginLeft: "4px" }}>
                          <i class="fa fa-close" style={{ fontSize: "20px", boxShadow: "none", textDecoration: 'none', }}
                          ></i></div>
                      </div>
                      <div id="editorContainer" className={'wiris-size-' + this.state.wirisSize}></div>
                      <div className='d-flex justify-content-center mt-2'>
                        <button disabled={this.props.actionBtnDisabled} className='mr-2 wiris-button' onClick={this.handleMultipleEquations}>Add Equation(s)</button>                      </div>
                    </div>
                  </Draggable> : null
              }
            </div >
          </div >
          <ContentOptionsPopup
            showContentOptions={this.state.showContentOptions}
            handleClose={() => this.setState({ showContentOptions: false })}
            body={this.contentModalBody}
            contentOptionsPosition={this.state.contentOptionsPosition} />
        </div >

        <ToastContainer />


        <Modal1
          show={this.state.showWarningModal}
          handleClose={() => this.handleWarningPopuClose()}
          title={this.modalTitle}
          body={this.state.warningModalBody}
          showButton={true}
          draggable={false} />

        <Modal1
          show={this.state.showEqI}
          handleClose={() => this.handleEqIClosemodal()}
          body={this.state.equationBoardTooltip}
          showButton={true}
          hideHeader={true}
          draggable={false} />

      </Aux >

    );
  }
}

export default SketchpadBoard;