import React from 'react';
import { withRouter } from 'react-router-dom';
import "./AnyProblem.scss";
import { askAnyProblemQuestion, askChatGPT, generateLatexFromMathML, checkmyworkfeedbackthumbsupdownupdate } from '../../../common/API';
import { isEmpty, isNil, random } from 'lodash';
import BrowseImageIcon from '../../../assets/images/icon-browse-image.svg';
import EditEquationIcon from '../../../assets/images/icon-calculation.svg';
import { InteractiveType, getCurrentUserId, getSessionId, showFullApp, equationContainerClass, formatAnyProblemAPIResponse, UserEvaluateFeedback, compressBase64Image, detectDevice, TABLET } from '../../../common/Functions';
import { getLatexFromImage, getStrokesToken } from '../../canvas/Api';
import Tooltip from '@mui/material/Tooltip';

import Draggable from "react-draggable";
import parse from 'html-react-parser';
import Close from '@mui/icons-material/Close';
import LogoSign from '../../../assets/images/logo-sign.svg';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-classic-with-mathtype'; //'@ckeditor/ckeditor5-build-classic';

import { Button } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import MathJaxRenderer from '../../Common/MathJaxRenderer/MathJaxRenderer';
import { IconButton } from '@mui/material'
import HintIcon from '../../../assets/images/icon-hint.svg';
import ShowFullSolutionIcon from '../../../assets/images/icon-show-full-solution.svg';
import ShowNextStepIcon from '../../../assets/images/icon-show-next-step.svg';
import ScanIcon from '../../../assets/images/Scan.svg';
import Flow from '../../SolutionGraph/flow';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UploadImageIcon from '../../../assets/images/icon-upload-image.svg';
import Modal from '../../Common/ModalComponent/ModalComponent';
import Aux from '../../../hoc/Wrapper';
import CalcIcon from '../../../assets/images/icon-calculation.svg';



const toolbar = ['MathType'];


class AnyProblem extends React.Component {

    question = '';
    loaderClass = '';
    hasChatGptAns = true;
    idSuffix = '';
    aiPlatoTrained = false;
    videoTimeStamp = 0;
    firstQuestion = true;
    isFirstCall = true;
    sessionId = null;
    hasTrainedQuestion = false;
    isSessionStarted = false;
    anyWirisEditor = null;
    questiondata = "";
    modalBody = null;
    tutormodalbody = null;
    modalTitle = null;
    showButton = false;
    customButton = null;
    latexData = [];
    imageBase64 = ''
    draggable = false;


    constructor(props) {
        super(props);
        this.question = this.props.isInline ? this.props.question : '';
        if (this.props.isInline) {
            this.aiPlatoTrained = this.props.isInline ? true : false;
        }

        this.videoTimeStamp = this.props.videoTimeStamp ? parseInt(this.props.videoTimeStamp) : 0;
        this.state = {
            showComponent: true, questions: [], questionCount: 0, isChatWindowOpen: false
            , questionText: '', askChatGPT: this.props.isInline ? false : true, problemImage: null, lastAnswer: '', lastAnswerText: '', lastAnswerType: 'text', wirisSize: 'medium'
            , question_data: "", pseditorData: "",
            pseditor: null, bgColor: [], showModal: false

        };

    }


    componentDidMount() {
        if (!isNil(document.getElementById('anyWirisEditorContainer')) && isNil(this.anyWirisEditor) && !isNil(window.com) && !isNil(window.com.wiris.jsEditor)) {
            this.anyWirisEditor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en', 'toolbar': '<toolbar ref="general" removeLinks="true"><removeTab ref="arrows"/><removeTab ref="symbols"/><tab ref="general"><removeItem ref="numberPi"/></tab></toolbar>' });
            this.anyWirisEditor.insertInto(document.getElementById('anyWirisEditorContainer'));
        }

    }
    componentDidUpdate(prevProps) {
        //debugger;

        if (this.props.selectedTab === InteractiveType.AnyProblem && !this.isSessionStarted) {
            this.submitQuestion('', false, true);
            this.isSessionStarted = true
        }

        if (!isNil(document.getElementById('anyWirisEditorContainer')) && isNil(this.anyWirisEditor) && !isNil(window.com.wiris) && !isNil(window.com.wiris.jsEditor)) {
            this.anyWirisEditor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en', 'toolbar': '<toolbar ref="general" removeLinks="true"><removeTab ref="arrows"/><removeTab ref="symbols"/><tab ref="general"><removeItem ref="numberPi"/></tab></toolbar>' });
            this.anyWirisEditor.insertInto(document.getElementById('anyWirisEditorContainer'));
        }

        if (this.firstQuestion && (!isNil(this.props.boardId) || this.props.isWOP)) {
            this.sessionId = Date.now();
            let newQuestion = { que: '', ans: '', type: 'text', bgColor: false, equationClass: "" };
            this.setState({ questions: [...this.state.questions, newQuestion] });
            this.askChatGPT('hello')
        }

        // if (prevProps !== this.props && this.props.isWOP) {
        //     let question = this.props.question
        //     if (question !== "" && question !== '') {
        //         if (question !== this.state.question_data) {
        //             this.sessionId = this.props.sessionId;
        //             this.firstQuestion = false;
        //             this.setState({ question_data: question, questionText: question });
        //             let newQuestion = { que: '', ans: '', type: 'text', src: '', bgColor: '', equationclass: '', equationClick: '' }
        //             this.setState({ questions: [...this.state.questions, newQuestion] });
        //             this.askChatGPT(question);

        //         }
        //     }

        // } else 
        if (prevProps !== this.props && !this.props.isWOP && (this.props.forHintAITutor === undefined || this.props.forHintAITutor === false)) {
            //debugger;
            let question = this.props.question
            if (question !== "" && question !== '') {
                if (question !== this.state.question_data) {
                    this.setState({ pseditorData: question, question_data: question, questionText: question })
                }
            }
        }
        else {
            if (this.props.fromPTTest !== undefined && this.props.fromPTTest === true) {
                let question = this.props.question
                if (question !== "" && question !== '') {
                    if (question !== this.state.question_data) {
                        this.setState({ pseditorData: question, question_data: question, questionText: question })
                    }
                }
            }
            if (this.props.forHintAITutor !== undefined && this.props.forHintAITutor === true) {
                let question = this.props.hinTextforAIAskTutorQuestion
                if (question !== "" && question !== '') {
                    if (question !== this.state.question_data) {
                        this.setState({ pseditorData: question, question_data: question, questionText: question })
                    }
                }
            }
        }
    }

    submitQuestion(q = '', fromImage = false, isFirstQues = false, imgSrc = '', bgColor = false, equationclass = "", equationClick = false, isEvaluate = false) {

        if (((this.question !== '' || isEvaluate) || isFirstQues || fromImage) && this.state.showComponent) {
            if (!this.question.toLocaleLowerCase().includes("show full solution")) {
                let newQuestion = { que: fromImage ? this.imageBase64 : this.question, ans: '', type: fromImage ? 'image' : 'text', bgColor: bgColor, equationclass: equationclass, equationClick: equationClick }
                this.setState({ questions: [...this.state.questions, newQuestion] });
                setTimeout(() => { this.scrollChartToBottom() }, 200)

            }
            this.setState({ isloader: true })
            this.askChatGPT(isEvaluate ? "aiPlato Check My Work " + this.question : this.question);
        }

        if (!this.props.isInline) {
            this.question = '';
        }

        this.setState({
            question_data: "", pseditorData: "",
            pseditor: null, questionText: ''
        })
        if (!isNil(this.props.handleremoveAskAIBgcolor)) {
            this.props.handleremoveAskAIBgcolor();
        }

        if (!this.props.isWOP) {
            this.props.handleremovequestions()
            this.props.handleequationclick()

        }

        // document.getElementById('anyProblemquestionText').value = ''
        const domEditableElement = document.querySelector('.ck-editor__editable')
        const editorInstance = domEditableElement.ckeditorInstance;
        editorInstance.setData('');

    }


    askChatGPT = (quesValue = '') => {
        //debugger;
        this.loaderClass = !quesValue.toLocaleLowerCase().includes("show full solution") ? 'loaderContainer' : "";
        let ans = '';
        this.hasChatGptAns = true;
        this.hasChart = false;
        //if (!this.props.isInline) {

        this.aiPlatoTrained = this.state.askChatGPT ? false : true;
        //}
        if (!isNil(quesValue)) {
            //debugger;

            let params = new URLSearchParams();
            params.append('InputMessage', quesValue);
            params.append('isNew', this.firstQuestion);
            params.append('sessionid', this.sessionId);
            params.append('userid', getCurrentUserId());
            params.append('source', this.props.isWOP ? "WOP" : "FRQ");
            params.append('challenge', this.props.challengeId);
            params.append('board', this.props.boardId)
            params.append('image', this.imageBase64)
            params.append('latex', JSON.stringify(this.latexData))

            this.latexData = [];
            this.imageBase64 = ''

            askAnyProblemQuestion(params).then(res => {
                //  debugger;
                this.setState({ isloader: false })
                if (!quesValue.toLocaleLowerCase().includes("show full solution")) {
                    debugger;
                    if (!isNil(res.data.response)) {
                        if (!Array.isArray(res.data.response)) {
                            this.setAnswer(res.data.response);
                        } else {
                            let str = ''
                            res.data.response.forEach(element => {
                                str += str === '' ? element : " \n " + element
                            });
                            this.setAnswer(formatAnyProblemAPIResponse(str));
                        }
                    } else if (!isNil(res.data.ui_json)) {
                        this.setAnswer(res.data.ui_json, 'flow')
                    }


                    this.loaderClass = '';
                    this.setState({
                        placeHolderText: res.data.response, buttonDisabled: false, question_data: "", pseditorData: "",
                        pseditor: null, questionText: ''
                    })

                    this.props.handleremovequestions()
                    this.props.handleequationclick()
                    this.props.handleremoveAskAIBgcolor();
                } else {
                    if (res.data.response !== undefined && res.data.response !== null && res.data.response !== "") {
                        let finalresponse = formatAnyProblemAPIResponse(res.data.response)
                        let contentdata = (
                            <Aux>
                                <div className='modal-p mt-3'>

                                    <MathJaxRenderer math={finalresponse}></MathJaxRenderer>
                                </div>

                            </Aux>
                        )
                        let modalBody = (
                            <>{contentdata}</>
                        );
                        this.openModal(null, 'Show Full Solution', modalBody, null, true, true);

                    } else if (!isNil(res.data.ui_json)) {

                        let contentdata = (
                            <Aux>
                                <div className='modal-p mt-3'>
                                    <div className="solutionGraphCotainer">
                                        <p className="sidebar-heading mt-2">Solution Graph</p>
                                        <div className="legendContainer">
                                            <div className="legendItemContainer">
                                                <div className="correct"></div>
                                                <span>Expected Step</span>
                                            </div>

                                            <div className="legendItemContainer">
                                                <div className="optional"></div>
                                                <span>Intermediate Step</span>
                                            </div>
                                        </div>
                                        <Flow readOnly={true} uiJson={res.data.ui_json} hasCheckMyWOrk={false} ></Flow>
                                    </div>
                                </div>

                            </Aux>
                        )
                        let modalBody = (
                            <>{contentdata}</>
                        );

                        this.openModal(null, 'Show Full Solution', modalBody, null, true, true);

                    }
                }

            }).catch((err) => {
                this.setState({ isloader: false })
                return err;
            })

            this.firstQuestion = false;
        }


    }

    getMathMl = (mathml) => {
        let returnArr = [];
        mathml = mathml.replace(/\n/g, " ");
        if (mathml.search('<mtable') !== -1) {
            // Create a DOMParser instance
            const parser = new DOMParser();
            // Parse the MathML string
            const mathmlDoc = parser.parseFromString(mathml, 'text/xml');
            // Get the mtable element containing rows
            const mtable = mathmlDoc.querySelector('mtable');
            // Get all mtr (table row) elements
            const rows = mtable.querySelectorAll('mtr');
            // Loop through rows and generate separate MathML strings
            const separateEquations = [];
            rows.forEach(row => {
                // Create a new MathML document for each row
                const newRowDoc = document.implementation.createDocument('http://www.w3.org/1998/Math/MathML', 'math', null);
                const newRowMath = newRowDoc.documentElement;
                // Create a new mtable for the row
                const newMtable = newRowDoc.createElementNS(null, 'mtable');
                newRowMath.appendChild(newMtable);
                // Clone the current row into the new mtable
                newMtable.appendChild(row.cloneNode(true));
                newMtable.removeAttribute('xmlns');
                // Serialize the new MathML document to string
                const newRowMathMLString = new XMLSerializer().serializeToString(newRowDoc);
                // newRowMathMLString.replace(/http://www.w3.org/1998/Math/MathML/g, "");
                // Add the serialized MathML string to the array
                returnArr.push(newRowMathMLString);

            });


        } else {
            returnArr.push(mathml)
        }
        return returnArr;
    }
    openModal = (event, modalTitle, modalBody, customButton = null, showButton = false, draggable = false) => {
        if (event) {
            event.preventDefault();
        }
        this.modalBody = modalBody;
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.customButton = customButton;
        this.draggable = draggable;
        this.setState({ showModal: true });
    }
    setAnswer = (ans, type = 'text') => {
        let o = this.state.questions[this.state.questions.length - 1];
        if (!this.props.isWOP) {
            o['ans'] = this.state.questions.length !== 1 ? formatAnyProblemAPIResponse(ans) : "";
        } else {
            o['ans'] = type === 'text' ? this.state.questions.length !== 1 ? formatAnyProblemAPIResponse(ans) : 'Let’s get started! Feel free to upload an image, scan your problem or simply type/copy-paste it into the textbox below.' : ans;
        }

        if (type !== 'text') {
            o['type'] = type;
        }
        let tempArray = (this.state.questions);
        tempArray.pop();
        tempArray.push(o);
        this.setState({ questionCount: tempArray.length - 1, lastAnswer: '' });
        this.setState({ questions: tempArray });
        setTimeout(this.handleLastAnswer, 300);

    }
    onInputKeyUp = (evt, hasAiPlato) => {
        if (evt.keyCode === 13) {
            this.hasTrainedQuestion = hasAiPlato;
            this.submitQuestion();
        }
    }

    handleLastAnswer = () => {
        if (this.hasChatGptAns) {
            if (this.state.questions[this.state.questions.length - 1]['type'] === 'text') {
                let words;
                const answer = this.state.questions[this.state.questions.length - 1]['ans']
                if (answer.length === 1) {
                    words = answer[0].split(' ');
                }
                else {
                    words = answer.split(' ');
                }
                let count = 0;
                let ansText = '';

                const interval = setInterval(() => {
                    ansText += ' ' + (words[count].includes('\n') ? words[count].replace('\n', '<br />') : words[count]);
                    this.setState({ lastAnswerText: ansText, lastAnswerType: 'text' })
                    if (count === words.length - 1) {
                        this.setState({ lastAnswer: ansText, lastAnswerText: "" })
                        clearInterval(interval);
                        this.state.questions[this.state.questions.length - 1]['ans'] = ansText;
                    }
                    count++;
                    this.scrollChartToBottom();
                }, 100);
            } else {
                this.setState({ lastAnswer: this.state.questions[this.state.questions.length - 1]['ans'], lastAnswerType: 'flow' });
            }


        }
    }

    scrollChartToBottom = () => {
        var element = document.getElementById('anychatContainer');
        if (!isNil(element)) {
            element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
        }
    }
    fetchToken = async () => {
        return await getStrokesToken();
    }


    onCustomeProblemUpload = () => {
        var file = document.querySelector('input[type=file]').files[0];
        var reader = new FileReader();
        reader.onloadend = () => {

            this.setState({ problemImage: reader.result });

            this.latexData = [];
            this.imageBase64 = '';
            this.fetchToken()
                .then(response => {
                    if (!isNil(response)) {
                        getLatexFromImage(response, reader.result)
                            .then(async response => {
                                if (!response.data.error) {
                                    if (this.props.isWOP && this.state.questions.length === 1) {
                                        this.props.setProblemStatement(formatAnyProblemAPIResponse(response.data.text));
                                    }

                                    this.question = this.props.isWOP && this.state.questions.length === 1 ? response.data.text : '';
                                    response.data.data.forEach((item) => {
                                        if (item.type === 'mathml') {
                                            this.latexData.push(item.value);
                                        }
                                    })

                                    this.imageBase64 = reader.result;
                                    this.submitQuestion(response.data.text, true, false, '')
                                    // this.setState({problemImage: response.data.text})
                                } else {
                                    this.question = '';
                                    this.latexData = [];
                                    this.imageBase64 = reader.result;
                                    this.submitQuestion('', true, false, '')
                                }
                            }).catch(err => {
                                console.log("API Error: ", err);
                            })
                    }
                }).catch(err => {
                    console.log("API Error: ", err);
                })


        }
        if (file) {
            reader.readAsDataURL(file);
        }
    }


    ///WIRIS functions
    openWiris = () => {
        document.getElementById('anyWirisModal').style.display = 'block';
        this.anyWirisEditor.focus();
    }

    closeWiris = () => {
        this.anyWirisEditor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>');
        document.getElementById('anyWirisModal').style.display = 'none';
    }
    getMathmlFromWiris = () => {
        let mathml = this.anyWirisEditor.getMathML();
        this.closeWiris();
        const latexReqData = {
            params: {
                mml: mathml
            },
        };
        this.question = mathml
        this.submitQuestion('', false, false, '');

    }


    checkMyworkthumbsupdownhandle = (e, thumbpsup, question, answer, buttonuniqueId, otherbuttonuniquId) => {
        var chfeedbackdata = {
            "userId": getCurrentUserId(),
            "challenge_id": this.props.challengeId,
            "node_id": "",
            "equation": question,
            "thumbsupdown": thumbpsup,
            "feedback": answer,
            "problem_source": this.props.problem_source
        }
        checkmyworkfeedbackthumbsupdownupdate(chfeedbackdata).then(async res => {
            if (res.status === 200) {
                document.getElementById(buttonuniqueId).style.color = "#4252af";
                document.getElementById(otherbuttonuniquId).style.color = "rgba(0, 0, 0, 0.54)";
            }
        }).catch(err => {

            console.error(err.message);
        });
    }

    render() {
        // console.log("........",this.props.askAIBgColor)

        return <>
            <div className='anyProblem'>
                <div className='headercontainer'>
                    <div className='divHeader'>
                        <img className='tourLogo' src={LogoSign} alt="" />
                        <h4 style={{ float: 'left' }}>aiPlato Tutor</h4>
                    </div>
                    {!this.props.isWOP ?
                        <div onClick={() => { this.props.onClose() }} className='divClose'>
                            <Close />
                        </div> : null}

                </div>

                <div id='chatBotContainer' className='chatBotContainer'>
                    <div id={`anychatContainer`} className={this.props.isWOP ? 'chatContainerWOP' : 'chatContainer'}>
                        {this.state.questions.map((item, index) => {
                            //debugger;

                            let spanclass = "defaultbg"
                            let ansspanclass = "defaultqbg"
                            let eqClass = item.equationclass
                            if (eqClass === equationContainerClass.errorContainer) {
                                spanclass = "redbg"
                                // ansspanclass = "correctincorrectqbg"
                            }
                            else if (eqClass === equationContainerClass.correctContainer) {
                                spanclass = "greenbg"
                                // ansspanclass = "correctincorrectqbg"
                            }

                            return (
                                <div>
                                    <div className='chatTextContainer questionContainer'>
                                        {item.type === 'text' ? <span className={spanclass} style={{ display: item['que'] === '' ? 'none' : 'inline-block' }}>{parse(item.que)}</span>
                                            : <img style={{ maxWidth: '400px' }} src={item.que}></img>}

                                    </div>

                                    <div className='chatTextContainer answerContainer' style={{ position: "relative" }}>
                                        {
                                            index !== this.state.questionCount ?
                                                <div className={ansspanclass} style={{ display: item['ans'] === ' ' ? 'none' : 'inline-block' }}>
                                                    <MathJaxRenderer math={parse(item.ans)}></MathJaxRenderer>
                                                </div> :
                                                <div className={ansspanclass} style={{ display: this.state.lastAnswer === ' ' ? 'none' : 'inline-block' }}>
                                                    <><span>{parse(this.state.lastAnswerText)}</span>
                                                        <MathJaxRenderer math={parse(this.state.lastAnswer)} id={`anylastAnswer`} style={{ wordBreak: 'break-word' }}>
                                                        </MathJaxRenderer></>


                                                </div>
                                        }

                                        {
                                            item.ans !== "" && item.ans !== undefined && item.ans !== null && item.ans !== " " ?
                                                (
                                                    <div className='divLikeDislike'>
                                                        <div style={{ cursor: "pointer" }}>

                                                            <ThumbUpIcon id={`${index}up`} fontSize="small" color="action"
                                                                onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsup, item.que, item.ans, `${index}up`, `${index}down`)} />

                                                        </div>
                                                        <div style={{ marginLeft: "2px", marginRight: "5px", cursor: "pointer", marginTop: "1px" }}>
                                                            <ThumbDownIcon id={`${index}down`} fontSize="small" color="action"
                                                                onClick={(e) => this.checkMyworkthumbsupdownhandle(e, UserEvaluateFeedback.thumbsdown, item.que, item.ans, `${index}down`, `${index}up`)}></ThumbDownIcon>

                                                        </div>
                                                    </div>
                                                )
                                                : null
                                        }


                                    </div>

                                </div>
                            );
                        })}
                        <div className={this.loaderClass}></div>
                    </div>
                    <div style={{ marginLeft: "20px", marginTop: "20px", marginBottom : "-10px" }}>
                        <span style={{ backgroundColor: "#f5f588", fontSize: "12px", padding: "3px" }}>
                            Click on <img height="20px" width="20px" alt='' src={CalcIcon} title='Equation keyboard to type in your equations.'></img>
                            &nbsp; to open Equation keyboard to type in your equations.
                        </span>
                    </div>
                    <div className={!this.state.isloader ? 'inputContainer' : 'inputContainer disableInput'}>
                        <div className='searchInput'>

                            <img src={CalcIcon} title='Equation keyboard to type in your equations.' className='wirisIcon'></img>
                            {/* <SearchIcon color="disabled" /> */}
                            <CKEditor
                                id={`anyProblemquestionText`}
                                data={this.state.pseditorData}
                                editor={ClassicEditor}
                                // onReady={(e) => {
                                //     this.setState({ questionText: e.editor })
                                // }}
                                onReady={(editor) => {
                                    this.setState({ pseditor: editor.editor })
                                    editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                            "min-height",
                                            "90px",
                                            editor.editing.view.document.getRoot()
                                        );
                                        writer.setStyle(
                                            "max-height",
                                            "90px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                config={{
                                    placeholder: "Type your question here...",
                                    toolbar: toolbar,
                                    iframe: true,
                                    charCounterCount: false,
                                    htmlAllowedTags: ['.*'],
                                    htmlAllowedAttrs: ['.*'],
                                    htmlAllowedEmptyTags: ['mprescripts'],
                                    imageResize: false,
                                    useClasses: false
                                }}
                                //    config={editorConfiguration}
                                onChange={(e, editor) => { this.setState({ questionText: editor.getData() }) }}
                            />

                            {this.state.questions.length > 1 ?
                                <div id="dibWOPGBtns" className="divWOPGuideBtns">
                                    {detectDevice() === TABLET ?
                                        <IconButton className='equationEditLabel'>
                                            {/* <p style={{ color: "#4353B0" }} className='toolLabel'>Equation Keyboard</p> */}
                                        </IconButton>
                                        :
                                        <Tooltip title="Equation keyboard to type in your equations." placement='right' arrow >
                                            <IconButton style={{ top: '9px' }} className='equationEditLabel'>
                                                {/* <p style={{ color: "#4353B0" }} className='toolLabel'>Equation Keyboard</p> */}
                                            </IconButton>
                                        </Tooltip>
                                    }

                                    {this.props.isWOP ?
                                        detectDevice() === TABLET ?
                                            <>
                                                <IconButton className='equationEditLabel' style={{ flexDirection: 'column', height: '20px' }} onClick={() => { this.question = 'Show me a hint'; this.submitQuestion() }}>
                                                    <div className='boardBtns boardBtns-support'>
                                                        <img alt='Hint Icon' src={HintIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Hint</p>
                                                </IconButton>

                                                <IconButton className='equationEditLabel' style={{ flexDirection: 'column', height: '20px' }} onClick={() => { this.question = 'Show next step'; this.submitQuestion() }}>
                                                    <div className='boardBtns boardBtns-support'>
                                                        <img alt='Ask Human Tutor' src={ShowNextStepIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Next Step</p>
                                                </IconButton>
                                                <IconButton className='equationEditLabel' style={{ flexDirection: 'column', height: '20px' }} onClick={() => { this.question = 'Show Full Solution'; this.submitQuestion() }}>
                                                    <div className='boardBtns boardBtns-support'>
                                                        <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Solution</p>
                                                </IconButton>

                                                <IconButton className='equationEditLabel' style={{ flexDirection: 'column', height: '20px' }} onClick={() => { this.question = this.state.questionText; this.submitQuestion('', false, false, '', false, '', false, true) }}>
                                                    <div className='boardBtns boardBtns-support'>
                                                        <TaskAltIcon style={{ color: '#4353B0' }} />
                                                    </div>
                                                    <p className='toolLabel'>Evaluate</p>
                                                </IconButton>
                                                <IconButton className='equationEditLabel' style={{ flexDirection: 'column', height: '20px' }}>
                                                    <div className='boardBtns boardBtns-support'>
                                                        <img alt='Upload' src={UploadImageIcon} ></img>
                                                    </div>
                                                    <label for="file-upload" className="toolLabel">
                                                        Upload Image
                                                    </label>
                                                    <input id="file-upload" style={{ width: '0' }} className='imageUploadInput' type="file" onChange={() => this.onCustomeProblemUpload()} />
                                                </IconButton>
                                            </>
                                            :
                                            <>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Hint" placement='right' arrow >
                                                    <IconButton style={{ height: '20px' }} onClick={() => { this.question = 'Show me a hint'; this.submitQuestion() }}>
                                                        <div className='boardBtns' >
                                                            <img alt='Hint Icon' src={HintIcon} />
                                                        </div>
                                                        <p className='toolLabel'>Hint</p>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Show Next Step" placement='right' arrow >
                                                    <IconButton style={{ height: '20px' }} onClick={() => { this.question = 'Show next step'; this.submitQuestion() }}>
                                                        <div className={`boardBtns`} >
                                                            <img alt='Ask Human Tutor' src={ShowNextStepIcon} />
                                                        </div>
                                                        <p className='toolLabel'>Show Next Step</p>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                                    <IconButton style={{ height: '20px' }} onClick={() => { this.question = 'Show Full Solution'; this.submitQuestion() }}>
                                                        <div className='boardBtns'>
                                                            <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                                        </div>
                                                        <p className='toolLabel'>Show Full Solution</p>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Evaluate your solution and receive step-by-step feedback." placement='right' arrow>
                                                    <IconButton style={{ height: '20px' }} onClick={() => { this.question = this.state.questionText; this.submitQuestion('', false, false, '', false, '', false, true) }}>
                                                        <div className='boardBtns'>
                                                            <TaskAltIcon style={{ color: '#4353B0' }} />
                                                        </div>
                                                        <p className='toolLabel'>Evaluate My Work</p>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Upload Image" placement='right' arrow >
                                                    <IconButton style={{ height: '20px' }}>
                                                        <div className='boardBtns'>
                                                            <img alt='Upload Image' src={UploadImageIcon} ></img>
                                                        </div>
                                                        <label for="file-upload" className="toolLabel">
                                                            Upload Image
                                                        </label>
                                                        <input id="file-upload" style={{ width: '0' }} className='imageUploadInput' type="file" onChange={() => this.onCustomeProblemUpload()} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }

                            {this.state.questions.length === 1 ?
                                <div className="divWOPGuideBtns">
                                    {detectDevice() === TABLET ?
                                        <IconButton className='equationEditLabel'>
                                            {/* <p style={{ color: "#4353B0" }} className='toolLabel'>Equation Keyboard</p> */}
                                        </IconButton>
                                        :
                                        <Tooltip title="Equation keyboard to type in your equations." placement='right' arrow >
                                            <IconButton style={{ top: '9px' }} className='equationEditLabel'>
                                                {/* <p style={{ color: "#4353B0" }} className='toolLabel'>Equation Keyboard</p> */}
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {this.props.isWOP ?
                                        detectDevice() === TABLET ?
                                            <>
                                                <IconButton className='equationEditLabel equationEditLabel-upload' >
                                                    <label for="file-upload" className="toolLabel uploadBtnLabel">
                                                        <div className='boardBtns'>
                                                            <img for="file-upload" alt='Upload' src={UploadImageIcon} ></img>
                                                        </div>
                                                        <span>Upload Problem</span>
                                                    </label>
                                                    <input id="file-upload" style={{ width: '0' }} className='imageUploadInput' type="file" onChange={() => this.onCustomeProblemUpload()} />
                                                </IconButton>
                                                <IconButton disabled className='equationEditLabel equationEditLabel-scan' onClick={() => { this.question = 'Show next step'; this.submitQuestion() }}>
                                                    <div className={`boardBtns`} >
                                                        <img alt='Scan' src={ScanIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Scan</p>
                                                </IconButton>
                                            </>
                                            :
                                            <>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Upload Image" placement='right' arrow >
                                                    <IconButton style={{ height: '20px' }}>

                                                        <label for="file-upload" className="toolLabel uploadBtnLabel">
                                                            <div className='boardBtns'>
                                                                <img for="file-upload" alt='Upload Image' src={UploadImageIcon} ></img>
                                                            </div>
                                                            <span>Upload Your Problem</span>
                                                        </label>
                                                        <input id="file-upload" style={{ width: '0' }} className='imageUploadInput' type="file" onChange={() => this.onCustomeProblemUpload()} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip style={{ flexDirection: 'column' }} title="Scan" placement='right' arrow >
                                                    <IconButton disabled style={{ height: '20px', opacity: '0.5' }} onClick={() => { this.question = 'Show next step'; this.submitQuestion() }}>
                                                        <div className={`boardBtns`} >
                                                            <img alt='Scan' src={ScanIcon} />
                                                        </div>
                                                        <p className='toolLabel'>Scan</p>
                                                    </IconButton>
                                                </Tooltip>
                                            </>

                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                            <Button title='Ask aiPlato Tutor' onClick={() => {
                                this.question = this.state.questionText
                                let bgColr = false
                                let eqclass = ""

                                if (this.state.question_data !== "" && this.state.question_data !== undefined && this.state.question_data !== null) {
                                    eqclass = this.props.equationClass
                                    if (this.props.askAIBgColor === true)
                                        bgColr = true
                                }
                                if (this.props.isWOP && this.state.questions.length === 1) {
                                    this.props.setProblemStatement(this.state.questionText);
                                    this.submitQuestion('', true, false, '', bgColr, eqclass, this.props.equationClick);
                                } else {
                                    this.submitQuestion('', false, false, '', bgColr, eqclass, this.props.equationClick);
                                }


                            }}>
                                <SendIcon />
                            </Button>
                            {/* <input
                                id={`anyProblemquestionText`}
                                style={{ width: "100%", float: 'left' }}
                                onChange={(evt) => {
                                    this.question = evt.target.value;
                                }}
                                onKeyUp={(evt) => { this.onInputKeyUp(evt, false) }}
                                placeholder="Ask question..."
                                className="labels"
                            /> */}
                        </div>
                        <div className='Otherbtns'>
                            {detectDevice() === TABLET ?
                                <button className='botBtn' onClick={() => this.openWiris()}>
                                    <div className='botBtns'>
                                        <img src={EditEquationIcon} />
                                    </div>
                                </button>
                                :
                                <Tooltip title="Edit Equation" placement='top' arrow>
                                    <button className='botBtn' onClick={() => this.openWiris()}>
                                        <div className='botBtns'>
                                            <img src={EditEquationIcon} />
                                        </div>
                                    </button>
                                </Tooltip>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {showFullApp() ?

                <Draggable
                    cancel=".wrs_editor ,.wrs_canvasContainer, .wrs_focusElement, .wiris-resizer-small, .wiris-resizer-medium, .wiris-resizer-large, .wiris-button"
                >
                    <div className="handwriting-content wirisBox" id="anyWirisModal">
                        <div className='wiris-resize-container ml-auto'>
                            <span className='wiris-resizer-small' onClick={() => this.setState({ wirisSize: 'small' })}></span>
                            <span className='wiris-resizer-medium' onClick={() => this.setState({ wirisSize: 'medium' })}></span>
                            <span className='wiris-resizer-large' onClick={() => this.setState({ wirisSize: 'large' })}></span>
                            <div onClick={() => this.closeWiris()} className='mr-2 wiris-button' style={{ fontSize: "22px", cursor: "pointer", marginLeft: "4px" }}>
                                <i class="fa fa-close" style={{ fontSize: "20px", boxShadow: "none", textDecoration: 'none', }}
                                ></i></div>
                        </div>
                        <div id="anyWirisEditorContainer" className={'wiris-size-' + this.state.wirisSize}></div>
                        <div className='d-flex justify-content-center mt-2'>
                            <button className='mr-2 wiris-button' onClick={() => this.getMathmlFromWiris()} >Ask Question</button>
                            <button className='wiris-button' onClick={() => this.closeWiris()}>Cancel</button>
                        </div>
                    </div>
                </Draggable> :

                <div className="handwriting-content wirisMobile" id="anyWirisModal">
                    <div id="anyWirisEditorContainer"></div>
                    <div className='d-flex justify-content-center mt-2'>
                        <button className='mr-2 wiris-button' onClick={() => this.getMathmlFromWiris()} >Ask Question</button>
                        <button className='wiris-button' onClick={() => this.closeWiris()}>Cancel</button>
                    </div>
                </div>

            }

            <Modal
                show={this.state.showModal}
                handleClose={() => { this.setState({ showModal: false }) }}
                title={this.modalTitle}
                body={this.modalBody}
                showButton={this.showButton}
                customButton={this.customButton}
                draggable={this.draggable} />

        </>

    }
}

export default withRouter(React.memo(AnyProblem));