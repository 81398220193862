import React, { useEffect, useRef, useState } from "react";
import { useCanvas } from "./CanvasContext";
import {
  HintMainButton, UploadImage, CalculationButton, PenButton,
  MoreButton, HintButton, AskTutorButton, CheckMyWorkButton, SubmitWorkButton,
  ShowNextStepButton, ShowSolutionButton, KnowledgeGraphButton,
  CheckStudentWorkButton, SaveToTranscriptButton, SynopsisButton, EnterAnswerButton, HandButton, RedoButton, UndoButton
  , ClearCanvasButton
  , FullScreenButton
  , ShowEquationButton
  , SubmitButtonMobile,
  AskAITutorButton,
  SaveMyWorkButton,
  CalculateGradeButton,
  EraseButton
} from './CanvasUtils'
import useWebSocket from 'react-use-websocket';
import { isEmpty, isNil } from 'lodash';
import './canvas.css'
import { MenuItems, Institute, timeLapseCounter } from "../../common/Constants";
import { storeCameraImage, saveUpdateFRQEquations, getFRQEquations } from "../../common/API";
import { User_Role, showFullApp, Problem_source, getInstituteId, convertDurationToSeonds, convertSecondsToHMS } from "../../common/Functions";
import configData from '../../common/config.json';
import Tour from "../../container/Tour/Tour";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Cookies from 'universal-cookie';
import SubmitWorkIcon from '../../assets/images/icon-submit-work.svg';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material'

const WS_URL = configData.WSS_URL // 'wss://kjlkkz8ddi.execute-api.us-east-2.amazonaws.com/production';

function CanvasInternal(props) {

  const [canvascontainerclass, setcanvascontainerclass] = useState("canvas-container")
  const {
    canvasRef,
    prepareCanvas,

  } = useCanvas();

  useEffect(() => {
    if (props.isAnyProblem) {
      setcanvascontainerclass("canvas-containerNone")
    }
    else {
      setcanvascontainerclass("canvas-container")
    }
    prepareCanvas();
  }, []);

  return (
    <div id="canvas_Parent" className={canvascontainerclass}>
      <canvas
        id={props.id}
        ref={canvasRef}
        className="canvas"
      />
    </div>
  );
}

const cookies = new Cookies();

function Canvas(props) {
  const {
    apiResponse,
    strokes,
    drawInitialGraphicsFromServer,
    windowResize,
    setMenuItem,
    readFromImage,
    clearCanvas,
    updateCanvasCount,
    redraw,
    isStrokesUpdated,
    eventType,
    fromImage,
    isDrawing,
    drawSavedWork,
    clearCurrentDrawing
  } = useCanvas();
  const [initialDrawingForTeacher, setInitialDrawingForTeacher] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItems.Pen);
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL);
  const [isShowSolutionDone, setisShowSolutionDone] = useState(false)
  const [canvasCount, setCanvasCount] = useState(1);
  const [canvasInternalCollection, setCanvasInternalCollection] = useState([<CanvasInternal id='1_canvas' isAnyProblem={props.isAnyProblem} />])
  //console.log("check submit button", props.leftpanelbuttonhide)
  const [hintOptionsShow, setHintoptionsShow] = useState(false);
  const [imageReadInterval, setImageReadInterval] = useState(null);
  const [hideUploadMenu, setHideUploadMenu] = useState(false);
  const [deleteconfirmodelopen, setdeleteconfirmodelopen] = useState(false);
  const [remainingTime, setremainingTime] = useState();

  useEffect(() => {
    if (props.isTimed === "true") {
      let timeLapseString = props.timeLapseString
      let timeLapseInSec
      const interval = setInterval(() => {
        let initialTime = timeLapseString
        let initialTimeInSec = convertDurationToSeonds(initialTime)

        timeLapseInSec = initialTimeInSec - (timeLapseCounter / 1000)
        timeLapseString = convertSecondsToHMS(timeLapseInSec)

        setremainingTime(timeLapseString)
        // console.log('Time lapse after 1 second is - ' + timeLapseString);
      }, timeLapseCounter) /// 1 sec Interval      

      if (timeLapseInSec <= 0) {
        clearInterval(interval)
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [props.isTimed])


  useEffect(() => {

    if (lastMessage !== null) {
      console.log('socket', lastMessage.toString())
      let msg = JSON.parse(lastMessage.data)
      if (msg.type === 'contentchange') {
        if (msg.content && props.userRole !== msg.content.userRole && msg.content.boardId === props.boardId) {
          if (props.userRole === true && !isNil(msg.content.strokes) && !isEmpty(msg.content.strokes)) {
            if (!initialDrawingForTeacher) {
              setInitialDrawingForTeacher(true);
            }
            setCanvasCount(msg.content.canvasCount);
            updateCanvasCount(msg.content.canvasCount)
            drawInitialGraphicsFromServer(msg.content.strokes)
          } else {
            if (!isNil(msg.content.strokes) && !isEmpty(msg.content.strokes) && msg.content.type === 'content') {
              setCanvasCount(msg.content.canvasCount);
              updateCanvasCount(msg.content.canvasCount)
              drawInitialGraphicsFromServer(msg.content.strokes)
            } else if (msg.content.type === 'initial') {
              sendJsonMessage({
                "action": "broadcastMessage",
                "message": {
                  "type": 'contentchange',
                  "content": { drawing: null, strokes: strokes, userRole: props.userRole, boardId: props.boardId, canvasCount: canvasCount }
                }
              });
            }

          }

        }
      } else if (msg.type === 'contentupload') {
        // if (!isNil(msg.content) && msg.content.userId === props.userId.toString() && msg.content.challengeId === props.challengeId && msg.content.boardId === props.boardId) {
        //   let params = {
        //     user_id: msg.content.userId,
        //     req_type: 'del',
        //     session_id: msg.content.sessionId,
        //   }
        //   storeCameraImage(params).then(res => {
        //     readFromImage(res.data.image_text)
        //   })

        // }
      }
    }
  }, [lastMessage]);


  useEffect(() => {
    if (props.hasActiveConference && isStrokesUpdated) {
      if (props.userRole === true) {
        sendJsonMessage({
          "action": "broadcastMessage",
          "message": {
            "type": 'contentchange',
            "content": { drawing: null, strokes: strokes, userRole: props.userRole, boardId: props.boardId, type: 'content', canvasCount: canvasCount }

          }
        });
      } else {
        sendJsonMessage({
          "action": "broadcastMessage",
          "message": {
            "type": 'contentchange',
            "content": { drawing: null, strokes: strokes, userRole: props.userRole, boardId: props.boardId, type: 'content', canvasCount: canvasCount }

          }
        });
      }
    }
  }, [strokes]);

  useEffect(() => {
    if (!initialDrawingForTeacher && props.userRole === true) {
      sendJsonMessage(
        {
          "action": "broadcastMessage",
          "message": {
            "type": 'contentchange',
            "content": { boardId: props.boardId, type: 'initial' }
          }
        }
      );
    }

  }, [props.userRole]);

  useEffect(() => {
    let reqData = {
      user_id: props.userId,
      challenge_id: props.challengeId,
      board_id: props.boardId
    }
    getFRQEquations({ params: reqData }).then(res => {
      if (res.data.equations !== "[]" && res.data.equations !== undefined && !isNil(res.data.equations) && !isEmpty(res.data.equations)) {
        let data = JSON.parse(res.data.equations)
        // data.sort((a, b) => b.canId - a.canId);
        // let maxCanId = data[0].canId;
        // console.log("canvas count from db - " + res.data.canvasCount)
        setCanvasCount(Number(res.data.canvasCount))
        setTimeout(() => {
          drawSavedWork(data)
        }, 3000)
      }
    });
  }, [props.challengeId])

  useEffect(() => {
    if (!props.hasActiveConference) {
      setInitialDrawingForTeacher(false);
    }

  }, [props.hasActiveConference]);



  useEffect(() => {
    if (props.eraseCanvas) {
      //  debugger;
      //clearCanvas(false, true);
      if (strokes.length > 0) {

        setCanvasCount(canvasCount + 1);

        setTimeout(() => {
          redraw(true, true);
        }, 1000)
      }


    }

  }, [props.eraseCanvas]);

  useEffect(() => {
    // debugger;
    if (props.isallequationdelete) {

      clearCurrentDrawing()
      clearCanvas(false, true);
      setdeleteconfirmodelopen(false)
      props.isallequationdeletefalse()
      setMenuItem(MenuItems.Pen);
    }

  }, [props.isallequationdelete]);


  useEffect(() => {
    if (!isNil(apiResponse) && !isNil(apiResponse.mathml)) {
      props.mathplxResponseHandler(apiResponse, fromImage, strokes, canvasCount);
    }

  }, [apiResponse]);

  useEffect(() => {
    setTimeout(() => windowResize(), 1000);
  }, [props.lastAiTutorState]);

  useEffect(() => {

    if (props.isAnyProblem) {
      if (props.anyProblembtnDisabled) {
        let elementcontainer = document.getElementById("canvasbuttons")
        elementcontainer.setAttribute("style", "display:none");
      }
      else {
        let elementcontainer = document.getElementById("canvasbuttons")
        elementcontainer.setAttribute("style", "display:block");
      }
    }

  }, [props.anyProblembtnDisabled]);

  useEffect(() => {
    updateCanvasCount(canvasCount);
    props.updateCanvasCount(canvasCount);
  }, [canvasCount]);

  useEffect(() => {
    if (!isDrawing) {
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);
    }
  }, [isDrawing])

  const eraseCanvas = () => {
    clearCanvas();
  }
  const readFromImageUpload = () => {

    let params = {
      user_id: props.userId.toString(),
      req_type: 'del'
    }
    storeCameraImage(params).then(res => {
      if (!isNil(res.data.image_text) && !isEmpty(res.data.image_text)) {
        readFromImage(res.data.image_text);
        clearInterval(imageReadInterval);
        //setSelectedMenuItemHandler(MenuItems.Pen)
        // setHideUploadMenu(true);
      }
    })
  }
  const setSelectedMenuItemHandler = (menu) => {
    if (selectedMenuItem === MenuItems.Upload && menu === MenuItems.Upload) {
      setSelectedMenuItem('');
      setMenuItem('');
      // setHideUploadMenu(true);
      clearInterval(imageReadInterval)
      props.saveuser_Actions()
      setTimeout(() => {
        props.capture_image_upload()
      }, 9000)
      return;
    }
    else {
      if (selectedMenuItem === MenuItems.Upload) {
        setSelectedMenuItem('');
        setMenuItem('');
        // setHideUploadMenu(true);
        clearInterval(imageReadInterval)
      }
      setSelectedMenuItem(menu);
      setMenuItem(menu);
      if (menu === MenuItems.Upload) {
        // setHideUploadMenu(false);
        clearInterval(imageReadInterval);
        setImageReadInterval(setInterval(() => readFromImageUpload(), 3000))
        //props.saveuser_Actions()
      } else {
        clearInterval(imageReadInterval);
      }

    }

    if (menu === MenuItems.CheckWork) {
      if (props.isAnyProblem) {
        props.checMyWorkForkAnyProblemHandler();
      }
      else {
        props.checkMyWorkHandler();
      }
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);


    } else if (menu === MenuItems.AskAITutor) {
      props.askAITutorHandler();
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);
    } else if (menu === MenuItems.AskTutor) {
      props.askTutorHandler();
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);


    } else if (menu === MenuItems.Hint) {
      props.hintHandler();
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);

    } else if (menu === MenuItems.Submit) {
      if (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") {
        props.submitNonFRQHandler();
      }
      else {
        props.submitHandler();
      }
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);
      //  clearCanvas();
    } else if (menu === MenuItems.Wiris) {
      props.openWirisHandler();
    } else if (menu === MenuItems.NextStep) {
      props.showNextStepHandler();
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);
    } else if (menu === MenuItems.ShowSolution) {
      // if (props.isAnyProblem) {
      //   setisShowSolutionDone(true)
      //   // props.showEntireSolutionHandler()
      // }
      props.showEntireSolutionHandler()
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);
    } else if (menu === MenuItems.KnowledgeGraph) {
      props.getKnowledgeGraphHandler();
    } else if (menu === MenuItems.CheckStudentWork) {
      props.checkMyWorkHandler();
      setSelectedMenuItem(MenuItems.Pen);
      setMenuItem(MenuItems.Pen);
    } else if (menu === MenuItems.SaveToTranscript) {
      props.saveSynopsisEquationToDatabaseHandler();
    } else if (menu === MenuItems.Synopsis) {
      props.endConferenceHandler()
    } else if (menu === MenuItems.EnterAnswer) {
      props.submitHandler();
    } else if (menu === MenuItems.New) {
      props.addNewCanvasHandler();
      setCanvasCount(canvasCount + 1);
    } else if (menu === MenuItems.FullScreen) {
      props.fullScreenHandler();
    } else if (menu === MenuItems.ShowEquation) {
      props.handleShowEquation();
    } else if (menu === MenuItems.Hand) {
      props.usageGuide();
    } else if (menu === MenuItems.Pen) {
      props.setWarningPopup(false)
    } else if (menu === MenuItems.CalculateGrades) {
      props.calculateGradesForPTFRQ();
    } else if (menu === MenuItems.Erase) {
      //props.eraseFromCanvas();

    } else if (menu === MenuItems.SaveMyWork) {
      handleSaveMyWork();
    }
    else if (menu === MenuItems.clearHandwriting) {
      // props.deleteAllequationhandler();
      setdeleteconfirmodelopen(true)
      setSelectedMenuItem(MenuItems.Pen)
      setMenuItem(MenuItems.Pen)
    }
    else if (menu === MenuItems.Undo) {
      setSelectedMenuItem(MenuItems.Pen)
      setMenuItem(MenuItems.Pen)
    }
    else if (menu === MenuItems.Redo) {
      setSelectedMenuItem(MenuItems.Pen)
      setMenuItem(MenuItems.Pen)
    }
  }

  const handleSaveMyWork = () => {
    if (JSON.stringify(strokes) !== "[]") {
      const reqData = {
        "user_id": props.userId,
        "challenge_id": props.challengeId,
        "board_id": props.boardId,
        "equations": JSON.stringify(strokes)
      }

      saveUpdateFRQEquations(reqData).then(res => {
        if (res.data !== undefined && res.data !== null) {
          alert("saved successfully.")
        }

      }).catch(err => {
        console.log("API Error: ", err);
      })
    }
    else {
      alert("There is nothing to save..!")
    }
    // else if (menu === MenuItems.SaveMyWork) {
    //   props.handleSaveMyWork(strokes);
    // }
  }

  const moreToggleVisibility = () => {
    // this.setState({ hintoptionsshow: !this.state.hintoptionsshow });
    setHintoptionsShow(!hintOptionsShow)
  };

  return (
    <>

      <Dialog
        hideBackdrop={false}
        open={deleteconfirmodelopen}
        onClose={props.closedeleteconfirmodel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ marginTop: "10px", color: "#164b99" }}>
              Are you sure you want to clear all the equations?<br />
              This will remove all the equations from the written solution.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="dragLabelcancel leftmargin" onClick={props.deleteAllequationhandler}>
            Yes
          </button>

          <button className="dragLabelcancel leftmargin" onClick={() => { setdeleteconfirmodelopen(false); props.closedeleteconfirmodel() }}>
            No
          </button>


        </DialogActions>
      </Dialog >
      {
        !props.isAnyProblem ? (
          <>
            {
              showFullApp() && (props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ?
                (

                  <div id="canvasbuttonsh" className={`${showFullApp() ? "canvas-buttonh" : "canvas-buttonhmobile"}`}>
                    <div
                      className="canvas-buttons-containerh">
                      <UndoButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      <RedoButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      <ClearCanvasButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      <EraseButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      {/*  <HandButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
                      <FullScreenButton isScreenFull={props.isScreenFull} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      {/* <SaveMyWorkButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
                    </div>
                  </div>
                ) :
                (
                  <>
                    {

                      props.questiontype === "FRQ" ? (<div id="canvasbuttonsh" className={`${showFullApp() ? "canvas-buttonh" : "canvas-buttonhmobile"}`}>
                        <div
                          className="canvas-buttons-containerh">
                          <PenButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          {/* <UploadImage setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} userId={props.userId} challengeId={props.challengeId} boardId={props.boardId} /> */}
                          <CalculationButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          <UndoButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          <RedoButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          <ClearCanvasButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          <EraseButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />

                          {/* <HandButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
                        </div>
                      </div>) : null
                    }
                  </>

                )
            }
          </>
        ) : null
      }

      {
        showFullApp() ? (

          <div id="canvasbuttons" className="canvas-buttons">
            <div className="canvas-buttons-container">
              {
                !props.isAnyProblem ?
                  (
                    <>

                      {/* {(props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ? */}
                      {/* <FullScreenButton isScreenFull={props.isScreenFull} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
                      {/* : null}  */}

                      <div style={{ border: 'none', display: 'flex' }}>
                        <div style={{ textTransform: 'uppercase', alignContent: 'center' }}>
                          <span style={{
                            color: '#4252AF', marginLeft: '3px', fontSize: '8pt', verticalAlign: 'middle',
                            textOrientation: 'upright', writingMode: 'vertical-lr'
                          }}>Input</span>
                        </div>
                        <div style={{
                          marginLeft: '10px', border: '1px solid #4252AF', borderRadius: '25px', height: '100%',
                          display: 'flex', flexDirection: 'column', width: '80%', alignItems: 'center'

                        }}>
                          {(props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ?
                            <>
                              <HandButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              <PenButton disabled={props.setShowSolutionBtnVisibility} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                            </>
                            : null}
                          {(props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ?
                            <UploadImage disabled={props.studentId === 0 ? props.setShowSolutionBtnVisibility : true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} userId={props.userId} challengeId={props.challengeId} boardId={props.boardId} />
                            : null}

                          <CalculationButton disabled={props.studentId === 0 ? props.setShowSolutionBtnVisibility : true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />

                        </div>
                      </div>
                      {(props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
                        null :
                        null // <div className="toolDivider"></div>
                      }

                    </>
                  ) : null
              }

              {props.isGuided === "true" && props.problem_source === Problem_source.assignment ?
                <>
                  <div style={{ marginTop: '5px', border: 'none', display: 'flex' }}>
                    <div style={{ textTransform: 'uppercase', alignContent: 'center' }}>
                      <span style={{
                        color: '#4252AF', marginLeft: '3px', fontSize: '8pt', verticalAlign: 'middle',
                        textOrientation: 'upright', writingMode: 'vertical-lr'
                      }}>Tutor</span>
                    </div>
                    <div style={{
                      marginLeft: '10px', border: '1px solid #4252AF', borderRadius: '25px', height: '100%',
                      display: 'flex', flexDirection: 'column', width: '80%', alignItems: 'center'
                    }}>

                      {/* <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      <ShowNextStepButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      <CheckMyWorkButton isBlinkCheckMyWork={props.isBlinkCheckMyWork} disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                       */}
                      <HintMainButton
                        setSelectedMenuItemHandler={setSelectedMenuItemHandler}
                        selectedMenuItem={selectedMenuItem}
                        user_Role={props.user_Role}
                        questiontype={props.questiontype}
                        hintText={props.hintText}
                        hintDisabled={props.hintDisabled}
                      />
                      <AskAITutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                      {props.setShowSolutionBtnVisibility ? <ShowSolutionButton disabled={false} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> : null}
                    </div>
                  </div>
                </>
                :
                null
              }

              {props.isGraded === "true" && props.problem_source === Problem_source.assignment ?
                <>
                  {props.setShowSolutionBtnVisibility ? <ShowSolutionButton disabled={false} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> : null}
                </>
                : null}

              {props.problem_source === Problem_source.assignment ? null :
                <div style={{ marginTop: '5px', border: 'none', display: 'flex' }}>
                  <div style={{ textTransform: 'uppercase', alignContent: 'center' }}>
                    <span style={{
                      color: '#4252AF', marginLeft: '3px', fontSize: '8pt', verticalAlign: 'middle',
                      textOrientation: 'upright', writingMode: 'vertical-lr'
                    }}>Tutor</span>
                  </div>
                  <div style={{
                    marginLeft: '10px', border: '1px solid #4252AF', borderRadius: '25px', height: '100%',
                    display: 'flex', flexDirection: 'column', width: '80%', alignItems: 'center'
                  }}>

                    {!props.isAnyProblem ?
                      (props.user_Role === User_Role.Tutor && props.questiontype === "FRQ") ? null : <HintMainButton
                        setSelectedMenuItemHandler={setSelectedMenuItemHandler}
                        selectedMenuItem={selectedMenuItem}
                        user_Role={props.user_Role}
                        questiontype={props.questiontype}
                        hintText={props.hintText}
                        hintDisabled={props.hintDisabled}
                      />
                      : null
                    }

                    {
                      props.user_Role === User_Role.Student && props.questiontype === "FRQ" ?
                        (
                          <>
                            {props.isAnyProblem && isShowSolutionDone ?
                              <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} disabled={true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              :
                              props.isAnyProblem ?
                                <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                : null
                            }
                            {props.isAnyProblem ? null : <AskTutorButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />}
                            {props.isAnyProblem ? null : <AskAITutorButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />}

                            {props.isAnyProblem && isShowSolutionDone ?
                              <ShowNextStepButton disabled={true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              :
                              props.isAnyProblem ?
                                <ShowNextStepButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                : null
                            }
                            {
                              props.isAnyProblem ? <CheckMyWorkButton isBlinkCheckMyWork={props.isBlinkCheckMyWork} disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                : null
                              // (
                              //   !props.checkmyworkdisabled ?
                              //     (
                              //       <CheckMyWorkButton isBlinkCheckMyWork={props.isBlinkCheckMyWork} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              //     ) :
                              //     (
                              //       <CheckMyWorkButton isBlinkCheckMyWork={props.isBlinkCheckMyWork} disabled={true} selectedMenuItem={selectedMenuItem} />
                              //     )
                              // )
                            }
                            {props.isAnyProblem ? <ShowSolutionButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> : null}
                          </>
                        ) : null
                    }

                    {
                      props.user_Role === User_Role.Student && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
                        (
                          <>
                            <AskTutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                            <EnterAnswerButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          </>
                        ) : null
                    }
                    {
                      (props.user_Role === User_Role.Tutor || props.user_Role === User_Role.Professor) && props.questiontype === "FRQ" ?
                        (
                          <>
                            {props.isAnyProblem ?
                              <CheckMyWorkButton isBlinkCheckMyWork={props.isBlinkCheckMyWork} disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              : null
                              //   <CheckStudentWorkButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              //
                            }
                            {props.isFromProfToTutor || props.user_Role === User_Role.Tutor ?

                              <>
                                <KnowledgeGraphButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                <SaveToTranscriptButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                <SynopsisButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              </>
                              : null
                            }
                          </>
                        ) : null
                    }
                    {
                      (props.user_Role === User_Role.Professor) && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
                        (
                          <>
                            <AskTutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                            <AskAITutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          </>
                        ) : null
                    }

                    {
                      (props.user_Role === User_Role.Tutor || props.user_Role === User_Role.Professor) && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
                        (
                          <>
                            {/* <CheckStudentWorkButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
                            <EnterAnswerButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          </>
                        ) : null
                    }
                    {
                      ((props.user_Role === User_Role.ContentAuthor || props.user_Role === User_Role.ContentExpert
                        || props.user_Role === User_Role.Professor
                      ) && props.questiontype === "FRQ") ?
                        (
                          <>
                            {props.isAnyProblem && isShowSolutionDone ?
                              <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} disabled={true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              :
                              props.isAnyProblem ?
                                <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                : null
                            }
                            {props.isAnyProblem ? null : <AskTutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />}
                            {props.isAnyProblem ? null : <AskAITutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />}
                            {props.isAnyProblem && isShowSolutionDone ?
                              <ShowNextStepButton disabled={true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                              :
                              props.isAnyProblem ?
                                <ShowNextStepButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                                : null
                            }
                            {props.user_Role !== User_Role.Professor ? null // <CheckMyWorkButton isBlinkCheckMyWork={props.isBlinkCheckMyWork} disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> 
                              : null}
                            {props.isAnyProblem ? <ShowSolutionButton disabled={props.anyProblembtnDisabled} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> : null}
                          </>
                        ) : null
                    }
                    {
                      (props.user_Role === User_Role.ContentAuthor) && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
                        (
                          <>
                            <AskTutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                            <AskAITutorButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                            {/*   <CheckStudentWorkButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
                            <EnterAnswerButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                          </>
                        ) : null
                    }
                    {/* <CopyToClipboardButton /> */}

                  </div>
                </div>
              }

            </div>
            {
              (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") || props.isAnyProblem
                ?

                <>
                  {
                    props.showsubmitbutton ?
                      (
                        <SubmitWorkButton disabled={false} visible={true}
                          setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />)
                      :

                      <SubmitWorkButton visible={true} disabled={true} selectedMenuItem={selectedMenuItem} />
                  }
                </>
                :
                props.showsubmitbutton ?
                  <SubmitWorkButton visible={true} disabled={false} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                  : (
                    <>

                      {props.totalGradeCalculationTrial ? null :
                        <SubmitWorkButton visible={false} disabled={true} selectedMenuItem={selectedMenuItem} />
                      }
                      {/* {props.practiceMode === "exam" ?*/}
                      <>

                        {props.isGraded === "true" ?
                          <CalculateGradeButton totalGradeCalculationTrial={props.totalGradeCalculationTrial}
                            disabled={props.studentId === 0 ? props.setShowSolutionBtnVisibility : true} visible={true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> : null}

                        {props.isFromAssignment ?
                          <Tooltip title="Submit Answer">
                            <IconButton style={{ justifyContent: 'flex-start' }} className='submitWorkBtnContainer2'
                              //  onClick={() => alert('Your answer submitted successfully.')}
                              onClick={() => setSelectedMenuItemHandler(MenuItems.Submit)}
                              disabled={props.studentId === 0 ? props.setShowSolutionBtnVisibility : true}>
                              <div className={`submitWorkBtnQuiz ${props.studentId === 0 ? props.setShowSolutionBtnVisibility : ' disabledDiv' ? ' disabledDiv' : ''}`}  >
                                <SaveAltRoundedIcon style={{ height: '25px', paddingTop: '5px', marginLeft: '5px', marginRight: '5px', color: 'white', alignItems: 'center' }} />
                                <span class="submitTextQuiz">Submit</span>
                                <span class="submitTextQuiz">Solution</span>
                              </div>
                            </IconButton>
                          </Tooltip>
                          :
                          null
                        }
                        {props.isTimed === "true" && !props.isReview ?
                          <div className="countDownDiv" style={{ width: '100px', marginLeft: '0px', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '10px' }}>
                            <div className="divTimeCounter" style={{ left: '0' }}>
                              <div className="timeLeft">Remaining Time</div>
                              <div style={{ paddingBottom: '5px' }} className="timeNumber">
                                {remainingTime}
                              </div>
                            </div>
                          </div>
                          : null
                        }

                      </>
                      {/* :
                           <CalculateGradeButton totalGradeCalculationTrial={props.totalGradeCalculationTrial} disabled={props.setShowSolutionBtnVisibility} visible={true} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />

                         } */}
                    </>
                  )

            }
          </div >
        )
          :
          (
            <div className={`solFooterBtns`}>
              {props.user_Role === User_Role.Tutor && props.questiontype === "FRQ" ? null :
                <HintMainButton
                  setSelectedMenuItemHandler={setSelectedMenuItemHandler}
                  selectedMenuItem={selectedMenuItem}
                  user_Role={props.user_Role}
                  questiontype={props.questiontype}
                  hintText={props.hintText}
                  hintDisabled={props.hintDisabled}
                />
              }

              {
                (props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ?

                  <CheckStudentWorkButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                  : <>
                    <EnterAnswerButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /></>}

              {
                (props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ?
                  <>
                    {
                      props.showsubmitbutton ?

                        <SubmitButtonMobile disabled={false} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />
                        : (
                          <SubmitButtonMobile disabled={true} selectedMenuItem={selectedMenuItem} />
                        )
                    }
                  </>

                  :
                  <>
                    {
                      props.problem_source !== Problem_source.interactiveproblems ?
                        (<SubmitButtonMobile disabled={false} setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} />) : null
                    }
                  </>
              }
              {props.showEquationPanel ?
                <>
                  {(props.questiontype !== "MCQ" && props.questiontype !== "MSQ" && props.questiontype !== "Ranking") ?
                    <UploadImage setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} userId={props.userId} challengeId={props.challengeId} boardId={props.boardId} />
                    : null}
                </>

                : null}

              {/* <ShowEquationButton setSelectedMenuItemHandler={setSelectedMenuItemHandler} selectedMenuItem={selectedMenuItem} /> */}
            </div>)
      }

      <div className={props.setShowSolutionBtnVisibility ? "canvas-container-parent canvas-container-parent-mobile whiteBoarddisable" : "canvas-container-parent canvas-container-parent-mobile"} id="canvas_container_parent"
        style={{
          height: `${props.cHeight}`,
          opacity: `${props.hasWarningPopup ? "0.5" : "1"}`, pointerEvents: `${props.studentId === 0 ? (props.hasWarningPopup ? "none" : "") : 'none'}`,
          display: `${!showFullApp() && props.questiontype !== "FRQ" ? "none" : props.isAnyProblem ? "none" : "flex"}`
        }
        }
      >
        {canvasInternalCollection}
      </div>


      {props.questiontype === 'FRQ' ? <Tour pagename="answerboard"></Tour> : null}


    </>

  );
}
export default Canvas;