import React, { useEffect, useState } from 'react';
import './TestReport.scss';
import PropTypes from 'prop-types';
import {
    makeStyles
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Row, Col } from 'react-bootstrap';
import { visuallyHidden } from '@mui/utils';
import { formatNumber, formatDateUs, getInstituteId, getMenuSelectedItem, getMinMaxAvg, isNumber } from '../../common/Functions'
import { PrProgressMaxValue, PrProgressPerMaxvalue } from '../../common/Constants'
import DownloadIcon from '../../assets/images/download-icon.svg';
import { CSVLink } from "react-csv"
import { problemparticipationReport } from '../../common/API'
import Button from '@mui/material/Button'
import CommonErrorsReport from './CommonErrorsReport';

export const DEFAULT_COLOR = '#5470C6';
export const SELECTED_COLOR = '#FFA756';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadPaticipantReport(props) {


    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="user_name"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "user_name" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "user_name"}
                        direction={orderBy === "user_name" ? order : 'asc'}
                        onClick={createSortHandler("user_name")}
                    >
                        Name
                        {orderBy === "user_name" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: "300px" }}>#Correct/In-correct Steps</TableCell>
                {/* <TableCell
                    key="total_count"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "total_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "total_count"}
                        direction={orderBy === "total_count" ? order : 'asc'}
                        onClick={createSortHandler("total_count")}
                    >
                        #Total steps
                        {orderBy === "total_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="correct_eq_count"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "correct_eq_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "correct_eq_count"}
                        direction={orderBy === "correct_eq_count" ? order : 'asc'}
                        onClick={createSortHandler("correct_eq_count")}
                    >
                        #Correct steps
                        {orderBy === "correct_eq_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="incorrect_eq_count"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "incorrect_eq_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "incorrect_eq_count"}
                        direction={orderBy === "incorrect_eq_count" ? order : 'asc'}
                        onClick={createSortHandler("incorrect_eq_count")}
                    >
                        #In-correct steps
                        {orderBy === "incorrect_eq_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell> */}
                <TableCell
                    key="checkequation_count"
                    align='center'
                    padding={'normal'}
                    sortDirection={orderBy === "checkequation_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "checkequation_count"}
                        direction={orderBy === "checkequation_count" ? order : 'asc'}
                        onClick={createSortHandler("checkequation_count")}
                    >
                        #Evaluation used
                        {orderBy === "checkequation_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="showsolution_count"
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderBy === "showsolution_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "showsolution_count"}
                        direction={orderBy === "showsolution_count" ? order : 'asc'}
                        onClick={createSortHandler("showsolution_count")}
                    >
                        #Show full solution used
                        {orderBy === "showsolution_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="shownextstep_count"
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderBy === "shownextstep_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "shownextstep_count"}
                        direction={orderBy === "shownextstep_count" ? order : 'asc'}
                        onClick={createSortHandler("shownextstep_count")}
                    >
                        #Show Next step used
                        {orderBy === "shownextstep_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="hints_count"
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderBy === "hints_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "hints_count"}
                        direction={orderBy === "hints_count" ? order : 'asc'}
                        onClick={createSortHandler("hints_count")}
                    >
                        #Hints used
                        {orderBy === "hints_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="askaitutor_count"
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderBy === "askaitutor_count" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "askaitutor_count"}
                        direction={orderBy === "askaitutor_count" ? order : 'asc'}
                        onClick={createSortHandler("askaitutor_count")}
                    >
                        #AskAITutor used
                        {orderBy === "askaitutor_count" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="percprogress"
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderBy === "percprogress" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "percprogress"}
                        direction={orderBy === "percprogress" ? order : 'asc'}
                        onClick={createSortHandler("percprogress")}
                    >
                        %Progress
                        {orderBy === "percprogress" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadPaticipantReport.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {


    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="challenge_name"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "challenge_name" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "challenge_name"}
                        direction={orderBy === "challenge_name" ? order : 'asc'}
                        onClick={createSortHandler("challenge_name")}
                    >
                        Assignments
                        {orderBy === "challenge_name" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell>Percent Attempted</TableCell>
                {props.isGraded === true || props.isGraded === "true" ?
                    <>
                        <TableCell>Average Score</TableCell>
                        <TableCell>Min Score</TableCell>
                        <TableCell>Max Score</TableCell>
                        <TableCell>Students with Max Score</TableCell>
                    </>
                    :
                    null}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 35,
        width: 35,
        // marginRight: theme.spacing(1),
        margin: "7px"
    },
    avatarlarge: {
        height: 100,
        width: 100,
    },
    linkbutton: {
        fontSize: "10px"
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#1976d214 !important"
        }
    },
    tableRowhw: {
        textTransform: "capitalize !important",
        "&:hover": {
            backgroundColor: "#e0e0e0 !important"
        }
    },
    tableRowhwParticipant: {
        textTransform: "capitalize !important",
        "&:hover": {
            backgroundColor: "#e0e0e0 !important"
        }
    },
    rootAbsolute: {
        "& .MuiButton-startIcon": {
            marginRight: "1px"
        }
    }

}));

const PROBLEM_SUMMARY_KPI = [{ field: 'correct_eq_count', label: 'Correct Steps' }, { field: 'incorrect_eq_count', label: 'In-Correct Steps' }, { field: 'checkequation_count', label: 'Evaluation' }, { field: 'showsolution_count', label: 'Show Full Solution' }, { field: 'shownextstep_count', label: 'Show Next Step' }, { field: 'hints_count', label: 'Hints' }, { field: 'askaitutor_count', label: 'AskAiTutor' }, { field: 'percprogress', label: 'Progress' }]
export default function TestReport({ problemList, isGraded, assignment_id }) {
    let selectedIds = ""
    const classes = useStyles();
    const [avgScore, setAvgScore] = useState(0);
    const [minScore, setMinScore] = useState(0);
    const [maxScore, setMaxScore] = useState(0);
    const [highScore, setHighScore] = useState(0);
    const [selectedProblem, setSelectedProblem] = useState('');
    const [selected, setSelected] = React.useState([]);
    const [showBarChart, setshowBarChart] = useState(true);
    const [totalPages, settotalPages] = useState(1)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');
    const [displayMessage, setDisplayMessage] = useState("")
    const [tableIconColor, settableIconColor] = useState("currentColor")
    const [barchartIconColor, setbarchartIconColor] = useState("#4252af")
    const [orderpr, setOrderpr] = React.useState('asc');
    const [orderByPr, setOrderByPr] = React.useState('user_name');
    const [selectedPr, setSelectedPr] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [selecteChallengeId, setselecteChallengeId] = useState('');
    const [prData, setPrData] = React.useState([]);
    const [displayStudentMessagepr, setdisplayStudentMessagepr] = useState("")
    const [problemparticipationReportList, setproblemparticipationReportList] = useState([])
    const [csvExportDataparticipationsummary, setcsvExportDataparticipationsummary] = useState([])
    const [classSummaryData, setClassSummaryData] = useState([])
    const [loader, setloader] = useState(false);
    const [problemParticipationSummary, setproblemParticipationSummary] = useState("contained")
    const [commonErrorsSummary, setcommonErrorsSummary] = useState("outlined")
    const [showParticipationSummarySection, setshowParticipationSummarySection] = useState(true)

    useEffect(() => {
        drawBarchart()
    }, [problemList])

    useEffect(() => {
        if (selecteChallengeId !== '' && problemparticipationReportList !== null && problemparticipationReportList !== undefined && problemparticipationReportList.length > 0) {
            getFilterdata(selecteChallengeId, problemparticipationReportList)
        }
    }, [selecteChallengeId, problemparticipationReportList])

    useEffect(() => {
        setdisplayStudentMessagepr("")
        setloader(true)
        const reqData = {
            params: {
                assignment_id: parseInt(assignment_id),
                institute_id: getInstituteId(),
                course_id: getMenuSelectedItem()
            }
        }
        problemparticipationReport(reqData).then(res => {
            if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                setproblemparticipationReportList(res.data.data)
                let csvParticipationReportProblem = [["Name", "#Total steps", "#Correct steps", "#In-correct steps", "#Evaluation used", "#Show full solution used", "#Show Next step used", "#Hints used", "#AskAITutor used", "%Progress"]]
                res.data.data.map((student) => {
                    csvParticipationReportProblem.push([student.user_name, student.total_count, student.correct_eq_count, student.incorrect_eq_count, student.checkequation_count, student.showsolution_count, student.shownextstep_count, student.hints_count, student.askaitutor_count, student.percprogress])
                })
                setcsvExportDataparticipationsummary(csvParticipationReportProblem)
                setdisplayStudentMessagepr("")
                setloader(false)
            }
            else {
                setdisplayStudentMessagepr("No Data Found.")
                setloader(false)
            }

        }).catch(err => {
            console.error(err.message);
            setloader(false)
            setdisplayStudentMessagepr("No Data Found.")
        })
    }, [])

    const prepareClassLevelSummary = (data) => {
        let tempArr = [];
        PROBLEM_SUMMARY_KPI.forEach(element => {
            let minMax = getMinMaxAvg(data, element.field);
            let obj = { kpi: element.label, min: minMax.min, max: minMax.max, avg: minMax.avg, med: minMax.med, dev: minMax.dev }
            tempArr.push(obj)
        })

        let obj = [
            {
                'user_name': 'Average', 'correct_eq_count': parseFloat(tempArr[0].avg).toFixed(2), 'incorrect_eq_count': parseFloat(tempArr[1].avg).toFixed(2),
                'shownextstep_count': parseFloat(tempArr[4].avg).toFixed(2), 'showsolution_count': parseFloat(tempArr[3].avg).toFixed(2), 'hints_count': parseFloat(tempArr[5].avg).toFixed(2),
                'checkequation_count': parseFloat(tempArr[2].avg).toFixed(2), 'total_count': 0, 'askaitutor_count': parseFloat(tempArr[6].avg).toFixed(2), 'percprogress': parseFloat(tempArr[7].avg).toFixed(2)
            },
            {
                'user_name': 'Median', 'correct_eq_count': parseFloat(tempArr[0].med).toFixed(2), 'incorrect_eq_count': parseFloat(tempArr[1].med).toFixed(2),
                'shownextstep_count': parseFloat(tempArr[4].med).toFixed(2), 'showsolution_count': parseFloat(tempArr[3].med).toFixed(2), 'hints_count': parseFloat(tempArr[5].med).toFixed(2),
                'checkequation_count': parseFloat(tempArr[2].med).toFixed(2), 'total_count': 0, 'askaitutor_count': parseFloat(tempArr[6].med).toFixed(2), 'percprogress': parseFloat(tempArr[7].med).toFixed(2)
            },
            {
                'user_name': 'Std. Deviation', 'correct_eq_count': parseFloat(tempArr[0].dev).toFixed(2), 'incorrect_eq_count': parseFloat(tempArr[1].dev).toFixed(2),
                'shownextstep_count': parseFloat(tempArr[4].dev).toFixed(2), 'showsolution_count': parseFloat(tempArr[3].dev).toFixed(2), 'hints_count': parseFloat(tempArr[5].dev).toFixed(2),
                'checkequation_count': parseFloat(tempArr[2].dev).toFixed(2), 'total_count': 0, 'askaitutor_count': parseFloat(tempArr[6].dev).toFixed(2), 'percprogress': parseFloat(tempArr[7].dev).toFixed(2)
            },
            {
                'user_name': 'Minimum', 'correct_eq_count': tempArr[0].min, 'incorrect_eq_count': tempArr[1].min,
                'shownextstep_count': tempArr[4].min, 'showsolution_count': tempArr[3].min, 'hints_count': tempArr[5].min,
                'checkequation_count': tempArr[2].min, 'total_count': 0, 'askaitutor_count': tempArr[6].min, 'percprogress': tempArr[7].min
            },
            {
                'user_name': 'Maximum', 'correct_eq_count': tempArr[0].max, 'incorrect_eq_count': tempArr[1].max,
                'shownextstep_count': tempArr[4].max, 'showsolution_count': tempArr[3].max, 'hints_count': tempArr[5].max,
                'checkequation_count': tempArr[2].max, 'total_count': 0, 'askaitutor_count': tempArr[6].max, 'percprogress': tempArr[7].max
            }
        ]

        setClassSummaryData(obj)

    }
    const getFilterdata = (id, data) => {
        if (data !== null && data !== undefined && data.length > 0) {
            let filterdata = data.filter(e => e.challenge_id.toString() === id.toString())
            prepareClassLevelSummary([...filterdata])
            setFilteredData(filterdata)
        }
    }

    const drawBarchart = () => {
        let xAxisLabel = [];
        let yAxisData = [];

        problemList.forEach((element, index) => {
            if (index === 0) {
                setAvgScore(element.avg_score);
                setMinScore(element.min_score);
                setMaxScore(element.max_score);
                setHighScore(element.students_max_score)
                setSelectedProblem(element.challenge_name)
                setselecteChallengeId(element.challenge_id)
            }
            xAxisLabel.push(element.challenge_name);
            yAxisData.push({ value: element.percentage_attempted, avg: element.avg_score, min: element.min_score, max: element.max_score, high: element.students_max_score, problem: element.challenge_name, challengeId: element.challenge_id });
        });
        console.log(problemList)
        let option = {
            scale: true,
            scaleSize: 20,
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                }
            },
            xAxis: {
                type: 'category',
                data: xAxisLabel,
                // name:'Problem Name',
                // nameLocation: "middle",
                // nameGap: 60,
                interval: 0,
                axisLabel: {
                    rotate: 22
                }
            },
            yAxis: {
                type: 'value',
                name: 'Percent Attempted',
                nameRotate: 90,
                nameLocation: "middle",
                nameGap: 40,
                axisLabel: {
                    formatter: function (item) {
                        return item + '%'; // Append '%' to the value
                    },

                }
            },
            tooltip: {
                trigger: 'item', // Trigger the tooltip on each item
                formatter: function (params) {
                    // Customize the tooltip content
                    // Assuming values are in decimal format and need to be displayed as percentages
                    return `Problem Name: ${params.name} <br/> Percent Attempted: ${params.value}%`//params.name + ': ' + (params.value) + '%'; // Format the value as percentage
                }
            },
            series: [
                {
                    data: yAxisData,
                    type: 'bar',
                    barWidth: "10%",
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'; // Append '%' to the value
                        }
                    },
                    itemStyle: {
                        color: function (params) {
                            // Set the default color of all bars
                            return params.dataIndex === 0 ? SELECTED_COLOR : DEFAULT_COLOR;
                        }
                    }
                }
            ]
        };
        var chartDom = document.getElementById('challengesBarChart');
        var myChart = window.echarts.init(chartDom);
        option && myChart.setOption(option);
        myChart.on('click', function (params) {
            try {
                var newColor = option.series[0].data.map((item, index) => {
                    return index === params.dataIndex ? SELECTED_COLOR : DEFAULT_COLOR;
                });

                setAvgScore(params.data.avg);
                setMinScore(params.data.min);
                setMaxScore(params.data.max);
                setHighScore(params.data.high)
                setSelectedProblem(params.data.problem);
                setselecteChallengeId(params.data.challengeId)
                // Update the chart option with the new color settings
                myChart.setOption({
                    series: [{
                        itemStyle: {
                            color: function (params) {
                                return newColor[params.dataIndex];
                            }
                        }
                    }]
                });
            } catch (e) {

            }

        });
    }

    const handleclickbarchart = () => {
        setshowBarChart(true)
        settableIconColor("currentColor")
        setbarchartIconColor("#4252af")
        setTimeout(() => { drawBarchart() }, 200)

    }
    const handletable = () => {
        setshowBarChart(false)
        settableIconColor("#4252af")
        setbarchartIconColor("currentColor")
    }

    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleRequestSortPR = (event, property) => {

        const isAsc = orderByPr === property && orderpr === 'asc';
        setOrderpr(isAsc ? 'desc' : 'asc');
        setOrderByPr(property);
    };
    const visibleRowshomeworkList = React.useMemo(
        () =>
            stableSort(problemList, getComparator(order, orderBy)),
        [problemList, order, orderBy],
    );


    const visibleRowsParticipantReportList = React.useMemo(
        () =>
            [...classSummaryData, ...stableSort(filteredData, getComparator(orderpr, orderByPr))],
        [filteredData, orderpr, orderByPr],
    );

    const showParticipationSummaryhandler = () => {
        setshowParticipationSummarySection(true)
        setproblemParticipationSummary("contained")
        setcommonErrorsSummary("outlined")
        const element = document.getElementById("participationsummary_div");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }

    const showCommonErrorsSummaryhandler = () => {
        setshowParticipationSummarySection(false)
        setproblemParticipationSummary("outlined")
        setcommonErrorsSummary("contained")
        const element = document.getElementById("participationsummary_div");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }

    return (
        <>

            <div className="reportContainer">
                <div style={{ width: '32%', float: 'left' }}>
                    <div className='d-flex justify-content-end' style={{ marginRight: "2%" }}>
                        <div onClick={handleclickbarchart} style={{ cursor: "pointer" }} title='Chart View'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={barchartIconColor} class="bi bi-bar-chart" viewBox="0 0 16 16">
                                <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                            </svg></div>
                        <div onClick={handletable} style={{ marginLeft: "10px", cursor: "pointer" }} title='Table View'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={tableIconColor} class="bi bi-table" viewBox="0 0 16 16">
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z" />
                            </svg></div>
                    </div>
                    {
                        showBarChart ? (
                            <>

                                <div id='challengesBarChart' className='challengesBarChart'>


                                </div>
                                {
                                    isGraded === true || isGraded === "true" ? (<div className='kpiConainter'>
                                        <div>
                                            <span>Average Score</span>
                                            <span>{avgScore !== null && avgScore !== undefined ? formatNumber(avgScore) : "0"}</span>
                                        </div>
                                        <div>
                                            <span>Min Score</span>
                                            <span>{minScore !== null && minScore !== undefined ? formatNumber(minScore) : "0"}</span>
                                        </div>
                                        <div>
                                            <span>Max Score</span>
                                            <span>{maxScore !== null && maxScore !== undefined ? formatNumber(maxScore) : "0"}</span>
                                        </div>
                                        <div>
                                            <span>Students with Max Score</span>
                                            <span>{highScore}</span>
                                        </div>
                                    </div>) : null
                                }






                            </>

                        )

                            :
                            (
                                <Box style={{ marginLeft: '0px' }} sx={{ float: 'left', width: '99%', mt: 3, ml: 7 }}>
                                    <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 400 }}>
                                            <Table
                                                sx={{ minWidth: 650 }}
                                                stickyHeader aria-label="sticky table"
                                                size={'medium'}
                                            >
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}

                                                />
                                                <TableBody>
                                                    {
                                                        visibleRowshomeworkList !== undefined && visibleRowshomeworkList !== null && visibleRowshomeworkList.length > 0 ?
                                                            (visibleRowshomeworkList.map((row, index) => {
                                                                return (
                                                                    <TableRow className={classes.tableRowhw}
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                        sx={{ cursor: 'pointer' }}>
                                                                        <TableCell align="left">
                                                                            {row.challenge_name}
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{ fontWeight: "bolder" }}>
                                                                            {`${row.percentage_attempted !== null && row.percentage_attempted !== undefined ? formatNumber(row.percentage_attempted) : 0.00}%`}
                                                                        </TableCell>
                                                                        {isGraded === true || isGraded === "true" ?
                                                                            <>
                                                                                <TableCell align="left">
                                                                                    {row.avg_score !== null && row.avg_score !== undefined ? formatNumber(row.avg_score) : 0.00}
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {row.min_score !== null && row.min_score !== undefined ? formatNumber(row.min_score) : 0.00}
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {row.max_score !== null && row.max_score !== undefined ? formatNumber(row.max_score) : 0.00}
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {row.students_max_score !== null && row.students_max_score !== undefined ? formatNumber(row.students_max_score) : 0.00}
                                                                                </TableCell>
                                                                            </>
                                                                            :
                                                                            null}
                                                                    </TableRow>
                                                                );
                                                            })) :
                                                            (
                                                                // <div style={{ padding: "20px" }}>{displayMessage}</div>
                                                                <TableRow>
                                                                    <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{displayMessage}</TableCell>
                                                                </TableRow>
                                                            )
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>

                                </Box>

                            )
                    }
                </div>
                {/* <div className='titleorcss' style={{ width: '1px', float: 'left' }}></div> */}

                <div style={{ marginTop: '-20px', float: 'right', width: '65%' }}>
                    <div className='prproblemNameHeader'>
                        <div>

                            <span className='prproblemNameCaption'>Selected Problem:</span> <span className='prproblemNameTitle'>{selectedProblem} </span>
                        </div>

                        <div style={{ marginRight: "1%" }}>
                            <CSVLink data={csvExportDataparticipationsummary} className='exportCSVbtn exportRepresentative'
                                filename={selectedProblem + "_participation_summary_ " + formatDateUs(Date.now()) + ".csv"}> Export To CSV
                                <img src={DownloadIcon}></img></CSVLink>
                        </div>
                    </div>

                    <div>
                        <Button variant={problemParticipationSummary}
                            onClick={showParticipationSummaryhandler}
                            className={classes.rootAbsolute}
                            sx={{ minWidth: "0 !important", padding: "7px !important", textDecoration: `${problemParticipationSummary === "text" ? "underline" : "none"}`, textTransform: "capitalize" }}>Problem Participation</Button>

                        <Button variant={commonErrorsSummary}
                            onClick={showCommonErrorsSummaryhandler}
                            className={classes.rootAbsolute}
                            sx={{ minWidth: "0 !important", padding: "7px !important", textDecoration: `${commonErrorsSummary === "text" ? "underline" : "none"}`, textTransform: "capitalize", marginLeft: "10px", marginRight: "30px" }}>Common Errors</Button>

                    </div>
                    <Paper sx={{ width: '100%', mt: 3, overflow: 'hidden', top: '12px', display: 'flex', position: 'relative' }} id="participationsummary_div">
                        <TableContainer sx={{ marginBottom: '10px', height: 500, overflowY: 'auto', overflowX: "auto" }}>
                            {
                                showParticipationSummarySection ? (<Table
                                    sx={{ minWidth: 650 }}
                                    stickyHeader aria-label="sticky table"
                                    size={'medium'}
                                >
                                    <EnhancedTableHeadPaticipantReport
                                        numSelected={selectedPr.length}
                                        order={orderpr}
                                        orderBy={orderByPr}
                                        onRequestSort={handleRequestSortPR}

                                    />
                                    <TableBody className='tblMin'>
                                        {
                                            visibleRowsParticipantReportList !== undefined && visibleRowsParticipantReportList !== null && visibleRowsParticipantReportList.length > 0 ?
                                                (visibleRowsParticipantReportList.map((row, index) => {

                                                    let progresscheckequation_count = row.checkequation_count
                                                    if (parseFloat(row.checkequation_count) > parseFloat(PrProgressMaxValue)) {
                                                        progresscheckequation_count = PrProgressMaxValue
                                                    }

                                                    let progressshowsolution_count = row.showsolution_count
                                                    if (parseFloat(row.showsolution_count) > parseFloat(PrProgressMaxValue)) {
                                                        progressshowsolution_count = PrProgressMaxValue
                                                    }

                                                    let progressshownextstep_count = row.shownextstep_count
                                                    if (parseFloat(row.shownextstep_count) > parseFloat(PrProgressMaxValue)) {
                                                        progressshownextstep_count = PrProgressMaxValue
                                                    }


                                                    let progresshints_count = row.hints_count
                                                    if (parseFloat(row.hints_count) > parseFloat(PrProgressMaxValue)) {
                                                        progresshints_count = PrProgressMaxValue
                                                    }

                                                    let progressaskaitutor_count = row.askaitutor_count
                                                    if (parseFloat(row.askaitutor_count) > parseFloat(PrProgressMaxValue)) {
                                                        progressaskaitutor_count = PrProgressMaxValue
                                                    }


                                                    let progresspercprogress = row.percprogress
                                                    if (parseFloat(row.percprogress) > parseFloat(PrProgressPerMaxvalue)) {
                                                        progresspercprogress = PrProgressPerMaxvalue
                                                    }


                                                    return (
                                                        <TableRow className={classes.tableRowhwParticipant}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            sx={{ cursor: 'pointer' }}>
                                                            <TableCell align="left">
                                                                {row.user_name}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ width: "300px" }}>
                                                                <div className='divprcorrect d-flex align-items-center justify-content-between'>
                                                                    <span style={{ marginRight: "17px", fontSize: "12px" }}> Correct</span>
                                                                    <progress id={`correct${index}`} value={row.correct_eq_count} max={PrProgressMaxValue}>
                                                                        {`${row.correct_eq_count !== null && row.correct_eq_count !== undefined ? formatNumber(row.correct_eq_count) : 0.00}`}
                                                                    </progress>
                                                                    <span style={{ marginLeft: "5px" }}>
                                                                        {`${row.correct_eq_count !== null && row.correct_eq_count !== undefined ? formatNumber(row.correct_eq_count) : 0.00}`
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className='divprincorrect d-flex align-items-center justify-content-between'>
                                                                    <span style={{ marginRight: "5px", fontSize: "12px" }}> In-Correct</span><progress class="" id={`incorrect${index}`} value={row.incorrect_eq_count} max={PrProgressMaxValue}>
                                                                        {`${row.incorrect_eq_count !== null && row.incorrect_eq_count !== undefined ? formatNumber(row.incorrect_eq_count) : 0.00}`}
                                                                    </progress><span style={{ marginLeft: "5px" }}>
                                                                        {`${row.incorrect_eq_count !== null && row.incorrect_eq_count !== undefined ? formatNumber(row.incorrect_eq_count) : 0.00}`
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                <progress id={`checkequation${index}`} value={progresscheckequation_count} max={PrProgressMaxValue}>
                                                                    {`${row.checkequation_count !== null && row.checkequation_count !== undefined ? formatNumber(row.checkequation_count) : 0.00}`}
                                                                </progress>
                                                                <span style={{ marginLeft: "5px" }}>
                                                                    {`${row.checkequation_count !== null && row.checkequation_count !== undefined ? formatNumber(row.checkequation_count) : 0.00}`
                                                                    }
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <progress id={`showsolution${index}`} value={progressshowsolution_count} max={PrProgressMaxValue}>
                                                                    {`${row.showsolution_count !== null && row.showsolution_count !== undefined ? formatNumber(row.showsolution_count) : 0.00}`}
                                                                </progress>
                                                                <span style={{ marginLeft: "5px" }}>
                                                                    {`${row.showsolution_count !== null && row.showsolution_count !== undefined ? formatNumber(row.showsolution_count) : 0.00}`
                                                                    }
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <progress id={`shownextstep${index}`} value={progressshownextstep_count} max={PrProgressMaxValue}>
                                                                    {`${row.shownextstep_count !== null && row.shownextstep_count !== undefined ? formatNumber(row.shownextstep_count) : 0.00}`}
                                                                </progress>
                                                                <span style={{ marginLeft: "5px" }}>
                                                                    {`${row.shownextstep_count !== null && row.shownextstep_count !== undefined ? formatNumber(row.shownextstep_count) : 0.00}`
                                                                    }
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <progress id={`hints_count${index}`} value={progresshints_count} max={PrProgressMaxValue}>
                                                                    {`${row.hints_count !== null && row.hints_count !== undefined ? formatNumber(row.hints_count) : 0.00}`}
                                                                </progress>
                                                                <span style={{ marginLeft: "5px" }}>
                                                                    {`${row.hints_count !== null && row.hints_count !== undefined ? formatNumber(row.hints_count) : 0.00}`
                                                                    }
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <progress id={`askaitutor_count${index}`} value={progressaskaitutor_count} max={PrProgressMaxValue}>
                                                                    {`${row.askaitutor_count !== null && row.askaitutor_count !== undefined ? formatNumber(row.askaitutor_count) : 0.00}`}
                                                                </progress>
                                                                <span style={{ marginLeft: "5px" }}>
                                                                    {`${row.askaitutor_count !== null && row.askaitutor_count !== undefined ? formatNumber(row.askaitutor_count) : 0.00}`
                                                                    }
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    isNumber(row.percprogress) ? (<>
                                                                        <progress id={`percprogress${index}`} value={progresspercprogress} max={PrProgressPerMaxvalue}>
                                                                            {`${row.percprogress !== null && row.percprogress !== undefined ? formatNumber(row.percprogress) : 0.00}`}
                                                                        </progress>
                                                                        <span style={{ marginLeft: "5px" }}>
                                                                            {`${row.percprogress !== null && row.percprogress !== undefined ? formatNumber(row.percprogress) : 0.00}%`
                                                                            }
                                                                        </span>
                                                                    </>) :
                                                                        <>
                                                                            {row.percprogress}
                                                                        </>
                                                                }

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })) :
                                                (

                                                    <TableRow>
                                                        <TableCell colSpan={10} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>
                                                            {
                                                                loader && (
                                                                    <button class="btn btn-primary" type="button" disabled>
                                                                        <span style={{ marginRight: '5px' }} class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        Processing...
                                                                    </button>
                                                                )
                                                            }
                                                            {
                                                                <div>{displayStudentMessagepr}</div>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        }

                                    </TableBody>
                                </Table>) : (<><CommonErrorsReport assignment_id={assignment_id} challenge_id={selecteChallengeId}></CommonErrorsReport></>)
                            }

                        </TableContainer>
                    </Paper>

                </div>
            </div>

            {/* {
                loader && (
                    <>
                        <div style={{
                            color: "#164b99",
                            position: 'absolute',
                            bottom: '7%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            zIndex: "9999"
                        }}>
                            <button class="btn btn-primary" type="button" disabled>
                                <span style={{ marginRight: '5px' }} class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Processing...
                            </button>
                        </div>
                    </>


                )
            } */}
        </>
    )
}